export default {
	data: () => ({
		langEng: {
			title: 'Cursos',
			purchasedCourses: 'Cursos comprados',
			viewStatus: {
				start: 'Pendientes',
				mid: 'Incompleto',
				final: 'Completado'
			},
			btnPlay: 'Play'
		}
	})
}