export default {
	data: () => ({
		langEng: {
			title: 'Acceso',
			name: 'Acceso',
			password: 'contraseña',
			newpass: 'Nueva contraseña',
			confirmPas: 'Confirmar contraseña',
			login: 'Registrarse',
			registration: 'Registrarse',
			recoveryPass: 'Recuperación',
			btnRecover: 'Recuperar',
			save: 'Guardar',
			generate: 'Generar',
      savePass: "Guardar acceso y contraseña"
		}
	})
}


