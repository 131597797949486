<template>
<div class="menu__mobile" @click="$emit('click')">
	<div class="menu__mobile_button" :class="{'active': active}">
		<span></span>
	</div>
</div>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.night {
  .menu__mobile {
    &_button {
      span,
      &:before, 
      &:after {
        background-color: white;
      }
    }
  }
}
.menu__mobile {
	display: none;
	align-items: center;
	justify-content: center;
  width: 26px;
  height: 20px;
  @media (max-width: 1200px) {
    display: flex;
  }
	
	&_button {
		position: relative;
		height: 20px;
		width: 40px;
		cursor: pointer;
		background-color: transparrent;
			
		&.active {
			span {
				opacity: 0;
			}
			&.menu__mobile_button:before,
			&.menu__mobile_button:after  {
				top: 40%;
			}
			&.menu__mobile_button:before {
				transform: rotate(45deg);
			}
			&.menu__mobile_button:after {
				transform: rotate(-45deg);
			}
		}

		span,
		&:before, 
		&:after {
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background-color: #930685bf;
			border-radius: 4px;
			opacity: 1;
		}
		&:before, 
		&:after {
			content: "";
			transition: top .35s cubic-bezier(.23,1,.32,1),transform .35s cubic-bezier(.23,1,.32,1);
		}
	
		span {
			top: 50%;
			transform: translateY(-50%);
		}
		&:before {
			top: 0;
		}
		&:after {
			top: calc(100% - 4px);
		}
	}
} 

</style>