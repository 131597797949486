<template>
  <div class="aq__modal__wrp" ref="modalAddCalendar">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <strong>{{$t('calendar.title')}}</strong>
          <!-- <span>20 Aug, 09:14</span> -->
        </div>
        <div class="close__btn" @click="close()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <form>
        <strong>{{$t('calendar.namePhone')}}</strong>
        <div class="inp__row">
          <input :class="{'requred' : !status.name}" type="text" :placeholder="$t('calendar.name')" v-model="selectUser.name">
          <input :class="{'requred' : !status.phone}" type="text" :placeholder="$t('calendar.phone')" v-model="selectUser.phone">
        </div>
        <strong>{{$t('calendar.timeDate')}}</strong>
        <div class="time__row">
          <!-- <input :class="{'requred' : !status.date}" class="date" type="date" v-model="selectUser.date"> -->
          <date-range-picker
            class="date_range_picker"
            :class="{'requred' : !status.date}"
            :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
            v-model="datePickerDate"
            :singleDatePicker="true"
            :ranges="false"
            :autoApply="true"                           
          />
         
          <vue-timepicker :input-class="{'requred' : !status.time, 'time': true}"  placeholder="" v-model="selectUser.time" v-if="!isMobile"></vue-timepicker>     
          <select v-else class="clock" :class="{'requred' : !status.time}" v-model="selectUser.time">
            <option v-for="h in 18" :key="h" :value="`${h + 5}:00`">{{ h + 5 }}:00</option>
          </select>
        </div>
        <strong>{{$t('calendar.comment')}}</strong>
        <div class="inp__row">
          <textarea class="comment" v-model="selectUser.comment"></textarea>
        </div>
        <button type="button" @click="save">{{$t('calendar.btn')}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapGetters} from 'vuex'
import { setTimeout } from 'timers';

const domain = process.env.VUE_APP_BASE_API;



export default {
  props: ["selectUser", "confirmationList"],
  components: {
    VueTimepicker,
    DateRangePicker
  },
  data() {
    return {
      status: {
        name: ' ',
        phone: ' ',
        date: ' ',
        time: ' ',
      },
      datePickerDate: {
        startDate: '',
        endDate: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      langStorage : 'languages/getLang',
    }),
    isMobile(){
      return window.innerWidth <= 430;
    },
  },
  watch: {
    'datePickerDate.startDate': function (newVal, oldVal){
      let d = new Date(newVal);
      let m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      this.selectUser.date = `${d.getFullYear()}-${m}-${day}`;
    },
    'selectUser.date': function (newVal, oldVal){
      this.datePickerDate.startDate = newVal;
      this.datePickerDate.endDate = newVal;
    },
    
  },
  methods: {
    async save() {  
      if(this.checkingForRepetitions(this.selectUser)) {
        this.close();
        this.$emit("warning");
        return;
      }
      if(this.validator()){
        if(this.selectUser.id != ''){ 
          this.sendData(this.selectUser.confirmation, 'PUT');
        }
        else {       
         this.sendData(true, 'POST');
         this.datePickerDate = {
            startDate: null,
            endDate: null
          }
          // if not reset twice , input datepicker not empty
          setTimeout(() => this.datePickerDate = {
            startDate: null,
            endDate: null
          }, 1000);
          
        }
        this.close();
      }
    },
    async sendData(status, mth){
      try {  
        let user = this.selectUser;
        let obj = {
          client_name: user.name,
          client_phone: user.phone,
          client_comments: user.comment ? user.comment : "comment",
          time_of_receipt: user.time == "HH:mm" || user.time == ''  ? "00:00" : user.time,
          confirmation: false,
          doctor: this.user.userID,
          lang: this.langStorage
        }
        user.date ? obj.date_of_receipt = user.date : '';
        await fetch(`${domain}/cabinet/appointment/${user.id ? user.id + '/' : ''}`, {
          method: mth,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${this.user.token}`
          },
          body: JSON.stringify(obj)
        });      
        this.$emit('reload');
      }
      catch(err){
        console.error(err);
      }
    },
    close(){
      this.status = {
        name: ' ',
        phone: ' ',
        date: ' ',
        time: ' ',
      };
      this.$emit('close');
    },
    validator(){
      this.status.name = this.selectUser.name != '';
      this.status.phone = this.selectUser.phone != '';
      this.status.date = this.selectUser.date != '';
      this.status.time = this.selectUser.time != 'HH:mm' && this.selectUser.time != '';

      for (let key in this.status) {
        if(this.status[key] == false){
          return false;
        }
      }
      return true;
    },
    checkingForRepetitions(item) {
      let flag = false;
      if(this.confirmationList) {
        flag = this.confirmationList.some(e => {
          if(item.date == e.date_of_receipt) {
            const time = item.time.split(':');
            const time2 = e.time_of_receipt.split(':');
            return time[0] == time2[0];
          }
          return false;
        });
      }

      return flag;
    },
  }
  
}
</script>

<style lang="scss">
.aq__modal__wrp {
  .time__row {
    .time,.ducraction {
      width: 100%;
    }
    .date {    
      padding-left: 14px !important;
    }

  }
  .vue__time-picker.time-picker {
    width: calc(50% - 12px);
    @media(max-width: 430px) { width: 100%; }
    .time {
      background: url('/img/time-icon.svg') no-repeat right #EFEEFE;
      background-position: top 16px right 20px;
      padding-left: 24px;
      border-radius: 6px;
      outline: none;
      border: none;
      height: 48px;
      color: #000001;
      font-size: 16px;
    }
  }
  .clock {
    width: calc(50% - 12px);
    background: url('/img/time-icon.svg') no-repeat right #EFEEFE;
    background-position: top 16px right 20px;
    padding-left: 24px;
    border-radius: 6px;
    outline: none;
    border: none;
    height: 48px;
    color: #000001;
    font-size: 16px;
    @media(max-width: 430px) { width: 100%; }
  }

  .vue__time-picker {
    .dropdown {
      ul li:not([disabled]).active{
        background: #930f81;
      }
    }
  }

  .date_range_picker {
    width: calc(50% - 12px);
    border-radius: 6px;
    @media(max-width: 430px){
      width: 100%;
      margin-bottom: 16px;
    }
    .form-control {
      display: flex;
      align-items: center;
      background: url('/img/calendar.svg') no-repeat right #EFEEFE;
      background-position-x: 95%;
      background-size: 25px;
      height: 48px;
      border-radius: 6px;
      outline: none;
      border: none;
    }
  }



  .duraction {
    background-color: #EFEEFE;
    border-radius: 6px;
    padding-left: 20px;
    outline: none;
    border: none;
    width: 100% !important;
    max-width: 180px;
  }
  .requred {
    background-color: #ff00002e !important;
    .form-control {
      background-color: #ff00002e;
    }
  }

}

</style>
