export default {
	data: () => ({
		langRo: {
			title: {
				text: 'Aveți ',
				color: 'neclarități?'
			},
			desc: {
				text: 'Aici puteți găsi răspunsuri la cele mai frecvente întrebări ',
				color: 'sau ne puteți contacta.',
				text2: 'Noi vom fi alături de Dumneavoastră pe tot parcursul studiilor.'
			},
			info: 'Pentru mai multe informații ne puteți contacta',
			btnText: 'Lasă o cerere'
		}
	})
}