const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    faq: []
  },
  getters: {
    getFaq(state){
      return state.faq;
    }
  },
  mutations: {
    setFaq(state, payload){
      state.faq = payload;
    },
    useItem(state, payload){
      state.faq[payload].isOpen = !state.faq[payload].isOpen;
    }
  },
  actions: {
  async setFaq({ commit, rootGetters }) {
      commit('setFaq', await getFaqItems(rootGetters['languages/getLang']));
   },
   useItem({ commit }, payload) {
    commit('useItem', payload);
   }
  }
}

async function getFaqItems(lang) {
  const response = await fetch(`${domain}/${lang}/get-faq/`);
  const data = await response.json();
  return wrapFaq(data);

}


function wrapFaq(arr) {
  const faq = [];
  arr.forEach(e => {
    faq.push({
      id: e.id,
      title: e.name,
      desc: e.description,
      isOpen: false
    });
  });
  return faq;
}