export default {
	data: () => ({
		langRu: {
			btnPath: {
				home: 'Домой',
				category: 'Курсы',
			},
			courseMaterials: 'Материалы курса',
			courseTitle: {
				text: 'Содержание',
				color: ' курса'
			},
			courseDesc: 'Вас ждут онлайн лекции, практические задания, воркшопы и мастерклассы',
			chapters: 'теоретическое',
			modules: 'практических видео'
		}
	})
}