export default {
	data: () => ({
		langEng: {
			header: {
				courses: 'Сourses',
				shop: 'Shop',
				specialists: 'Specialists',
				about: 'About',
				blog: 'Blog',
        promotions: 'Promotions',
				login: 'Login',
				registration: 'Registration',
				shopHidden: 'for registered users only',
				shopClose: 'not available in your region'
			},
			slider: {
        
				btn: 'Select course'
			},
			about: {
				video: { title: 'Video', desc: 'Theoretical and practical courses' },
				support: { title: 'Support', desc: 'Consulting from the best specialists' },
				web: { title: 'WEB', desc: 'Create your own web page with online appointments' },
				results: { title: 'Results', desc: 'Professional certificates' },
			},
			support: {
				question: 'Do you need some help?',
				desc: 'We are always ready to help you and answer any question',
				btn: 'Contact us'
			},
			courses: {
				title: 'Our courses',
				left: 'Cosmetology',
				right: 'Aesthetic medicine',
				btn: 'See all courses'
			},
			author: {
				title: 'About the author',
				btn: 'View full story'
			},
			offers: {
				title: {
					txt: 'Special',
					color: 'offers'
				}
			}
		}
	})
}








