import { render, staticRenderFns } from "./arrowDown.vue?vue&type=template&id=2df509ff&scoped=true&"
var script = {}
import style0 from "./arrowDown.vue?vue&type=style&index=0&id=2df509ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df509ff",
  null
  
)

export default component.exports