<script>
    export default {
        props: ['modalActive'],
        methods: {
            closeModal () {
                this.$emit('close-modal')
            }
        }
    }
</script>

<template>
    <div
      class="modal__wrapper"
      :class="{ active: modalActive }"
      @click.self="closeModal"
    >
        <div class="modal__substrate">
            <div class="top">
                <slot name="title"></slot>
                <div class="close__btn" @click="closeModal">
                    <svg
                    class="cls"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z"
                        fill="white"
                    ></path>
                    </svg>
                </div>
            </div>
            <slot></slot>
            <div class="modal__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.modal__wrapper {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    width: 100%;
    height: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: rgba(20, 20, 24, 0.5);
    .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .close__btn {
            width: 36px;
            height: 36px;
            display: flex;
            flex-shrink: 0;
            cursor: pointer;
            border-radius: 100%;
            align-items: center;
            background: #000001;
            justify-content: center;
        }
    }
}

.modal__substrate {
    gap: 25px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.modal__wrapper.active {
    display: flex;
}

.modal__footer {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
}

</style>