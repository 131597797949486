<template>
<footer ref="footer">
    <div class="cnt">
      <div class="top">
        <div class="txt">
          <router-link class="logo" to="/">
            <img style="width:50%" src="/img/logo-white1.svg" alt="">
          </router-link>
          <span>{{ $t('footer.desc') }}</span>
        </div>
        <div class="links__row">
          <div class="wrapp">
            <div class="links__col">
              <router-link :to="{name: 'category', params:{lang: langStorage}}">{{ $t('footer.courses') }}</router-link>
              <router-link 
                v-if="isLogin && checkCity && checkUserType" 
                :to="{name: 'shopCategory', params:{lang: langStorage}}"
              >
                {{ $t('footer.shop') }}
              </router-link>
              <a class="shop-lock"
                style="cursor:pointer; color: rgba(81, 80, 118, 0.7)"
                :data-title="textTranslate.closeShop[langStorage]"
                v-else
              >
                {{  $t('footer.shop') }}
                <svg class="icon-lock" width="15px" height="15px" fill="rgba(81, 80, 118, 0.7)">
                  <use xlink:href="/icons.svg#lock"></use>
                </svg>
              </a>
              <!-- <router-link to="/specialists">{{ $t('footer.specialists') }}</router-link> -->
              <router-link to="/about">{{ $t('footer.about') }}</router-link>
              <router-link to="/blog">{{ $t('footer.blog') }}</router-link>
            </div>
            <div class="links__col">
              <router-link to="/login">{{ $t('footer.login') }}</router-link>
              <router-link to="/registration">{{ $t('footer.registration') }}</router-link>
              <template v-if="checkCountryRu">
                <a style="opacity:1" target="_blank" :href="`/policy_ru/policy_ru.pdf`" class="faded">{{ $t('footer.provisions') }}</a>
                <a style="opacity:1" target="_blank" :href="`/conditions_ru/conditions_ru.pdf`" class="faded">{{ $t('footer.policy') }}</a>
                <a style="opacity:1" target="_blank" :href="`/terms_ru/terms_ru.pdf`" class="faded">{{$t('footer.security')}}</a>
              </template>
              <template v-else-if="generationPolice">
                <a style="opacity:1" target="_blank" :href="`/policy_md/policy_${langStorage}.pdf`" class="faded">{{ $t('footer.provisions') }}</a>
                <a style="opacity:1" target="_blank" :href="`/terms_md/terms_${langStorage}.pdf`" class="faded">{{ $t('footer.policy') }}</a>
              </template>
              <template v-else>
                <a style="opacity:1" target="_blank" :href="`/policy_ro/policy_ro.pdf`" class="faded">{{  $t('footer.provisions') }}</a>
                <a style="opacity:1" target="_blank" :href="`/terms_ro/terms_ro.pdf`" class="faded">{{ $t('footer.policy') }}</a>
              </template>
            </div>
          </div>
          <div class="payment_services">
              <img src="/img/payment_services/visa.png" alt="visa">
              <img src="/img/payment_services/visaelectron.png" alt="visaelectron">
           
              <img src="/img/payment_services/mastercard.svg" alt="mastercard">
              <img src="/img/payment_services/maestro.png" alt="maestro">
              <img src="/img/payment_services/cirrus.png" alt="mastercard">
              <img v-if="checkCounryRo" src="/img/payment_services/platescro.svg" alt="platescro">
              <img v-else-if="checkCountryRu" src="/img/payment_services/prodamusLogo.png" alt="prodamus">
              <img v-else src="/img/payment_services/paynet.png" alt="paynet">
          </div>
        </div>
        <div class="contact__col">
          <div class="details">
            <span>{{ $t('footer.message') }}</span>
            <a href="mailto:info@lica.doctor" class="email">info@lica.doctor</a>
            <a v-if="langStorage === 'es'" :href="`tel:${regionPhone}`" class="footer_phone">+34605975194</a>
            <a v-else-if="regionPhone" :href="`tel:${regionPhone}`" class="footer_phone">{{regionPhone}}</a>
            <span v-if="langStorage === 'es'">C/Libreros,1 1A 28806 Alcalá de Henares-Madrid</span>
            <span v-if="regionAddress">{{regionAddress}}</span>
          </div>
          <div class="social__row">
            <a target="_blank" href="https://instagram.com/lica.doctor">
              <img src="/img/ig-logo.svg" alt="">
            </a>
            <a target="_blank" href="https://www.facebook.com/doctorlicabeautyacademy/">
              <img src="/img/fb-logo.svg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="btm">
        <div class="copyright">
          Copyright © {{year}} SRL „Doctor Lica Salon”. All rights reserved.
        </div>
        <div class="powered">
          Powered by
          <a target="_blank" href="https://alsodev.com/ru/">AlsoDev</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LangRu from '@/lang/components/Footer/ru' 
import LangRo from '@/lang/components/Footer/ro' 
import LangEng from '@/lang/components/Footer/eng'
import LangEs from '@/lang/components/Footer/es' 

import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  beforeMount(){
    this.getRegionInfo();
  },
  data(){
    return {
      date: new Date(),
      textTranslate: {
        closeShop: {
          ru: 'недоступно',
          ro: 'nu este disponibil',
          en: 'not available',
          es: 'no disponible'
        }
      },
      regionInfo: {
        phone_eu: '',
        phone_md: '',
        phone_ro: '',
        address_eu: '',
        address_md: '',
        address_ro: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      isLogin: 'user/isLogin',
      checkCity: 'user/checkCountry',
      checkUserType: 'user/checkUserType',
      user: 'user/user',
    }),

    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    regionPhone(){
      if(this.isLogin && this.user.profile?.profile.city == "Romania"){
        return this.regionInfo.phone_ro;
      }
      else if(this.isLogin && this.user.profile?.profile.city == "Moldova, Republic of"){
        return this.regionInfo.phone_md;
      }
      else if(this.isLogin && this.user.profile?.profile.city == "Russian Federation"){
        return this.regionInfo.phone_ru;
      }
      else {
        if(this.user.country.countryCode == "MD") {
          return this.regionInfo.phone_md;
        }
        else if (this.user.country.countryCode == "RO") {
          return this.regionInfo.phone_ro;
        }
        else if(this.user.country.countryCode == "RU"){
          return this.regionInfo.phone_ru;
        }
        return this.regionInfo.phone_eu;
      }
    },
    regionAddress(){
      if(this.isLogin && this.user.profile?.profile.city == "Romania"){
        return this.regionInfo.address_ro;
      }
      else if(this.isLogin && this.user.profile?.profile.city == "Moldova, Republic of"){
        return this.regionInfo.address_md;
      }
      else if(this.isLogin && this.user.profile?.profile.city == "Russian Federation"){
        return this.regionInfo.address_ru
      }
      else {
        if(this.user.country.countryCode == "MD") {
          return this.regionInfo.address_md;
        }
        else if (this.user.country.countryCode == "RO") {
          return this.regionInfo.address_ro;
        }
        else if(this.user.country.countryCode == "RU"){
          return this.regionInfo.address_ru;
        }
        return this.regionInfo.address_eu;
      }
    },
    generationPolice() {
     if(this.isLogin && this.user.profile?.profile.city == "Romania"){
        return false;
     }
     else if(this.user.country.countryCode == "RO"){
       return false;
     }
      return true;
    },
    checkCounryRo(){
      if(!this.isLogin){
       return this.user.country.countryCode == "RO" ? true : false;
      }
      else {
        return this.user.profile?.profile.city == "Romania" ? true : false;
      }
    },
    checkCountryRu(){
      if(!this.isLogin){
        return this.user.country.countryCode == "RU" ? true : false;
      }
      else {
        return this.user.profile?.profile.city == "Russian Federation" ? true : false
      }
    },
    year() {
      return this.date.getFullYear()
    }
  },
  methods: {
    async getRegionInfo(){
      try {
        let request = await fetch(`${domain}/get-phone/`);
        if(request.ok){
          let responce = await request.json();
          if(responce.length) {
            this.regionInfo = responce[0];
          }
        }
        else {
          console.error(request);
        }
      }
      catch(ex){
        console.error(ex);
      }
    },
  }
}
</script>

<style lang="scss">
  .footer_phone {
    margin-bottom: 32px;
    font-weight: 900;
    color: white;
    @media(max-width: 430px){
      margin-top: 15px;
    }
  }
  @media(max-width:430px){
    .logo {
      display: flex;
      justify-content: center;
    }
  }
  footer {
    .shop-lock {
      display: block;;
      position: relative;

      &:hover::after {
        content: attr(data-title);
        position: absolute;
        background-color: #EFEEFD;
        width: 100%;
        padding: 8px;
        border-radius: 12px;
        font-size: 13px;
        line-height: normal;
        color: black;
        top: 95%;
        left: 0px;
        z-index: 1;
      }
    }
    .links__row {
      flex-direction: column;
      .wrapp {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      .payment_services {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media(max-width: 768px){
          justify-content: center;
        }
        img {
          width: 100%;
          max-width: 75px;
          margin: 10px;
          pointer-events: none;
          @media(max-width: 768px){
            max-width: 65px;
            margin: 5px;
          }
        }
      }
    }
  }
</style>