export default {
	data: () => ({
		langRo: {
			header: {
				courses: 'Cursuri',
				shop: 'Magazin',
				specialists: 'Specialiști',
				about: 'Despre noi',
				blog: 'Blog',
        promotions: 'Promoții',
				login: 'Autentificare',
				registration: 'Înregistrare',
				shopHidden: 'numai pentru utilizatorii înregistrați',
				shopClose: 'nu este disponibil în regiunea dvs.',
				dashboard: 'Cont Personal'
			}
		}
	})
}