let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
let lng = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'ro';

if(locale == 'ru' || locale == 'ro' || locale == 'en' || locale == 'fr' || locale == 'es'){
  lng = locale;
}
// 
export default {
    namespaced: true,
    state: {
        lang: lng
    },
    getters: {
        getLang(state){
            return state.lang;
        }
    },
    mutations: {
        setLang(state, payload){
            state.lang = payload;
            window.localStorage.setItem('lang', payload);
        }
    },
    actions: {
        setLang({ commit }, payload){
            commit('setLang', payload);
        }
    }
}