const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    courses: [],
    cosmetology: [],
    medicina: [],
    coursesUpdates: [],
    page: 1,
  },
  getters: {
    getAll(state){
      return state.courses;
    },
    getOne(state){
      return (id) => {
        return state.courses.find(item => id == item.id);
      }
    },
    getIndex(state) {
      return (id) => {
        return state.courses.findIndex(item => id == item.id);
      }
    },
    getPage(state){
      return state.page;
    },
    donePage(state){
      return state.page == countPage;
    },
    getCosmetologyCourses(state){
      return state.cosmetology;
    },
    getMedicinaCourses(state){
      return state.medicina;
    },
    getCoursesUpdates(state){
      return state.coursesUpdates
    }
  },
  mutations: {
    setCourses(state, payload){
      state.courses = payload;
    },
    setPage(state, payload){
      state.page = payload;
    },
    addPage(state, payload){
      state.courses.push(payload);
    },
    setCosmetology(state, payload){
      state.cosmetology = payload;
    },
    setMedicina(state, payload){
      state.medicina = payload;
    },
    setCoursesUpdates(state, payload){
      state.coursesUpdates = payload
    }
  },
  actions: {
    async setCourses({ commit, getters, rootGetters }){
      let lang = rootGetters['languages/getLang'];
      let page = 1;
      commit('setPage', 1);
      let categoryId = rootGetters['category/getSelectId'];
      commit('setCourses', await getCourses(lang, page, categoryId));
    },
    async nextPage({ commit, getters, rootGetters }){
      let lang = rootGetters['languages/getLang'];
      let page = getters.getPage + 1 <= countPage ? getters.getPage + 1 : 1;
      let categoryId = rootGetters['category/getSelectId'];

      const responce = await getCourses(lang, page, categoryId);
      responce.forEach(e => commit('addPage', e));
      commit('setPage', page);
    },
    async getCosmetologyCourses({commit, rootGetters}){
      let lang = rootGetters['languages/getLang'];
      commit("setCosmetology", await getCosmetologyCourses(lang));
    },
    async getMedicinaCourses({commit, rootGetters}){
      let lang = rootGetters['languages/getLang'];
      commit("setMedicina", await getMedicinaCourses(lang));
    },
    async fetchCoursesUpdates ({commit, rootGetters}) {
      let lang = rootGetters['languages/getLang'];
      await fetch(`${domain}/${lang}/courses/courses-updates/`, {
          headers: {
              "Content-Type": 'application/json',
              "Authorization": `Token ${rootGetters['user/user'].token}`
          }
      }).then(async response => { 
            if (!response.ok) return
            commit("setCoursesUpdates", await response.json())
        })
    },
    async markUserHasBeenNotifiedAboutCoursesUpdates ({commit, rootGetters}) {
      let lang = rootGetters['languages/getLang'];
      await fetch(`${domain}/${lang}/courses/courses-updates/clear`, {
        headers: {
            "Content-Type": 'application/json',
            "Authorization": `Token ${rootGetters['user/user'].token}`
        }
      }).then(response => {
        if (response.ok) commit("setCoursesUpdates", [])
      })
    }
  }
}

let countPage = 0;

async function getCourses(lang, page = 1, category, countContent = 8) {
  const response = await fetch(`${domain}/${lang}/courses/course/?page=${page}${category ? '&category=' + category : ''}&page_size=${countContent}`);
  if(response.ok) {
    const data = await response.json();
    countPage = data.count;
    return wrapperCourses(data.results);
  }
  return null;
}

async function getCosmetologyCourses(lang, countContent = 8){
  const response = await fetch(`${domain}/${lang}/courses/course/?category=3&page_size=${countContent}`);
  if(response.ok) {
    const data = await response.json();
    let size = data.results.length >= 4 ? 4 : data.results.length;
    let arr = [];
    for(let i = 0; i < size; i++) {
      arr.push(data.results[i]);
    }
    return arr;
  }
  return null;
}
async function getMedicinaCourses(lang, countContent = 8){
  const response = await fetch(`${domain}/${lang}/courses/course/?category=4&page_size=${countContent}`);
  if(response.ok) {
    const data = await response.json();
    let size = data.results.length >= 4 ? 4 : data.results.length;
    let arr = [];
    for(let i = 0; i < size; i++) {
      arr.push(data.results[i]);
    }
    return arr;
  }
  return null;
}


function wrapperCourses(arr) {
  const filtered = [];
  arr.forEach(e => {
    filtered.push({
      id: e.id,
      image: e.image,
      title: e.name,
      discount: e.discount,
      linkVideo: e.preview_video_link,
      tags: e.brand,
      price: e.price,
      time: e.time,
      category: e.category
    });
  });

  return filtered;
}

