<template>
  <div class="home">
    <div class="hero__slider__wrp">
      <div @click="$refs.carousel.prev()" :style="firstSreen" class="arr prev">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M20.1586 32.5802C19.8317 32.2617 19.8317 31.7363 20.1586 31.4178L42.7153 9.44055C43.0304 9.13355 43.5328 9.13355 43.8479 9.44055C44.1748 9.75906 44.1748 10.2844 43.8479 10.603L21.2912 32.5802C20.9761 32.8872 20.4737 32.8872 20.1586 32.5802Z"
              fill="white"
            />
            <path
              d="M43.8479 54.5593C44.1748 54.2408 44.1748 53.7154 43.8479 53.3969L21.2846 31.4132C20.9695 31.1062 20.4671 31.1062 20.152 31.4132C19.8251 31.7317 19.8251 32.2571 20.152 32.5756L42.7153 54.5593C43.0304 54.8663 43.5328 54.8663 43.8479 54.5593Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      <div @click="$refs.carousel.next()" :style="lastSreen" class="arr next">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.8413 32.5802C44.1683 32.2617 44.1683 31.7363 43.8413 31.4178L21.2847 9.44055C20.9696 9.13355 20.4672 9.13355 20.1521 9.44055C19.8252 9.75906 19.8252 10.2844 20.1521 10.603L42.7088 32.5802C43.0239 32.8872 43.5262 32.8872 43.8413 32.5802Z"
            fill="white"
          />
          <path
            d="M20.1521 54.5593C19.8252 54.2408 19.8252 53.7154 20.1521 53.3969L42.7154 31.4132C43.0305 31.1062 43.5328 31.1062 43.8479 31.4132C44.1748 31.7317 44.1748 32.2571 43.8479 32.5756L21.2846 54.5593C20.9695 54.8663 20.4672 54.8663 20.1521 54.5593Z"
            fill="white"
          />
        </svg>
      </div>
      <HeaderWrp />
      <div v-if="mainSlider.length" class="hero__slider">
        <VueSlickCarousel
          ref="carousel"
          :arrows="true"
          :dots="true"
          :infinite="true"
          :autoplaySpeed="6000"
          :pauseOnHover="false"
          :adaptiveHeight="true"
          @beforeChange="ChengeScreen"
        >
          <!-- :style="{background: `url(${slide.image}) no-repeat`, backgroundSize: 'cover'}" -->
          <div
            v-for="slide in generateMainSlider"
            :key="slide.id"
            :class="{ pointer: slide.button_link }"
            class="img__cont"
            @mousedown="redirectStart = $event.clientX"
            @mouseup="redirectTo($event, slide.button_link)"
          >
            <img :src="slide.image" alt="" />
            <div class="hero__description">
              <div class="top">
                <h1 class="inner-p" v-html="slide.title"></h1>
                <!--<b>selected text opasity</b>-->
                <div class="right">
                  <span
                    class="inner-p"
                    v-if="mobileText"
                    v-html="slide.description"
                  >
                  </span>
                  <a v-if="slide.button_is_active" class="choose__btn">
                    {{ slide.button_name }}
                  </a>
                  <!-- <router-link class="choose__btn" to="/category">{{ selectLang.slider.btn }}</router-link> -->
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="our__courses">
      <div class="cnt">
        <h3>{{ $t("home.courses.title") }}</h3>
        <div class="types">
          <div
            class="type"
            :class="{ active: currentTab === 'cosmetology' }"
            @click="changeTab('cosmetology')"
            id="courseTag1"
          >
            {{ $t("home.courses.left") }}
          </div>
          <div
            class="type"
            :class="{ active: currentTab === 'medicine' }"
            @click="changeTab('medicine')"
            id="courseTag2"
          >
            {{ $t("home.courses.right") }}
          </div>
          <div
            class="type"
            :class="{ active: currentTab === 'massage' }"
            @click="changeTab('massage')"
            id="courseTag3"
          >
            {{ $t("home.courses.massage") }}
          </div>
        </div>
        <div
          class="courses__row"
          :class="{ active: currentTab === 'cosmetology' }"
          id="courseRow1"
        >
          <div
            class="card md"
            v-for="item in filterCosmetologyCourses"
            :key="item.id"
          >
            <router-link
              class="img__cont"
              :to="{ name: 'course', params: { id: item.id } }"
            >
              <div class="new" v-if="item.is_new">New</div>
              <img :src="item.image" alt="" />
              <div class="play__btn">
                <svg
                  width="25"
                  height="30"
                  viewBox="0 0 25 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 16.732C25.3333 15.9622 25.3333 14.0378 24 13.268L3 1.14359C1.66666 0.373795 0 1.33605 0 2.87565L0 27.1244C0 28.664 1.66667 29.6262 3 28.8564L24 16.732Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div v-if="item.discount" class="discount">
                {{ item.discount }}%
              </div>
            </router-link>
            <router-link
              class="title"
              :to="{ name: 'course', params: { id: item.id } }"
              >{{ item.title }}</router-link
            >
            <div class="tags">
              <a
                class="tag"
                style="background-color: #dfdcf2"
                v-for="brand in item.brand"
                :key="brand.id"
                >{{ brand.title }}</a
              >
            </div>
            <div class="old_price">
              <template v-if="item.discount">
                {{ item.price }} &#8364;
              </template>
            </div>
            <div class="price__row">
              <div class="price">
                {{
                  Math.trunc(
                    Number((item.price / 100) * item.discount) +
                      Number(item.price)
                  )
                }}
                &#8364;
              </div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div
          class="courses__row"
          :class="{ active: currentTab === 'medicine' }"
          id="courseRow2"
        >
          <div
            class="card md"
            v-for="item in filterMedicinaCourses"
            :key="item.id"
          >
            <router-link
              class="img__cont"
              :to="{ name: 'course', params: { id: item.id } }"
            >
              <div v-if="item.is_new" class="new">New</div>
              <img :src="item.image" alt="" />
              <div class="play__btn">
                <svg
                  width="25"
                  height="30"
                  viewBox="0 0 25 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 16.732C25.3333 15.9622 25.3333 14.0378 24 13.268L3 1.14359C1.66666 0.373795 0 1.33605 0 2.87565L0 27.1244C0 28.664 1.66667 29.6262 3 28.8564L24 16.732Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div v-if="item.discount" class="discount">
                {{ item.discount }}%
              </div>
            </router-link>
            <router-link
              class="title"
              :to="{ name: 'course', params: { id: item.id } }"
              >{{ item.title }}</router-link
            >
            <div class="tags">
              <a
                class="tag"
                style="background-color: #dfdcf2"
                v-for="brand in item.brand"
                :key="brand.id"
                >{{ brand.title }}</a
              >
            </div>
            <div class="old_price">
              <template v-if="item.discount">
                {{ item.price }} &#8364;
              </template>
            </div>
            <div class="price__row">
              <div class="price">
                {{
                  Math.trunc(
                    Number((item.price / 100) * item.discount) +
                      Number(item.price)
                  )
                }}
                &#8364;
              </div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div
          class="courses__row"
          :class="{ active: currentTab === 'massage' }"
          id="courseRow3"
        >
          <div
            class="card md"
            v-for="item in filterMassageCourses"
            :key="item.id"
          >
            <router-link
              class="img__cont"
              :to="{ name: 'course', params: { id: item.id } }"
            >
              <div v-if="item.is_new" class="new">New</div>
              <img :src="item.image" alt="" />
              <div class="play__btn">
                <svg
                  width="25"
                  height="30"
                  viewBox="0 0 25 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 16.732C25.3333 15.9622 25.3333 14.0378 24 13.268L3 1.14359C1.66666 0.373795 0 1.33605 0 2.87565L0 27.1244C0 28.664 1.66667 29.6262 3 28.8564L24 16.732Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div v-if="item.discount" class="discount">
                {{ item.discount }}%
              </div>
            </router-link>
            <router-link
              class="title"
              :to="{ name: 'course', params: { id: item.id } }"
              >{{ item.title }}</router-link
            >
            <div class="tags">
              <a
                class="tag"
                style="background-color: #dfdcf2"
                v-for="brand in item.brand"
                :key="brand.id"
                >{{ brand.title }}</a
              >
            </div>
            <div class="old_price">
              <template v-if="item.discount">
                {{ item.price }} &#8364;
              </template>
            </div>
            <div class="price__row">
              <div class="price">
                {{
                  Math.trunc(
                    Number((item.price / 100) * item.discount) +
                      Number(item.price)
                  )
                }}
                &#8364;
              </div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <router-link 
          class="view__all"
          :to="{ name: 'category', hash: this.$route.hash ? this.$route.hash : '#cosmetology' }"
        >
          {{ $t("home.courses.btn") }}
          <img src="/img/success-forward.svg" alt="" />
        </router-link>
      </div>
    </div>
    <div class="sponsors__row">
      <VueSlickCarousel
        :arrows="false"
        :slidesToShow="5"
        :responsive="sponsorsSlider"
      >
        <div v-for="item in 5" :key="item">
          <div class="partner_item">
            <img :src="`/img/partners/${item}.png`" alt="partner" />
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="advantages__row">
      <div class="card blue">
        <img src="/img/adv-video.jpg" alt="" />
        <div class="txt">
          <strong>{{ $t("home.about.video.title") }}</strong>
          <span>{{ $t("home.about.video.desc") }}</span>
        </div>
      </div>
      <div class="card purple">
        <img src="/img/adv-consult.jpg" alt="" />
        <div class="txt">
          <strong>{{ $t("home.about.support.title") }}</strong>
          <span>{{ $t("home.about.support.desc") }}</span>
        </div>
      </div>
      <div class="card green">
        <img src="/img/adv-pro.jpg" alt="" />
        <div class="txt">
          <strong>{{ $t("home.about.web.title") }}</strong>
          <span>{{ $t("home.about.web.desc") }}</span>
        </div>
      </div>
      <div class="card pink">
        <img src="/img/adv-result.jpg" alt="" />
        <div class="txt">
          <strong>{{ $t("home.about.results.title") }}</strong>
          <span>{{ $t("home.about.results.desc") }}</span>
        </div>
      </div>
    </div>
    <PersonalSlider
      v-if="personalSlider.length"
      :reviews="personalSlider"
      :infinite="true"
    />
    <!-- <div class="help__wrp">
    <h3>{{ $t('home.support.question') }}</h3>
    <span>{{ $t('home.support.desc') }}</span>
    <a href="#" class="contact__btn" v-scroll-to="{ el: '.objective__cnt', offset: -110 }">
      {{ $t('home.support.btn') }}
    </a>
  </div> -->
    <about-author
      v-if="AboutTheAuthor.length"
      :title="AboutTheAuthor[0].title"
      :name="AboutTheAuthor[0].sub_title"
      :desc="AboutTheAuthor[0].description_short"
      :images="AboutTheAuthor[0].gallery_about_author"
    >
    </about-author>
    <div class="special__offers" v-if="filterSpecialOffers.length">
      <div class="top">
        <h3>
          {{ $t("home.offers.title.txt") }}
          <b>{{ $t("home.offers.title.color") }}</b>
        </h3>
        <div class="arrows">
          <div class="arr prev" @click="$refs.SliderOffers.prev()">
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.1587 32.5802C19.8317 32.2617 19.8317 31.7363 20.1587 31.4178L42.7153 9.44052C43.0304 9.13352 43.5328 9.13351 43.8479 9.44052C44.1748 9.75903 44.1748 10.2844 43.8479 10.6029L21.2912 32.5802C20.9761 32.8872 20.4738 32.8872 20.1587 32.5802Z"
                fill="#000001"
              />
              <path
                d="M43.8479 54.5593C44.1748 54.2408 44.1748 53.7154 43.8479 53.3969L21.2846 31.4132C20.9695 31.1062 20.4672 31.1062 20.1521 31.4132C19.8252 31.7317 19.8252 32.2571 20.1521 32.5756L42.7154 54.5593C43.0305 54.8663 43.5328 54.8663 43.8479 54.5593Z"
                fill="#000001"
              />
            </svg>
          </div>
          <div class="arr next" @click="$refs.SliderOffers.next()">
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43.8413 32.5802C44.1683 32.2617 44.1683 31.7363 43.8413 31.4178L21.2847 9.44052C20.9696 9.13352 20.4672 9.13351 20.1521 9.44052C19.8252 9.75903 19.8252 10.2844 20.1521 10.6029L42.7088 32.5802C43.0239 32.8872 43.5262 32.8872 43.8413 32.5802Z"
                fill="#000001"
              />
              <path
                d="M20.1521 54.5593C19.8252 54.2408 19.8252 53.7154 20.1521 53.3969L42.7154 31.4132C43.0305 31.1062 43.5328 31.1062 43.8479 31.4132C44.1748 31.7317 44.1748 32.2571 43.8479 32.5756L21.2846 54.5593C20.9695 54.8663 20.4672 54.8663 20.1521 54.5593Z"
                fill="#000001"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="special__offers__slider">
        <VueSlickCarousel
          ref="SliderOffers"
          :arrows="false"
          :infinite="false"
          :slidesToShow="4"
          :responsive="offersSlider"
          :touchMove="touchMoveCarousel"
          :key="filterSpecialOffers.length"
        >
          <div v-for="card in filterSpecialOffers" :key="card.id">
            <div class="card md">
              <router-link
                tag="div"
                class="img__cont"
                :to="{ name: card.link, params: { id: card.id } }"
              >
                <div v-if="card.is_new" class="new">New</div>
                <img :src="card.image" alt="" />
                <div class="play__btn" v-if="card.type != 'product'">
                  <svg
                    width="25"
                    height="30"
                    viewBox="0 0 25 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 16.732C25.3333 15.9622 25.3333 14.0378 24 13.268L3 1.14359C1.66666 0.373795 0 1.33605 0 2.87565L0 27.1244C0 28.664 1.66667 29.6262 3 28.8564L24 16.732Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div v-if="card.discount" class="discount">
                  {{ card.discount }}%
                </div>
              </router-link>
              <router-link
                tag="div"
                class="title"
                :to="{ name: card.link, params: { id: card.id } }"
                >{{ card.title }}</router-link
              >
              <div class="tags">
                <a class="tag" v-for="brand in card.tags" :key="brand.id">{{
                  brand.title
                }}</a>
              </div>
              <div class="old_price">
                <template
                  v-if="card.type == 'product' && parseFloat(card.old_price)"
                >
                  {{ card.old_price }}
                </template>
                <template v-else-if="card.discount">
                  {{ card.price }} &#8364;
                </template>
              </div>
              <div class="price__row">
                <div class="price" v-if="card.type != 'product'">
                  {{
                    Math.trunc(
                      Number((card.price / 100) * card.discount) +
                        Number(card.price)
                    )
                  }}
                  &#8364;
                </div>
                <div class="price" v-else>
                  {{ card.price }}
                </div>
                <div class="time">{{ card.time }}</div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <ContactForm />

    <FaqWrp />
    <CoolLightBox
      :items="lightboxVideoItems"
      :index="indexVideoPopup"
      @close="indexVideoPopup = null"
    >
    </CoolLightBox>
    <FooterApp />
  </div>
</template>

<script>
import LangRu from "@/lang/Home/ru";
import LangRo from "@/lang/Home/ro";
import LangEng from "@/lang/Home/eng";
import LangEs from "@/lang/Home/es";
import HeaderWrp from "@/components/HeaderWrp";
import FooterApp from "@/components/FooterApp";
import VueSlickCarousel from "vue-slick-carousel";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import FaqWrp from "@/components/FaqWrp";
import PersonalSlider from "@/components/PersonalSlider";
import ContactForm from "@/components/ContactForm";
import AboutAuthor from "@/components/AboutAuthor";
import SelectorLang from "@/components/SelectorLang";
import { mapActions, mapGetters } from "vuex";

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    FaqWrp,
    VueSlickCarousel,
    PersonalSlider,
    ContactForm,
    CoolLightBox,
    "about-author": AboutAuthor,
    SelectorLang,
    FooterApp,
    HeaderWrp,
  },
  data() {
    return {
      token: "",
      indexScreen: 0,
      indexVideoPopup: null,

      sponsorsSlider: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
      offersSlider: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 430,
          settings: {
            arrows: false,
            dots: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],

      ourSlider: true,
      lang: "",
      mainSlider: [],
      AboutTheAuthor: [],
      redirectStart: 0,
      currentTab: "cosmetology",
    };
  },
  metaInfo() {
    return this.langStorage == "ru"
      ? {
          title:
            "Профессиональные курсы по косметологии и эстетической медицине",
          meta: [
            {
              vmid: "title",
              name: "title",
              content:
                "Профессиональные курсы по косметологии и эстетической медицине",
            },
            {
              vmid: "description",
              name: "description",
              content:
                "Первая Академия красоты, специализирующаяся на косметологии и эстетической медицине. Академия красоты Lica Doctor проводит курсы для новичков и профессионалов в сфере косметологии.",
            },
          ],
        }
      : this.langStorage == "ro"
      ? {
          title: "Cursuri profesionale de cosmetologie și medicină estetică",
          meta: [
            {
              vmid: "title",
              property: "og:title",
              content:
                "Cursuri profesionale de cosmetologie și medicină estetică - Lica Doctor",
            },
            {
              vmid: "description",
              property: "og:description",
              content:
                "Lica doctor - prima Academie de frumusețe, specializată în cosmetologie și medicină estetică. Cursuri pentru începători și profesioniști în domeniul cosmetologiei",
            },
          ],
        }
      : this.langStorage == 'es'
      ? {
          title: "Cursos profesionales de cosmetología y medicina estética",
          meta: [
            {
              vmid: "title",
              property: "og:title",
              content:
                "Cursos de Cosmetología y Formación en Medicina Estética - Lica Doctor",
            },
            {
              vmid: "description",
              property: "og:description",
              content:
                "La primera Academia de belleza especializada en cosmetología y medicina estética. La Academia de Belleza Lica Doctor ofrece cursos para principiantes y profesionales en el campo de la cosmetología.",
            },
          ],
      } : {
          title: "Professional courses of cosmetology and aesthetic medicine",
          meta: [
            {
              vmid: "title",
              property: "og:title",
              content:
                "Cosmetology courses & Aesthetic medicine training - Lica Doctor",
            },
            {
              vmid: "description",
              property: "og:description",
              content:
                "The first beauty Academy specializing in cosmetology and aesthetic medicine. Beauty Academy Lica Doctor offers courses for beginners and professionals in the field of cosmetology.",
            },
          ],
        };
  },
  async beforeMount() {
    this.getMainSldier();
    this.getAboutTheAuthor();
    await this.setProducts(this.countryCode);
  },
  async mounted() {
    await this.sendLogin();
    this.scrollHeader();
    this.lang = this.langStorage;
    this.currentTab = location.hash ? location.hash.slice(1) : "cosmetology";
  },
  watch: {
    lang() {
      this.setLang(this.lang);
      this.setProducts(this.countryCode);
    },
    langStorage() {
      this.getMainSldier();
      this.getAboutTheAuthor();
      this.$store.dispatch("personalSlider/setSlider");
      this.initCourses();
    },
  },
  methods: {
    changeTab(tabName) {
      this.currentTab = tabName;
      location.hash = tabName;
    },
    scrollHeader() {
      const body = document.body;
      const scrollUp = "scroll-up";
      const scrollDown = "scroll-down";
      let lastScroll = 0;
      window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
          body.classList.remove(scrollUp);
          return;
        }
        if (
          currentScroll > lastScroll &&
          !body.classList.contains(scrollDown)
        ) {
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown);
        } else if (
          currentScroll < lastScroll &&
          body.classList.contains(scrollDown)
        ) {
          body.classList.remove(scrollDown);
          body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
      });
    },
    ...mapActions({
      setDarkMode: "setDarkMode",
      setLang: "languages/setLang",
      setMobileMenu: "setMobileMenu",
      initCourses: "shopCourses/initializeCourses",
      setProducts: "shopCards/setItems",
      editUser: "user/user"
    }),
    async sendLogin() {
      this.token = this.$route.query.token;
      if (this.token !== undefined) {
        const responseUres = await fetch(`${domain}/auth/users/me/`, {
          headers: {
            Authorization: `Token ${this.token}`,
          },
        });
        const dataUser = await responseUres.json();
        this.editUser({name: 'userLogin', value: dataUser.username});
        this.editUser({name: 'userID', value: dataUser.id});
        this.editUser({name: 'token', 'value': this.token});
        let profile = await fetch(`${domain}/accounts/users/${dataUser.id}/`);
        profile = await profile.json();
        this.editUser({name: 'profile', 'value': profile});
        this.editUser({name: 'userName', 'value': profile.profile.name});
        this.editUser({name: 'specialization', value: profile.profile.specialization});
        this.editUser({name: 'avatar', value: profile.profile.image});
        delete this.$route.query.token;
        this.$router.push({
          name: "courses",
          params: { lang: this.langStorage },
        });
        this.badRequest = true;
        this.login = "";
        this.password = "";
      }
    },
    ChengeScreen(e, i) {
      this.indexScreen = i;
    },
    onModeChenge() {
      this.setDarkMode(!this.darkMode);
    },
    async getMainSldier() {
      const response = await fetch(
        `${domain}/${this.langStorage}/get-general-home-page-slider/`
      );
      this.mainSlider = await response.json();
    },
    async getAboutTheAuthor() {
      const response = await fetch(
        `${domain}/${this.langStorage}/about-author-slider/`
      );
      if (response.ok) {
        this.AboutTheAuthor = await response.json();
      } else {
        this.AboutTheAuthor = [
          {
            description_short: "Desc responce error",
            gallery_about_author: [],
            specialization: "specialization responce error",
            sub_title: "responce error",
            title: "responce error",
          },
        ];
      }
    },
    redirectTo(event, url) {
      const blank = event.ctrlKey || event.metaKey;
      const swipe = Math.abs(this.redirectStart - event.clientX) < 3;

      if (url && swipe && blank) {
        window.open(url);
      } else if (url && swipe) {
        window.location.href = url;
      }
    },
  },
  computed: {
    mobileText() {
      let w = window.innerWidth;
      if (w > 767) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      courses: "shopCourses/getAll",
      langStorage: "languages/getLang",
      isLogin: "user/isLogin",
      personalSlider: "personalSlider/getAll",
      darkMode: "darkMode",
      user: "user/user",
      checkCountry: "user/checkCountry",
      productsStore: "shopCards/getAll",
    }),
    firstSreen() {
      return this.indexScreen == 0 ? { opacity: 0.5 } : { opacity: 1 };
    },
    lastSreen() {
      return this.indexScreen == 2 ? { opacity: 0.5 } : { opacity: 1 };
    },
    lightboxVideoItems() {
      return this.courses.map((course) => ({
        title: course.title,
        description: course.price + " MDL",
        src: course.linkVideo,
      }));
    },
    selectLang() {
      if (this.langStorage == "ro") {
        return this.langRo;
      } else if (this.langStorage == "ru") {
        return this.langRu;
      } else if (this.langStorage == "es") {
        return this.langEs;
      }
      return this.langEng;
    },
    touchMoveCarousel() {
      return window.innerWidth > 430 ? false : true;
    },
    filterCosmetologyCourses() {
      let i = 0;
      let arr = [];
      for (const item of this.courses) {
        if (item.category == 3) {
          arr.push(item);
          ++i;
        }
        if (i == 4) break;
      }
      return arr;
    },
    filterMedicinaCourses() {
      let i = 0;
      let arr = [];
      for (const item of this.courses) {
        if (item.category == 4) {
          arr.push(item);
          ++i;
        }
        if (i == 4) break;
      }
      return arr;
    },
    filterMassageCourses() {
      let i = 0;
      let arr = [];
      for (const item of this.courses) {
        if (item.category == 8) {
          arr.push(item);
          ++i;
        }
        if (i == 4) break;
      }
      return arr;
    },
    filterSpecialOffers() {
      let i = 0;
      let arr = [];
      for (const item of this.courses) {
        if (typeof item.discount == "number" && item.discount != 0) {
          arr.push({
            ...item,
            link: "course",
          });
          ++i;
        }
        if (i == 8) break;
      }

      return [...arr, ...this.filterSpecialOfferProducts];
    },
    isMobile() {
      return window.innerWidth <= 430;
    },
    userAvatar() {
      if (this.user.profile?.profile) {
        let img = this.user.profile?.profile.image;
        return img != null && img != "null" ? img : "/img/avatar-dashboard.png";
      }
      return "/img/avatar-dashboard.png";
    },
    generateMainSlider() {
      let w = window.innerWidth;
      let str = "image_mobile_thumbnail";

      if (w > 567) {
        str = "image_tablet_thumbnail";
      }
      if (w > 1023) {
        str = "image_laptop_thumbnail";
      }
      if (w > 1680) {
        str = "image_pc_thumbnail";
      }
      console.log("width device:", str);
      let ar = [];
      this.mainSlider.forEach((el) => {
        ar.push({
          ...el,
          image: el[str],
        });
      });
      return ar;
    },
    countryCode() {
      return this.user.profile?.profile?.city == "Romania"
        ? "ro"
        : this.user.profile?.profile?.city == "Moldova, Republic of"
        ? "md"
        : "ru";
    },
    currency() {
      return this.user.profile?.profile?.city == "Romania"
        ? "RON"
        : this.user.profile?.profile?.city == "Moldova, Republic of"
        ? "MDL"
        : "RUB";
    },
    price() {
      if (this.user.profile?.profile.city == "Moldova, Republic of") {
        return "price";
      } else if (this.user.profile?.profile.city == "Romania") {
        return "price_ro";
      } else {
        return "price_ru";
      }
    },
    oldPrice() {
      if (this.user.profile?.profile.city == "Moldova, Republic of") {
        return "price_old";
      } else if (this.user.profile?.profile.city == "Romania") {
        return "price_old_ro";
      } else {
        return "price_old_ru";
      }
    },
    discountPrice() {
      if (this.user.profile?.profile.city == "Moldova, Republic of") {
        return "discount";
      } else if (this.user.profile?.profile.city == "Romania") {
        return "discount_ro";
      } else {
        return "discount_ru";
      }
    },
    filterProductForSpecialist() {
      if (this.user.profile?.profile?.type == "doctor") {
        return this.productsStore.results.filter((el) => el.doctor);
      } else if (this.user.profile?.profile?.type == "cosmetology") {
        return this.productsStore.results.filter((el) => el.cosmetolog);
      } else {
        return this.productsStore.results;
      }
    },
    is_special_offer() {
      if (this.user.profile?.profile.city == "Moldova, Republic of") {
        return "is_special_offer";
      } else if (this.user.profile?.profile.city == "Romania") {
        return "is_special_offer_ro";
      } else {
        return "is_special_offer_ru";
      }
    },
    filterSpecialOfferProducts() {
      const products = this.filterProductForSpecialist.filter(
        (el) => el[this.is_special_offer]
      );
      return products.map((el) => {
        return {
          type: "product",
          category: el.category,
          discount: el[this.discountPrice],
          id: el.id,
          image: el.image_thumbnail,
          linkVideo: "",
          price: !!el[this.price] ? `${el[this.price]} ${this.currency}` : "",
          old_price: !!el[this.oldPrice]
            ? `${el[this.oldPrice]} ${this.currency}`
            : "",
          tags: el.brand,
          time: el.weight_packaging,
          title: el.title,
          link: "shop-item",
        };
      });
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.our__courses {
  .cnt {
    .courses__row {
      align-items: stretch;
      .card {
        .old_price {
          margin-top: auto;
        }
      }
    }
  }
}

.special__offers__slider {
  .card {
    .tags {
      &::-webkit-scrollbar-thumb {
        background: none;
        box-shadow: none;
      }
    }
  }
}
.hero__slider__wrp {
  margin-top: 6.8em;
  @media (max-width: 768px) {
    margin-top: 4.8em;
  }
  @media (max-width: 414px) {
    padding: 0;
  }
}
@media (max-width: 414px) {
  .hero__slider__wrp .hero__slider .img__cont img {
    border-radius: 0;
  }
}

@media (max-width: 430px) {
  .hero__slider__wrp .hero__description .top .right .choose__btn {
    height: 55px !important;
    margin-top: 11em;
    font-size: 10px;
    padding: 0 12px;
    line-height: 16px !important;
    width: 102px !important;
    margin-left: 2em !important;
    height: 40px !important;
    text-align: center !important;
  }
  h1.inner-p {
    margin-left: 1em !important;
    text-align: left !important;
  }
  .hero__slider__wrp .hero__description .top {
    flex-direction: row !important;
  }
  .hero__slider__wrp .hero__description .top .right span {
    margin-left: 7em !important;
    font-size: 15px !important;
  }
}
.hero__slider__wrp .arr .next {
  right: 366px;
}

.scroll-down .header_wrp {
  transform: translate3d(0, -100%, 0);
}
.scroll-up .header_wrp {
  max-width: 100%;
  border-bottom: 1px solid rgba(0, 0, 1, 0.05);
  display: flex;
  justify-content: center;
  background: #fff;
  transform: none;
}
.scroll-up header {
  margin-top: 0;
}
.scroll-up .header_wrp header .links a {
  color: black !important;
}
.scroll-up .header_wrp header .right .login__box .login.aush {
  color: black;
}
.scroll-up .header_wrp header .right .mode {
  background-image: url("/img/moon.svg");
}
.scroll-up .header_wrp .right .dropdown .lang .caption {
  color: rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
}
.scroll-up .hero-logo {
  content: url("/img/logo2.svg");
}
.scroll-up .icon-lock {
  fill: black;
}
.scroll-up .header_wrp header .right .login__box span.login {
  color: black;
}
.scroll-up .header_wrp header .right .login__box span.register {
  color: black;
}
.mobile-logo {
  content: url("/img/menu-icon.svg");
}
.scroll-up .mobile-logo {
  content: url("/img/mobile-menu.svg");
}
.hero-logo {
  content: url("/img/logo-white1.svg");
}
.header_wrp {
  height: 88px;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: transform 0.3s ease;
  width: 100%;
  display: flex;
  justify-content: center;
}
.special__offers__slider {
  .card {
    cursor: pointer;
  }
  .slick-dots {
    position: unset;
  }
}

.sponsors__row {
  .slick-slider {
    width: 100%;
  }
  @media (min-width: 430px) {
    margin: 50px auto;
  }

  .partner_item {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 65%;
      object-fit: scale-down;
    }
  }
}
.lang {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  option {
    color: #6b6b78;
  }
}

.login.aush {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.hero-logo {
  width: 150px;
}

.advantages__row .card {
  cursor: default !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -khtml-border-radius: 50%;
  }
}

.dropdown.lang {
  margin-right: 20px !important;
}
.shop-lock {
  display: block;
  position: relative;

  &:hover::after {
    content: attr(data-title);
    position: absolute;
    width: 145px;
    background-color: #ffffff9e;
    padding: 8px;
    border-radius: 12px;
    font-size: 14px;
    line-height: normal;
    color: black;
    top: 120%;
    left: 0;
    z-index: 1;
  }
}

.home .view__all {
  white-space: nowrap;
}
.home {
  .card {
    .new {
      position: absolute;
      top: 24px;
      right: 24px;
      background-color: #eb236b;
      border-radius: 12px 64px 64px 64px;
      height: 72px;
      width: 92px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #fff;
      z-index: 1;
    }
  }
  .profile {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
body.night .home {
  .partner_item {
    img {
      filter: invert(1);
    }
  }
}

.hero__slider__wrp .hero__description {
  margin: 4em 0 auto;
}
@media (max-width: 430px) {
  .hero__slider__wrp .hero__description {
    margin-top: 0 !important;
    display: flex;
    font-size: 15px !important;
    flex-direction: row;
  }
}
@media (min-width: 430px) {
  .hero__slider__wrp .hero__description .top h1 {
    font-size: 32px !important;
    line-height: 42px !important;
    max-width: 800px !important;
  }
}

.hero__slider__wrp header {
  padding: 0;
}
@media (max-width: 1800px) {
  .hero__slider__wrp .slick-dots {
    bottom: 15px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1800px) {
  .hero__slider__wrp .hero__description {
    margin: 3em auto;
    margin-left: 11em;
  }
}
@media (max-width: 1200px) {
  .sponsors__row {
    .partner_item {
      // width: 214px;
      height: 64px;
    }
  }
}
@media (max-width: 1200px) and (min-width: 430px) {
  .hero__slider__wrp .hero__description .top h1 {
    font-size: 32px !important;
    line-height: 45px !important;
  }
  .hero__slider__wrp .hero__description .top .right span {
    font-size: 16px !important;
  }
  .hero__slider__wrp .hero__description {
    margin: 84px auto 0 !important;
  }
}
@media (min-width: 1920px) {
  .hero__slider__wrp .hero__description .top .right {
    margin-left: 27em !important;
    width: fit-content;
  }
  @media (min-width: 1201px) and (max-width: 1800px) {
    .hero__slider__wrp .hero__description .top .right {
      margin-left: 100px !important;
      width: fit-content;
    }
  }
  .hero__slider__wrp .hero__description .top .right .choose__btn {
    width: fit-content;
    // width: 300px !important;
  }
}
@media (max-width: 430px) {
  .hero__description {
    width: 100% !important;
  }
  .top {
    justify-content: space-between !important;
  }
  .special__offers__slider .card::nth-child(2n) {
    padding-left: 75px;
  }
  .hero__slider__wrp .hero__description .top h1 {
    font-size: 15px !important;
    line-height: 30px !important;
    margin-left: 1em !important;
    width: 20% !important;
    margin-top: 4em;
  }
  .hero__slider__wrp .hero__description {
    margin-top: 180px;
  }
  header .right .dropdown.lang {
    margin-right: 0 !important;
  }
  .hero__slider__wrp header .right .mode {
    display: block;
    margin-right: 1em;
  }
}
</style>
