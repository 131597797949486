export default {
	data: () => ({
		langRu: {
		  title: 'О нас',
		  left: 'Источник знаний для вашего профессионального роста',
		  right: 'У каждого есть сила и талант для достижения великих целей. Мы поможем вам найти способ развиваться и достигать в профессии - по вашему желанию',
		  author: {
				title: 'Об авторе',
				btn: 'Посмотреть всю историю'
			},
			breadCrumbs: {
				home: 'Домой',
				about: 'О нас'
			}
		}
	})
}