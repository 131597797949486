export default {
	data: () => ({
		langEng: {
			form: {
				title: { text1: 'Tell us about your', color: ' goals ', text2: '' },
				desc: 'We will help you choose the perfect program for you.',
				consent: { text1: 'I agree with terms and ', link: 'conditions ', text2: 'of using personal data' },
				country: 'Сountry',
				name: 'Name and surname',
				phone: 'Phone',
				btn: 'Leave a request'
			}
		}
	})
}