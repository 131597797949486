const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    category: []
  },
  getters: {
   getAll: state =>  state.category,
   getSelectId(state){
    let id = null;
    state.category.forEach(cat => {
      if(cat.status){
        id = cat.id;
      }
    });
    return id;
   }
  },
  mutations: {
   setCat(state, payload) {
    state.category = payload;
   },
   selectCat(state, id) {
    state.category.forEach(e => {
      if(e.id != id) {
        e.status = false
      }
      else {
        e.status = !e.status
      }
    });
   },
  },
  actions: {
   async setCat({ commit, rootGetters }){
    commit('setCat', await getCategoryApi(rootGetters['languages/getLang']))
   },

  }
}


async function getCategoryApi(lang) {
  const response = await fetch(`${domain}/${lang}/courses/category/`);
  if(response.ok) {
    let data = await response.json();
    data.forEach(element => {
      element.status = false;
    });
    return data;
  }
  return null;
}