export const countriesFile = [{
  code: "AF",
  flag: "🇦🇫",
  name: "Afghanistan",
  phoneCode: "+93"
}, {
  code: "AX",
  flag: "🇦🇽",
  name: "Åland Islands",
  phoneCode: "+358"
}, {
  code: "AL",
  flag: "🇦🇱",
  name: "Albania",
  phoneCode: "+355"
}, {
  code: "DZ",
  flag: "🇩🇿",
  name: "Algeria",
  phoneCode: "+213"
}, {
  code: "AS",
  flag: "🇦🇸",
  name: "American Samoa",
  phoneCode: "+1 684"
}, {
  code: "AD",
  flag: "🇦🇩",
  name: "Andorra",
  phoneCode: "+376"
}, {
  code: "AO",
  flag: "🇦🇴",
  name: "Angola",
  phoneCode: "+244"
}, {
  code: "AI",
  flag: "🇦🇮",
  name: "Anguilla",
  phoneCode: "+1 264"
}, {
  code: "AQ",
  flag: "🇦🇶",
  name: "Antarctica",
  phoneCode: "+672"
}, {
  code: "AG",
  flag: "🇦🇬",
  name: "Antigua and Barbuda",
  phoneCode: "+1 268"
}, {
  code: "AR",
  flag: "🇦🇷",
  name: "Argentina",
  phoneCode: "+54"
}, {
  code: "AM",
  flag: "🇦🇲",
  name: "Armenia",
  phoneCode: "+374"
}, {
  code: "AW",
  flag: "🇦🇼",
  name: "Aruba",
  phoneCode: "+297"
}, {
  code: "AU",
  flag: "🇦🇺",
  name: "Australia",
  phoneCode: "+61"
}, {
  code: "AT",
  flag: "🇦🇹",
  name: "Austria",
  phoneCode: "+43"
}, {
  code: "AZ",
  flag: "🇦🇿",
  name: "Azerbaijan",
  phoneCode: "+994"
}, {
  code: "BS",
  flag: "🇧🇸",
  name: "Bahamas",
  phoneCode: "+1 242"
}, {
  code: "BH",
  flag: "🇧🇭",
  name: "Bahrain",
  phoneCode: "+973"
}, {
  code: "BD",
  flag: "🇧🇩",
  name: "Bangladesh",
  phoneCode: "+880"
}, {
  code: "BB",
  flag: "🇧🇧",
  name: "Barbados",
  phoneCode: "+1 246"
}, {
  code: "BY",
  flag: "🇧🇾",
  name: "Belarus",
  phoneCode: "+375"
}, {
  code: "BE",
  flag: "🇧🇪",
  name: "Belgium",
  phoneCode: "+32"
}, {
  code: "BZ",
  flag: "🇧🇿",
  name: "Belize",
  phoneCode: "+501"
}, {
  code: "BJ",
  flag: "🇧🇯",
  name: "Benin",
  phoneCode: "+229"
}, {
  code: "BM",
  flag: "🇧🇲",
  name: "Bermuda",
  phoneCode: "+1 441"
}, {
  code: "BT",
  flag: "🇧🇹",
  name: "Bhutan",
  phoneCode: "+975"
}, {
  code: "BO",
  flag: "🇧🇴",
  name: "Bolivia, Plurinational State of",
  phoneCode: "+591"
}, {
  code: "BQ",
  flag: "🇧🇶",
  name: "Bonaire, Sint Eustatius and Saba",
  phoneCode: "+599"
}, {
  code: "BA",
  flag: "🇧🇦",
  name: "Bosnia and Herzegovina",
  phoneCode: "+387"
}, {
  code: "BW",
  flag: "🇧🇼",
  name: "Botswana",
  phoneCode: "+267"
}, {
  code: "BV",
  flag: "🇧🇻",
  name: "Bouvet Island",
  phoneCode: "+47"
}, {
  code: "BR",
  flag: "🇧🇷",
  name: "Brazil",
  phoneCode: "+55"
}, {
  code: "IO",
  flag: "🇮🇴",
  name: "British Indian Ocean Territory",
  phoneCode: "+246"
}, {
  code: "BN",
  flag: "🇧🇳",
  name: "Brunei Darussalam",
  phoneCode: "+673"
}, {
  code: "BG",
  flag: "🇧🇬",
  name: "Bulgaria",
  phoneCode: "+359"
}, {
  code: "BF",
  flag: "🇧🇫",
  name: "Burkina Faso",
  phoneCode: "+226"
}, {
  code: "BI",
  flag: "🇧🇮",
  name: "Burundi",
  phoneCode: "+257"
}, {
  code: "KH",
  flag: "🇰🇭",
  name: "Cambodia",
  phoneCode: "+855"
}, {
  code: "CM",
  flag: "🇨🇲",
  name: "Cameroon",
  phoneCode: "+237"
}, {
  code: "CA",
  flag: "🇨🇦",
  name: "Canada",
  phoneCode: "+1"
}, {
  code: "CV",
  flag: "🇨🇻",
  name: "Cape Verde",
  phoneCode: "+238"
}, {
  code: "KY",
  flag: "🇰🇾",
  name: "Cayman Islands",
  phoneCode: "+1 345"
}, {
  code: "CF",
  flag: "🇨🇫",
  name: "Central African Republic",
  phoneCode: "+236"
}, {
  code: "TD",
  flag: "🇹🇩",
  name: "Chad",
  phoneCode: "+235"
}, {
  code: "CL",
  flag: "🇨🇱",
  name: "Chile",
  phoneCode: "+56"
}, {
  code: "CN",
  flag: "🇨🇳",
  name: "China",
  phoneCode: "+86"
}, {
  code: "CX",
  flag: "🇨🇽",
  name: "Christmas Island",
  phoneCode: "+61"
}, {
  code: "CC",
  flag: "🇨🇨",
  name: "Cocos (Keeling) Islands",
  phoneCode: "+61"
}, {
  code: "CO",
  flag: "🇨🇴",
  name: "Colombia",
  phoneCode: "+57"
}, {
  code: "KM",
  flag: "🇰🇲",
  name: "Comoros",
  phoneCode: "+269"
}, {
  code: "CG",
  flag: "🇨🇬",
  name: "Congo",
  phoneCode: "+242"
}, {
  code: "CD",
  flag: "🇨🇩",
  name: "Congo, the Democratic Republic of the",
  phoneCode: "+243"
}, {
  code: "CK",
  flag: "🇨🇰",
  name: "Cook Islands",
  phoneCode: "+682"
}, {
  code: "CR",
  flag: "🇨🇷",
  name: "Costa Rica",
  phoneCode: "+506"
}, {
  code: "CI",
  flag: "🇨🇮",
  name: "Côte d'Ivoire",
  phoneCode: "+225"
}, {
  code: "HR",
  flag: "🇭🇷",
  name: "Croatia",
  phoneCode: "+385"
}, {
  code: "CU",
  flag: "🇨🇺",
  name: "Cuba",
  phoneCode: "+53"
}, {
  code: "CW",
  flag: "🇨🇼",
  name: "Curaçao",
  phoneCode: "+599"
}, {
  code: "CY",
  flag: "🇨🇾",
  name: "Cyprus",
  phoneCode: "+357"
}, {
  code: "CZ",
  flag: "🇨🇿",
  name: "Czech Republic",
  phoneCode: "+420"
}, {
  code: "DK",
  flag: "🇩🇰",
  name: "Denmark",
  phoneCode: "+45"
}, {
  code: "DJ",
  flag: "🇩🇯",
  name: "Djibouti",
  phoneCode: "+253"
}, {
  code: "DM",
  flag: "🇩🇲",
  name: "Dominica",
  phoneCode: "+1 767"
}, {
  code: "DO",
  flag: "🇩🇴",
  name: "Dominican Republic",
  phoneCode: "+1 849"
}, {
  code: "EC",
  flag: "🇪🇨",
  name: "Ecuador",
  phoneCode: "+593"
}, {
  code: "EG",
  flag: "🇪🇬",
  name: "Egypt",
  phoneCode: "+20"
}, {
  code: "SV",
  flag: "🇸🇻",
  name: "El Salvador",
  phoneCode: "+503"
}, {
  code: "GQ",
  flag: "🇬🇶",
  name: "Equatorial Guinea",
  phoneCode: "+240"
}, {
  code: "ER",
  flag: "🇪🇷",
  name: "Eritrea",
  phoneCode: "+291"
}, {
  code: "EE",
  flag: "🇪🇪",
  name: "Estonia",
  phoneCode: "+372"
}, {
  code: "ET",
  flag: "🇪🇹",
  name: "Ethiopia",
  phoneCode: "+251"
}, {
  code: "FK",
  flag: "🇫🇰",
  name: "Falkland Islands (Malvinas)",
  phoneCode: "+500"
}, {
  code: "FO",
  flag: "🇫🇴",
  name: "Faroe Islands",
  phoneCode: "+298"
}, {
  code: "FJ",
  flag: "🇫🇯",
  name: "Fiji",
  phoneCode: "+679"
}, {
  code: "FI",
  flag: "🇫🇮",
  name: "Finland",
  phoneCode: "+358"
}, {
  code: "FR",
  flag: "🇫🇷",
  name: "France",
  phoneCode: "+33"
}, {
  code: "GF",
  flag: "🇬🇫",
  name: "French Guiana",
  phoneCode: "+594"
}, {
  code: "PF",
  flag: "🇵🇫",
  name: "French Polynesia",
  phoneCode: "+689"
}, {
  code: "TF",
  flag: "🇹🇫",
  name: "French Southern Territories",
  phoneCode: "+262"
}, {
  code: "GA",
  flag: "🇬🇦",
  name: "Gabon",
  phoneCode: "+241"
}, {
  code: "GM",
  flag: "🇬🇲",
  name: "Gambia",
  phoneCode: "+220"
}, {
  code: "GE",
  flag: "🇬🇪",
  name: "Georgia",
  phoneCode: "+995"
}, {
  code: "DE",
  flag: "🇩🇪",
  name: "Germany",
  phoneCode: "+49"
}, {
  code: "GH",
  flag: "🇬🇭",
  name: "Ghana",
  phoneCode: "+233"
}, {
  code: "GI",
  flag: "🇬🇮",
  name: "Gibraltar",
  phoneCode: "+350"
}, {
  code: "GR",
  flag: "🇬🇷",
  name: "Greece",
  phoneCode: "+30"
}, {
  code: "GL",
  flag: "🇬🇱",
  name: "Greenland",
  phoneCode: "+299"
}, {
  code: "GD",
  flag: "🇬🇩",
  name: "Grenada",
  phoneCode: "+1 473"
}, {
  code: "GP",
  flag: "🇬🇵",
  name: "Guadeloupe",
  phoneCode: "+590"
}, {
  code: "GU",
  flag: "🇬🇺",
  name: "Guam",
  phoneCode: "+1 671"
}, {
  code: "GT",
  flag: "🇬🇹",
  name: "Guatemala",
  phoneCode: "+502"
}, {
  code: "GG",
  flag: "🇬🇬",
  name: "Guernsey",
  phoneCode: "+44"
}, {
  code: "GN",
  flag: "🇬🇳",
  name: "Guinea",
  phoneCode: "+224"
}, {
  code: "GW",
  flag: "🇬🇼",
  name: "Guinea-Bissau",
  phoneCode: "+245"
}, {
  code: "GY",
  flag: "🇬🇾",
  name: "Guyana",
  phoneCode: "+592"
}, {
  code: "HT",
  flag: "🇭🇹",
  name: "Haiti",
  phoneCode: "+509"
}, {
  code: "HM",
  flag: "🇭🇲",
  name: "Heard Island and McDonald Islands",
  phoneCode: "+672"
}, {
  code: "VA",
  flag: "🇻🇦",
  name: "Holy See (Vatican City State)",
  phoneCode: "+379"
}, {
  code: "HN",
  flag: "🇭🇳",
  name: "Honduras",
  phoneCode: "+504"
}, {
  code: "HK",
  flag: "🇭🇰",
  name: "Hong Kong",
  phoneCode: "+852"
}, {
  code: "HU",
  flag: "🇭🇺",
  name: "Hungary",
  phoneCode: "+36"
}, {
  code: "IS",
  flag: "🇮🇸",
  name: "Iceland",
  phoneCode: "+354"
}, {
  code: "IN",
  flag: "🇮🇳",
  name: "India",
  phoneCode: "+91"
}, {
  code: "ID",
  flag: "🇮🇩",
  name: "Indonesia",
  phoneCode: "+62"
}, {
  code: "IR",
  flag: "🇮🇷",
  name: "Iran, Islamic Republic of",
  phoneCode: "+98"
}, {
  code: "IQ",
  flag: "🇮🇶",
  name: "Iraq",
  phoneCode: "+964"
}, {
  code: "IE",
  flag: "🇮🇪",
  name: "Ireland",
  phoneCode: "+353"
}, {
  code: "IM",
  flag: "🇮🇲",
  name: "Isle of Man",
  phoneCode: "+44"
}, {
  code: "IL",
  flag: "🇮🇱",
  name: "Israel",
  phoneCode: "+972"
}, {
  code: "IT",
  flag: "🇮🇹",
  name: "Italy",
  phoneCode: "+39"
}, {
  code: "JM",
  flag: "🇯🇲",
  name: "Jamaica",
  phoneCode: "+1 876, +1 658"
}, {
  code: "JP",
  flag: "🇯🇵",
  name: "Japan",
  phoneCode: "+81"
}, {
  code: "JE",
  flag: "🇯🇪",
  name: "Jersey",
  phoneCode: "+44"
}, {
  code: "JO",
  flag: "🇯🇴",
  name: "Jordan",
  phoneCode: "+962"
}, {
  code: "KZ",
  flag: "🇰🇿",
  name: "Kazakhstan",
  phoneCode: "+7"
}, {
  code: "KE",
  flag: "🇰🇪",
  name: "Kenya",
  phoneCode: "+254"
}, {
  code: "KI",
  flag: "🇰🇮",
  name: "Kiribati",
  phoneCode: "+686"
}, {
  code: "KP",
  flag: "🇰🇵",
  name: "Korea, Democratic People's Republic of",
  phoneCode: "+850"
}, {
  code: "KR",
  flag: "🇰🇷",
  name: "Korea, Republic of",
  phoneCode: "+82"
}, {
  code: "KW",
  flag: "🇰🇼",
  name: "Kuwait",
  phoneCode: "+965"
}, {
  code: "KG",
  flag: "🇰🇬",
  name: "Kyrgyzstan",
  phoneCode: "+996"
}, {
  code: "LA",
  flag: "🇱🇦",
  name: "Lao People's Democratic Republic",
  phoneCode: "+856"
}, {
  code: "LV",
  flag: "🇱🇻",
  name: "Latvia",
  phoneCode: "+371"
}, {
  code: "LB",
  flag: "🇱🇧",
  name: "Lebanon",
  phoneCode: "+961"
}, {
  code: "LS",
  flag: "🇱🇸",
  name: "Lesotho",
  phoneCode: "+266"
}, {
  code: "LR",
  flag: "🇱🇷",
  name: "Liberia",
  phoneCode: "+231"
}, {
  code: "LY",
  flag: "🇱🇾",
  name: "Libya",
  phoneCode: "+218"
}, {
  code: "LI",
  flag: "🇱🇮",
  name: "Liechtenstein",
  phoneCode: "+423"
}, {
  code: "LT",
  flag: "🇱🇹",
  name: "Lithuania",
  phoneCode: "+370"
}, {
  code: "LU",
  flag: "🇱🇺",
  name: "Luxembourg",
  phoneCode: "+352"
}, {
  code: "MO",
  flag: "🇲🇴",
  name: "Macao",
  phoneCode: "+853"
}, {
  code: "MK",
  flag: "🇲🇰",
  name: "Macedonia, the Former Yugoslav Republic of",
  phoneCode: "+389"
}, {
  code: "MG",
  flag: "🇲🇬",
  name: "Madagascar",
  phoneCode: "+261"
}, {
  code: "MW",
  flag: "🇲🇼",
  name: "Malawi",
  phoneCode: "+265"
}, {
  code: "MY",
  flag: "🇲🇾",
  name: "Malaysia",
  phoneCode: "+60"
}, {
  code: "MV",
  flag: "🇲🇻",
  name: "Maldives",
  phoneCode: "+960"
}, {
  code: "ML",
  flag: "🇲🇱",
  name: "Mali",
  phoneCode: "+223"
}, {
  code: "MT",
  flag: "🇲🇹",
  name: "Malta",
  phoneCode: "+356"
}, {
  code: "MH",
  flag: "🇲🇭",
  name: "Marshall Islands",
  phoneCode: "+692"
}, {
  code: "MQ",
  flag: "🇲🇶",
  name: "Martinique",
  phoneCode: "+596"
}, {
  code: "MR",
  flag: "🇲🇷",
  name: "Mauritania",
  phoneCode: "+222"
}, {
  code: "MU",
  flag: "🇲🇺",
  name: "Mauritius",
  phoneCode: "+230"
}, {
  code: "YT",
  flag: "🇾🇹",
  name: "Mayotte",
  phoneCode: "+262"
}, {
  code: "MX",
  flag: "🇲🇽",
  name: "Mexico",
  phoneCode: "+52"
}, {
  code: "FM",
  flag: "🇫🇲",
  name: "Micronesia, Federated States of",
  phoneCode: "+691"
}, {
  code: "MD",
  flag: "🇲🇩",
  name: "Moldova, Republic of",
  phoneCode: "+373"
}, {
  code: "MC",
  flag: "🇲🇨",
  name: "Monaco",
  phoneCode: "+377"
}, {
  code: "MN",
  flag: "🇲🇳",
  name: "Mongolia",
  phoneCode: "+976"
}, {
  code: "ME",
  flag: "🇲🇪",
  name: "Montenegro",
  phoneCode: "+382"
}, {
  code: "MS",
  flag: "🇲🇸",
  name: "Montserrat",
  phoneCode: "+1 664"
}, {
  code: "MA",
  flag: "🇲🇦",
  name: "Morocco",
  phoneCode: "+212"
}, {
  code: "MZ",
  flag: "🇲🇿",
  name: "Mozambique",
  phoneCode: "+258"
}, {
  code: "MM",
  flag: "🇲🇲",
  name: "Myanmar",
  phoneCode: "+95"
}, {
  code: "NA",
  flag: "🇳🇦",
  name: "Namibia",
  phoneCode: "+264"
}, {
  code: "NR",
  flag: "🇳🇷",
  name: "Nauru",
  phoneCode: "+674"
}, {
  code: "NP",
  flag: "🇳🇵",
  name: "Nepal",
  phoneCode: "+977"
}, {
  code: "NL",
  flag: "🇳🇱",
  name: "Netherlands",
  phoneCode: "+31"
}, {
  code: "NC",
  flag: "🇳🇨",
  name: "New Caledonia",
  phoneCode: "+687"
}, {
  code: "NZ",
  flag: "🇳🇿",
  name: "New Zealand",
  phoneCode: "+64"
}, {
  code: "NI",
  flag: "🇳🇮",
  name: "Nicaragua",
  phoneCode: "+505"
}, {
  code: "NE",
  flag: "🇳🇪",
  name: "Niger",
  phoneCode: "+227"
}, {
  code: "NG",
  flag: "🇳🇬",
  name: "Nigeria",
  phoneCode: "+234"
}, {
  code: "NU",
  flag: "🇳🇺",
  name: "Niue",
  phoneCode: "+683"
}, {
  code: "NF",
  flag: "🇳🇫",
  name: "Norfolk Island",
  phoneCode: "+672"
}, {
  code: "MP",
  flag: "🇲🇵",
  name: "Northern Mariana Islands",
  phoneCode: "+1 670"
}, {
  code: "NO",
  flag: "🇳🇴",
  name: "Norway",
  phoneCode: "+47"
}, {
  code: "OM",
  flag: "🇴🇲",
  name: "Oman",
  phoneCode: "+968"
}, {
  code: "PK",
  flag: "🇵🇰",
  name: "Pakistan",
  phoneCode: "+92"
}, {
  code: "PW",
  flag: "🇵🇼",
  name: "Palau",
  phoneCode: "+680"
}, {
  code: "PS",
  flag: "🇵🇸",
  name: "Palestine, State of",
  phoneCode: "+970"
}, {
  code: "PA",
  flag: "🇵🇦",
  name: "Panama",
  phoneCode: "+507"
}, {
  code: "PG",
  flag: "🇵🇬",
  name: "Papua New Guinea",
  phoneCode: "+675"
}, {
  code: "PY",
  flag: "🇵🇾",
  name: "Paraguay",
  phoneCode: "+595"
}, {
  code: "PE",
  flag: "🇵🇪",
  name: "Peru",
  phoneCode: "+51"
}, {
  code: "PH",
  flag: "🇵🇭",
  name: "Philippines",
  phoneCode: "+63"
}, {
  code: "PN",
  flag: "🇵🇳",
  name: "Pitcairn",
  phoneCode: "+64"
}, {
  code: "PL",
  flag: "🇵🇱",
  name: "Poland",
  phoneCode: "+48"
}, {
  code: "PT",
  flag: "🇵🇹",
  name: "Portugal",
  phoneCode: "+351"
}, {
  code: "PR",
  flag: "🇵🇷",
  name: "Puerto Rico",
  phoneCode: "+1 787, +1 939"
}, {
  code: "QA",
  flag: "🇶🇦",
  name: "Qatar",
  phoneCode: "+974"
}, {
  code: "RE",
  flag: "🇷🇪",
  name: "Réunion",
  phoneCode: "+262"
}, {
  code: "RO",
  flag: "🇷🇴",
  name: "Romania",
  phoneCode: "+40"
}, {
  code: "RU",
  flag: "🇷🇺",
  name: "Russian Federation",
  phoneCode: "+7"
}, {
  code: "RW",
  flag: "🇷🇼",
  name: "Rwanda",
  phoneCode: "+250"
}, {
  code: "BL",
  flag: "🇧🇱",
  name: "Saint Barthélemy",
  phoneCode: "+590"
}, {
  code: "SH",
  flag: "🇸🇭",
  name: "Saint Helena, Ascension and Tristan da Cunha",
  phoneCode: "+290, +247"
}, {
  code: "KN",
  flag: "🇰🇳",
  name: "Saint Kitts and Nevis",
  phoneCode: "+1 869"
}, {
  code: "LC",
  flag: "🇱🇨",
  name: "Saint Lucia",
  phoneCode: "+1 758"
}, {
  code: "MF",
  flag: "🇲🇫",
  name: "Saint Martin (French part)",
  phoneCode: "+590"
}, {
  code: "PM",
  flag: "🇵🇲",
  name: "Saint Pierre and Miquelon",
  phoneCode: "+508"
}, {
  code: "VC",
  flag: "🇻🇨",
  name: "Saint Vincent and the Grenadines",
  phoneCode: "+1 784"
}, {
  code: "WS",
  flag: "🇼🇸",
  name: "Samoa",
  phoneCode: "+685"
}, {
  code: "SM",
  flag: "🇸🇲",
  name: "San Marino",
  phoneCode: "+378"
}, {
  code: "ST",
  flag: "🇸🇹",
  name: "Sao Tome and Principe",
  phoneCode: "+239"
}, {
  code: "SA",
  flag: "🇸🇦",
  name: "Saudi Arabia",
  phoneCode: "+966"
}, {
  code: "SN",
  flag: "🇸🇳",
  name: "Senegal",
  phoneCode: "+221"
}, {
  code: "RS",
  flag: "🇷🇸",
  name: "Serbia",
  phoneCode: "+381"
}, {
  code: "SC",
  flag: "🇸🇨",
  name: "Seychelles",
  phoneCode: "+248"
}, {
  code: "SL",
  flag: "🇸🇱",
  name: "Sierra Leone",
  phoneCode: "+232"
}, {
  code: "SG",
  flag: "🇸🇬",
  name: "Singapore",
  phoneCode: "+65"
}, {
  code: "SX",
  flag: "🇸🇽",
  name: "Sint Maarten (Dutch part)",
  phoneCode: "+1 721"
}, {
  code: "SK",
  flag: "🇸🇰",
  name: "Slovakia",
  phoneCode: "+421"
}, {
  code: "SI",
  flag: "🇸🇮",
  name: "Slovenia",
  phoneCode: "+386"
}, {
  code: "SB",
  flag: "🇸🇧",
  name: "Solomon Islands",
  phoneCode: "+677"
}, {
  code: "SO",
  flag: "🇸🇴",
  name: "Somalia",
  phoneCode: "+252"
}, {
  code: "ZA",
  flag: "🇿🇦",
  name: "South Africa",
  phoneCode: "+27"
}, {
  code: "GS",
  flag: "🇬🇸",
  name: "South Georgia and the South Sandwich Islands",
  phoneCode: "+500"
}, {
  code: "SS",
  flag: "🇸🇸",
  name: "South Sudan",
  phoneCode: "+211"
}, {
  code: "ES",
  flag: "🇪🇸",
  name: "Spain",
  phoneCode: "+34"
}, {
  code: "LK",
  flag: "🇱🇰",
  name: "Sri Lanka",
  phoneCode: "+94"
}, {
  code: "SD",
  flag: "🇸🇩",
  name: "Sudan",
  phoneCode: "+249"
}, {
  code: "SR",
  flag: "🇸🇷",
  name: "Suriname",
  phoneCode: "+597"
}, {
  code: "SJ",
  flag: "🇸🇯",
  name: "Svalbard and Jan Mayen",
  phoneCode: "+47"
}, {
  code: "SZ",
  flag: "🇸🇿",
  name: "Swaziland",
  phoneCode: "+268"
}, {
  code: "SE",
  flag: "🇸🇪",
  name: "Sweden",
  phoneCode: "+46"
}, {
  code: "CH",
  flag: "🇨🇭",
  name: "Switzerland",
  phoneCode: "+41"
}, {
  code: "SY",
  flag: "🇸🇾",
  name: "Syrian Arab Republic",
  phoneCode: "+963"
}, {
  code: "TW",
  flag: "🇹🇼",
  name: "Taiwan, Province of China",
  phoneCode: "+886"
}, {
  code: "TJ",
  flag: "🇹🇯",
  name: "Tajikistan",
  phoneCode: "+992"
}, {
  code: "TZ",
  flag: "🇹🇿",
  name: "Tanzania, United Republic of",
  phoneCode: "+255"
}, {
  code: "TH",
  flag: "🇹🇭",
  name: "Thailand",
  phoneCode: "+66"
}, {
  code: "TL",
  flag: "🇹🇱",
  name: "Timor-Leste",
  phoneCode: "+670"
}, {
  code: "TG",
  flag: "🇹🇬",
  name: "Togo",
  phoneCode: "+228"
}, {
  code: "TK",
  flag: "🇹🇰",
  name: "Tokelau",
  phoneCode: "+690"
}, {
  code: "TO",
  flag: "🇹🇴",
  name: "Tonga",
  phoneCode: "+676"
}, {
  code: "TT",
  flag: "🇹🇹",
  name: "Trinidad and Tobago",
  phoneCode: "+1 868"
}, {
  code: "TN",
  flag: "🇹🇳",
  name: "Tunisia",
  phoneCode: "+216"
}, {
  code: "TR",
  flag: "🇹🇷",
  name: "Turkey",
  phoneCode: "+90"
}, {
  code: "TM",
  flag: "🇹🇲",
  name: "Turkmenistan",
  phoneCode: "+993"
}, {
  code: "TC",
  flag: "🇹🇨",
  name: "Turks and Caicos Islands",
  phoneCode: "+1 649"
}, {
  code: "TV",
  flag: "🇹🇻",
  name: "Tuvalu",
  phoneCode: "+688"
}, {
  code: "UG",
  flag: "🇺🇬",
  name: "Uganda",
  phoneCode: "+256"
}, {
  code: "UA",
  flag: "🇺🇦",
  name: "Ukraine",
  phoneCode: "+380"
}, {
  code: "AE",
  flag: "🇦🇪",
  name: "United Arab Emirates",
  phoneCode: "+971"
}, {
  code: "GB",
  flag: "🇬🇧",
  name: "United Kingdom",
  phoneCode: "+44"
}, {
  code: "US",
  flag: "🇺🇸",
  name: "United States",
  phoneCode: "+1"
}, {
  code: "UM",
  flag: "🇺🇲",
  name: "United States Minor Outlying Islands",
  phoneCode: "+1"
}, {
  code: "UY",
  flag: "🇺🇾",
  name: "Uruguay",
  phoneCode: "+598"
}, {
  code: "UZ",
  flag: "🇺🇿",
  name: "Uzbekistan",
  phoneCode: "+998"
}, {
  code: "VU",
  flag: "🇻🇺",
  name: "Vanuatu",
  phoneCode: "+678"
}, {
  code: "VE",
  flag: "🇻🇪",
  name: "Venezuela, Bolivarian Republic of",
  phoneCode: "+58"
}, {
  code: "VN",
  flag: "🇻🇳",
  name: "Viet Nam",
  phoneCode: "+84"
}, {
  code: "VG",
  flag: "🇻🇬",
  name: "Virgin Islands, British",
  phoneCode: "+1 284"
}, {
  code: "VI",
  flag: "🇻🇮",
  name: "Virgin Islands, U.S.",
  phoneCode: "+1 340"
}, {
  code: "WF",
  flag: "🇼🇫",
  name: "Wallis and Futuna",
  phoneCode: "+681"
}, {
  code: "EH",
  flag: "🇪🇭",
  name: "Western Sahara",
  phoneCode: "+212"
}, {
  code: "YE",
  flag: "🇾🇪",
  name: "Yemen",
  phoneCode: "+967"
}, {
  code: "ZM",
  flag: "🇿🇲",
  name: "Zambia",
  phoneCode: "+260"
}, {
  code: "ZW",
  flag: "🇿🇼",
  name: "Zimbabwe",
  phoneCode: "+263"
}]

// export const countriesFile = {
//   "AF": "Afghanistan",
//   "AX": "Åland Islands",
//   "AL": "Albania",
//   "DZ": "Algeria",
//   "AS": "American Samoa",
//   "AD": "Andorra",
//   "AO": "Angola",
//   "AI": "Anguilla",
//   "AQ": "Antarctica",
//   "AG": "Antigua and Barbuda",
//   "AR": "Argentina",
//   "AM": "Armenia",
//   "AW": "Aruba",
//   "AU": "Australia",
//   "AT": "Austria",
//   "AZ": "Azerbaijan",
//   "BS": "Bahamas",
//   "BH": "Bahrain",
//   "BD": "Bangladesh",
//   "BB": "Barbados",
//   "BY": "Belarus",
//   "BE": "Belgium",
//   "BZ": "Belize",
//   "BJ": "Benin",
//   "BM": "Bermuda",
//   "BT": "Bhutan",
//   "BO": "Bolivia, Plurinational State of",
//   "BQ": "Bonaire, Sint Eustatius and Saba",
//   "BA": "Bosnia and Herzegovina",
//   "BW": "Botswana",
//   "BV": "Bouvet Island",
//   "BR": "Brazil",
//   "IO": "British Indian Ocean Territory",
//   "BN": "Brunei Darussalam",
//   "BG": "Bulgaria",
//   "BF": "Burkina Faso",
//   "BI": "Burundi",
//   "KH": "Cambodia",
//   "CM": "Cameroon",
//   "CA": "Canada",
//   "CV": "Cape Verde",
//   "KY": "Cayman Islands",
//   "CF": "Central African Republic",
//   "TD": "Chad",
//   "CL": "Chile",
//   "CN": "China",
//   "CX": "Christmas Island",
//   "CC": "Cocos (Keeling) Islands",
//   "CO": "Colombia",
//   "KM": "Comoros",
//   "CG": "Congo",
//   "CD": "Congo, the Democratic Republic of the",
//   "CK": "Cook Islands",
//   "CR": "Costa Rica",
//   "CI": "Côte d'Ivoire",
//   "HR": "Croatia",
//   "CU": "Cuba",
//   "CW": "Curaçao",
//   "CY": "Cyprus",
//   "CZ": "Czech Republic",
//   "DK": "Denmark",
//   "DJ": "Djibouti",
//   "DM": "Dominica",
//   "DO": "Dominican Republic",
//   "EC": "Ecuador",
//   "EG": "Egypt",
//   "SV": "El Salvador",
//   "GQ": "Equatorial Guinea",
//   "ER": "Eritrea",
//   "EE": "Estonia",
//   "ET": "Ethiopia",
//   "FK": "Falkland Islands (Malvinas)",
//   "FO": "Faroe Islands",
//   "FJ": "Fiji",
//   "FI": "Finland",
//   "FR": "France",
//   "GF": "French Guiana",
//   "PF": "French Polynesia",
//   "TF": "French Southern Territories",
//   "GA": "Gabon",
//   "GM": "Gambia",
//   "GE": "Georgia",
//   "DE": "Germany",
//   "GH": "Ghana",
//   "GI": "Gibraltar",
//   "GR": "Greece",
//   "GL": "Greenland",
//   "GD": "Grenada",
//   "GP": "Guadeloupe",
//   "GU": "Guam",
//   "GT": "Guatemala",
//   "GG": "Guernsey",
//   "GN": "Guinea",
//   "GW": "Guinea-Bissau",
//   "GY": "Guyana",
//   "HT": "Haiti",
//   "HM": "Heard Island and McDonald Islands",
//   "VA": "Holy See (Vatican City State)",
//   "HN": "Honduras",
//   "HK": "Hong Kong",
//   "HU": "Hungary",
//   "IS": "Iceland",
//   "IN": "India",
//   "ID": "Indonesia",
//   "IR": "Iran, Islamic Republic of",
//   "IQ": "Iraq",
//   "IE": "Ireland",
//   "IM": "Isle of Man",
//   "IL": "Israel",
//   "IT": "Italy",
//   "JM": "Jamaica",
//   "JP": "Japan",
//   "JE": "Jersey",
//   "JO": "Jordan",
//   "KZ": "Kazakhstan",
//   "KE": "Kenya",
//   "KI": "Kiribati",
//   "KP": "Korea, Democratic People's Republic of",
//   "KR": "Korea, Republic of",
//   "KW": "Kuwait",
//   "KG": "Kyrgyzstan",
//   "LA": "Lao People's Democratic Republic",
//   "LV": "Latvia",
//   "LB": "Lebanon",
//   "LS": "Lesotho",
//   "LR": "Liberia",
//   "LY": "Libya",
//   "LI": "Liechtenstein",
//   "LT": "Lithuania",
//   "LU": "Luxembourg",
//   "MO": "Macao",
//   "MK": "Macedonia, the Former Yugoslav Republic of",
//   "MG": "Madagascar",
//   "MW": "Malawi",
//   "MY": "Malaysia",
//   "MV": "Maldives",
//   "ML": "Mali",
//   "MT": "Malta",
//   "MH": "Marshall Islands",
//   "MQ": "Martinique",
//   "MR": "Mauritania",
//   "MU": "Mauritius",
//   "YT": "Mayotte",
//   "MX": "Mexico",
//   "FM": "Micronesia, Federated States of",
//   "MD": "Moldova, Republic of",
//   "MC": "Monaco",
//   "MN": "Mongolia",
//   "ME": "Montenegro",
//   "MS": "Montserrat",
//   "MA": "Morocco",
//   "MZ": "Mozambique",
//   "MM": "Myanmar",
//   "NA": "Namibia",
//   "NR": "Nauru",
//   "NP": "Nepal",
//   "NL": "Netherlands",
//   "NC": "New Caledonia",
//   "NZ": "New Zealand",
//   "NI": "Nicaragua",
//   "NE": "Niger",
//   "NG": "Nigeria",
//   "NU": "Niue",
//   "NF": "Norfolk Island",
//   "MP": "Northern Mariana Islands",
//   "NO": "Norway",
//   "OM": "Oman",
//   "PK": "Pakistan",
//   "PW": "Palau",
//   "PS": "Palestine, State of",
//   "PA": "Panama",
//   "PG": "Papua New Guinea",
//   "PY": "Paraguay",
//   "PE": "Peru",
//   "PH": "Philippines",
//   "PN": "Pitcairn",
//   "PL": "Poland",
//   "PT": "Portugal",
//   "PR": "Puerto Rico",
//   "QA": "Qatar",
//   "RE": "Réunion",
//   "RO": "Romania",
//   "RU": "Russian Federation",
//   "RW": "Rwanda",
//   "BL": "Saint Barthélemy",
//   "SH": "Saint Helena, Ascension and Tristan da Cunha",
//   "KN": "Saint Kitts and Nevis",
//   "LC": "Saint Lucia",
//   "MF": "Saint Martin (French part)",
//   "PM": "Saint Pierre and Miquelon",
//   "VC": "Saint Vincent and the Grenadines",
//   "WS": "Samoa",
//   "SM": "San Marino",
//   "ST": "Sao Tome and Principe",
//   "SA": "Saudi Arabia",
//   "SN": "Senegal",
//   "RS": "Serbia",
//   "SC": "Seychelles",
//   "SL": "Sierra Leone",
//   "SG": "Singapore",
//   "SX": "Sint Maarten (Dutch part)",
//   "SK": "Slovakia",
//   "SI": "Slovenia",
//   "SB": "Solomon Islands",
//   "SO": "Somalia",
//   "ZA": "South Africa",
//   "GS": "South Georgia and the South Sandwich Islands",
//   "SS": "South Sudan",
//   "ES": "Spain",
//   "LK": "Sri Lanka",
//   "SD": "Sudan",
//   "SR": "Suriname",
//   "SJ": "Svalbard and Jan Mayen",
//   "SZ": "Swaziland",
//   "SE": "Sweden",
//   "CH": "Switzerland",
//   "SY": "Syrian Arab Republic",
//   "TW": "Taiwan, Province of China",
//   "TJ": "Tajikistan",
//   "TZ": "Tanzania, United Republic of",
//   "TH": "Thailand",
//   "TL": "Timor-Leste",
//   "TG": "Togo",
//   "TK": "Tokelau",
//   "TO": "Tonga",
//   "TT": "Trinidad and Tobago",
//   "TN": "Tunisia",
//   "TR": "Turkey",
//   "TM": "Turkmenistan",
//   "TC": "Turks and Caicos Islands",
//   "TV": "Tuvalu",
//   "UG": "Uganda",
//   "UA": "Ukraine",
//   "AE": "United Arab Emirates",
//   "GB": "United Kingdom",
//   "US": "United States",
//   "UM": "United States Minor Outlying Islands",
//   "UY": "Uruguay",
//   "UZ": "Uzbekistan",
//   "VU": "Vanuatu",
//   "VE": "Venezuela, Bolivarian Republic of",
//   "VN": "Viet Nam",
//   "VG": "Virgin Islands, British",
//   "VI": "Virgin Islands, U.S.",
//   "WF": "Wallis and Futuna",
//   "EH": "Western Sahara",
//   "YE": "Yemen",
//   "ZM": "Zambia",
//   "ZW": "Zimbabwe"
//   }
  
  
  