<template>
  <div class="objective__cnt">
    <div class="txt">
      <h2>{{ $t('contactForm.form.title.text1') }}<b>{{ $t('contactForm.form.title.color') }}</b> {{ $t('contactForm.form.title.text2') }}</h2>
      <!-- <p>Și noi vă vom ajuta să vă alegeți programul potrivit</p> -->
      <div class="wrapp_icon">
        <p>{{ $t('contactForm.form.desc') }}</p>
<!--        <img class="icon" :src="!darkMode ? '/img/form-fig-dark.svg' : '/img/form-fig.svg'" alt="icon">-->
      </div>
    </div>
    <form action="post" v-if="!send" @submit.prevent="sendData">
      <!-- <input v-model="country" type="text" :placeholder="selectLang.form.country" class="inp"> -->
      <v-select v-model="country" label="countryName" :options="countries" :placeholder="$t('contactForm.form.country')" class="inp" :class="{'warning': !regexp.country.status }"></v-select>
      <input v-model="name" type="text" :placeholder="$t('contactForm.form.name')" class="inp" :class="{'warning': !regexp.name.status }">
      <input v-model="phone" type="text" :placeholder="$t('contactForm.form.phone')" class="inp" :class="{'warning': !regexp.phone.status }">
      <label>
        <input name="consent" type="checkbox" v-model="consent" required :class="{'warning': !regexp.consent.status }">
        <span :class="{'warning-text': !regexp.consent.status }">
          {{ $t('contactForm.form.consent.text1') }}
          <a :href="`/policy_md/policy_${langStorage}.pdf`"> {{ $t('contactForm.form.consent.link') }} </a>         
          {{ $t('contactForm.form.consent.text2') }}
        </span>
      </label>
      <button  ref="btnSend">{{ $t('contactForm.form.btn') }}</button>
    </form>
    <h3 v-else class="sendText" style="color: #515076; max-width:600px">{{$t('contactForm.form.message')}}</h3>
  </div>
</template>

<script>
import {countriesFile} from "@/lang/Countries/";
import LangRu from '@/lang/components/contactForm/ru.js' 
import LangRo from '@/lang/components/contactForm/ro.js'
import LangEng from '@/lang/components/contactForm/eng.js'
import LangEs from '@/lang/components/contactForm/es.js'


import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;


export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  data:()=>({
    country: '',
    name: '',
    phone: '',
    consent: false,
    send: false,
    textTranslate: {
      message: {
        ro: 'Aplicația a fost trimisă cu succes, iar managerul nostru vă va contacta în scurt timp',
        ru: 'Заявка успешно отправлена, в скором времени с вами свяжется наш менеджер',
        en: 'The application has been successfully sent, and our manager will contact you shortly',
        es: 'La solicitud se ha enviado correctamente, nuestro manager te contactará pronto'
      }
    },
    countries: [],
      regexp: {
        country: {
          status: ' '
        },
        name: {
          status: ' '
        },
        phone: {
          status: ' '
        },
        consent: {
          status: ' '
        },
      },
  }),
  beforeMount(){
    countriesFile.forEach(country => {
      this.countries.push({
        countryCode: country.code,
        countryName: country.name
      });
    });
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      darkMode: 'darkMode'
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    }
  },
  methods: {
    googleEventCallBack(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'Callback_Complete'
      });
    },
    async sendData(){
      this.$refs.btnSend.disabled = true;
      this.$refs.btnSend.textContent = "Loading";
      if(this.validator()){
        try {
          await fetch(`${domain}/get-feedback/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'name': this.name,
              'contact_data': this.phone,
              'country': this.country.countryName
            })
          });
        }
        catch(er){
          console.error(er);
        }
        this.$refs.btnSend.disabled = false;
        this.$refs.btnSend.style.background = 'green';
        this.send = true;
        this.name = '';
        this.phone = '';
        this.consent = false;
        this.googleEventCallBack();
      }
      else {
        this.$refs.btnSend.disabled = false;
        this.$refs.btnSend.textContent = this.selectLang.form.btn;
      }
      
    },
    validator(){
      this.regexp.country.status = this.country != "";
      this.regexp.name.status = this.name.length >= 4;
      this.regexp.phone.status = this.phone.length != 0;
      this.regexp.consent.status = this.consent;

      for (let key in this.regexp) {
        if(this.regexp[key].status == false){
          return false;
        }
      }

      return true;
    }
  },
}
</script>

<style lang="scss">
  .objective__cnt label  {
    position: relative;
    span:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 24px;
      height: 24px;
      min-width: 24px;
      background: #FFF;
      border: 1px solid #6B6B78;
      border-radius: 4px;
      transition: .2s ease;
    }
    input:checked+span:before {
      background: #EB236B;
      border: 1px solid #6B6B78;
      background-repeat: no-repeat;
      background-size: 100%;
    }

  }
  .objective__cnt button {
    cursor: pointer;
  }

  .objective__cnt .fig {
    display: none;
    bottom: -55px !important;
    left: 35% !important;
  }

  .objective__cnt .txt {
    position: relative;

    .icon {
      position: absolute;
      bottom: -180px;
      right: 0;
    }
  }

  .objective__cnt {
    .v-select {
      .vs__clear {
        padding: 0 !important;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer !important;

      }
      .vs__dropdown-toggle {
        height: 96px;
        border: none;
        @media(max-width: 430px){
          height: 64px;
        }
      }
    }
    .vs__search::-webkit-input-placeholder  {
      font-weight: 500;
    }
  }

  .objective__cnt .warning {
    border: 1px solid rgba(255, 0, 0, 0.493) !important;
    background-color: rgba(255, 0, 0, 0.034) !important;
    &:after {
      border: 1px solid rgba(255, 0, 0, 0.493);
      background-color: rgba(255, 0, 0, 0.034);
    }
  }
  .objective__cnt .warning-text {
    color: red !important;
  }
  

  @media(min-width: 430px) {
    .objective__cnt .txt h2 {
      margin: 0px 0 20px !important
    }
  }
  @media(max-width: 430px) {
    .objective__cnt  {
      .wrapp_icon {
        display: flex;
        .icon {
          position: unset;
          width: 40%;
        }
      }
    }

  }


</style>