<template>
  <div class="header__wrp">
    <header>
      <!-- <div class="menu" :style="{ background: !darkMode ? 'url(/img/mobile-menu-dark.svg)' : 'url(/img/mobile-menu.svg)'}" @click="setMobileMenu">

      </div> -->
      <BurgerMenu @click="setMobileMenu" :active="mobileMenu"/>
        <router-link 
          :to="{name: 'Home', params: { lang: langStorage } }"
          class="logo"
        >
        </router-link>
        <div class="links">
          <router-link 
            :to="{name: 'category', params: {lang : langStorage}}"
          >
            {{ $t('header.courses') }}
          </router-link>
          <router-link 
            v-if="isLogin && checkCountry && checkUserType"
            :to="{name: 'shopCategory', params: {lang : langStorage}}">
            {{ $t('header.shop') }}
          </router-link>
          <a class="shop-lock"
            style="cursor:pointer; color: rgba(81, 80, 118, 0.7)"
            :data-title="(isLogin && !checkCountry && !checkUserType)?  $t('header.shopClose') : $t('header.shopHidden')"
            v-else
          >
            {{ $t('header.shop') }}
            <svg class="icon-lock" width="15px" height="15px" fill="rgba(81, 80, 118, 0.7)">
              <use xlink:href="/icons.svg#lock"></use>
            </svg>
          </a>
          <!-- <router-link :to="{name: 'Specialists', params: {lang : langStorage}}">{{ $t('header.specialists') }}</router-link> -->
          <router-link :to="{name: 'About', params: {lang : langStorage}}">{{ $t('header.about') }}</router-link>
          <router-link :to="{name: 'blog', params: {lang : langStorage}}">{{ $t('header.blog') }}</router-link>
          <router-link :to="{name: 'promotions', params: {lang : langStorage}}">{{ $t('header.promotions') }}</router-link>
      </div>
      <div class="right">
        <div class="wrapp-countCard" @click="goToCheckout">
          <iconBasket class="icon-card"/>
          <div v-show="totalCnt" class="conuter" v-if="isLogin">{{ totalCnt }}</div>

          <a class="shop-lock tooltip"
            v-if="$store.getters['user/isLogin'] == false"
            :data-title="(isLogin && !checkCountry && !checkUserType)?  $t('header.shopClose') : $t('header.shopHidden')"
          >
          </a>
          
        </div>
        <div @click="onModeChenge()" class="mode">
          <!-- <img src="img/night-mode.svg" alt=""> -->
        </div>
        <SelectorLang class="selector_lang" :color="headerColor"/>
        <div class="login__box">
          <template v-if="!isLogin">
            <router-link class="login" tag="span" to="/login">{{ $t('header.login') }}</router-link>
            <router-link class="register" tag="span" to="/registration">{{ $t('header.registration') }}</router-link>
          </template>
          <router-link v-else class="login aush" tag="span" :to="{name: 'courses'}">{{ user.userName }}</router-link>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import LangRu from '@/lang/components/header/ru' 
import LangRo from '@/lang/components/header/ro' 
import LangEng from '@/lang/components/header/eng'
import LangEs from '@/lang/components/header/es' 
import SelectorLang from '@/components/SelectorLang'
import iconBasket from '@/components/icons/basket.vue';
import BurgerMenu from '@/components/BurgerMenu.vue';
import { getCurrency, getProductPrice } from '@/utils/helpers';


import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    SelectorLang,
    iconBasket,
    BurgerMenu
  },
  mixins: [LangRu, LangRo, LangEng, LangEs],
  data:()=>({
    lang: ''
  }),
  mounted(){
    this.scrollHeader();
    this.lang = this.langStorage;
  },
  watch:{
    lang(){
      this.setLang(this.lang);
    }
  },
  methods: {
    scrollHeader(){
      const body = document.body
      const scrollUp = "scroll-up"
      const scrollDown = "scroll-down"
      let lastScroll = 0;
      window.addEventListener("scroll", () =>{
        const currentScroll = window.pageYOffset;
        if(currentScroll <= 0){
          body.classList.remove(scrollUp);
          return
        }
        if(currentScroll > lastScroll && !body.classList.contains(scrollDown)){
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown)
        }
        else if(currentScroll < lastScroll && body.classList.contains(scrollDown)){
          body.classList.remove(scrollDown)
          body.classList.add(scrollUp)
        }
        lastScroll = currentScroll
      })
    },
    onModeChenge(){
      this.setDarkMode(!this.darkMode);
    },
   ...mapActions(['setMobileMenu', 'setDarkMode']),
   ...mapActions('languages', ['setLang']),
   ...mapActions('googleEvents', ['sendBeginCheckoutEvent']),
   goToCheckout(){
    if(this.$store.getters["user/isLogin"] && this.$route.name != 'сheckout'){
      this.sendBeginCheckoutEvent({ 
        items: this.getProductStore.map(p => ({ 
          title: p.title,
          price: p[getProductPrice(this.user?.profile?.profile?.city)],
          quantity: p.cnt
        })),
        currency: getCurrency(this.user?.profile?.profile?.city)
      })
      this.$router.push({name: 'сheckout'})
    }
   }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      isLogin: 'user/isLogin',
      user: 'user/user',
      darkMode: 'darkMode',
      totalCnt: 'checkout/totalCnt',
      checkCountry: 'user/checkCountry',
      checkUserType: 'user/checkUserType',
      getProductStore: 'checkout/products',
      mobileMenu: 'mobileMenu',
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    headerColor(){
      return this.darkMode ? '#2B2A29' : 'white';
    },
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  width: 20px;
  height: 16px; 
  position: absolute !important;
  top: 0;
  &:hover::after {
    top: 200% !important;
  }
}
.scroll-down .header__wrp{
  transform: translate3d(0, -100%, 0)
}
.scroll-up .header__wrp{
  transform: none;
}
.header__wrp{
  position: fixed;
  top: 0;
  z-index: 100;
  transition: transform .3s ease;
}
  .header__wrp {
    padding: 0 20px;

  }
  .login.aush {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .wrapp-countCard {
    cursor: pointer;
    position: relative;
    margin-right: 25px;
    @media (max-width:430px) {
      margin-right: 15px;
    }
    .icon-card {
      width: 20px;
    }
    .conuter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      font-weight: 800;
      color: white;
      left: 12px;
      font-size: 10px;
      background-color: #DC3E6C;
      border-radius: 50% ;
      width: 14px;
      height: 14px;
    }
  }
  .shop-lock {
    display: block;;
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      background-color: #EFEEFD;
      width: 145px;
      padding: 8px;
      border-radius: 12px;
      font-size: 14px;
      line-height: normal;
      color: black;
      top: 80%;
      left: 0;
      z-index: 1;
    }
  }
  @media(max-width: 1024px) and (min-width: 430px){
    header .menu {
      display: block;
      height: 24px;
      width: 24px;
    }
  }
</style>