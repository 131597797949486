import Vue from 'vue'
import Vuex from 'vuex'
import user from "./user"
import specialists from "./specialists"
import courses from "./courses"
import shopCards from "./shopCards"
import checkout from "./checkout/"
import languages from "./languages"
import faq from "./faq"
import category from "./category"
import shopCourses from "./shopCourses"
import personalSlider from "./personalSlider"
import utmQuery from "./utmQuery"
import googleEvents from "./googleEvents"

import createPersistedState from "vuex-persistedstate";

const dataState = createPersistedState({
  paths: ['user'],
  storage: {
    getItem: key => sessionStorage.getItem(key),
    setItem: (key, value) => sessionStorage.setItem(key, value),
    removeItem: key => sessionStorage.removeItem(key),
  },
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobileMenu: false,
    specialistMobileMenu: false,
    darkMode: false
  },
  getters: {
    specialistMobileMenu(state){
      return state.specialistMobileMenu
    },
    mobileMenu(state){
      return state.mobileMenu;
    },
    darkMode(state){
      return state.darkMode;
    }
  },
  mutations: {
    setSpecialistMobileMenu(state){
      state.specialistMobileMenu = !state.specialistMobileMenu
    },
    setMobileMenu(state){
      state.mobileMenu = !state.mobileMenu;
    },
    setDarkMode(state, mode){
      state.darkMode = mode;
      window.localStorage.setItem('darkMode', mode);
    },
    changeMobileMenu(state, payload){
      state.mobileMenu = payload;
    },
    changeSpecialistMobileMenu(state,payload){
      state.specialistMobileMenu = payload
    }
  },
  actions: {
    setMobileMenu({ commit }){
      commit('setMobileMenu');
    },
    setDarkMode({ commit }, payload){
      commit("setDarkMode", payload);
    },
    setSpecialistMobileMenu({ commit }){
      commit('setSpecialistMobileMenu')
    }
  },
  modules: {
    user,
    specialists,
    courses,
    shopCards,
    checkout,
    languages,
    faq,
    category,
    personalSlider,
    shopCourses,
    utmQuery,
    googleEvents
  },
  plugins: [dataState],
  strict: process.env.NODE_ENV !== 'production'
})
