export default {
	data: () => ({
		langRu: {
			header: {
				courses: 'Курсы',
				shop: 'Магазин',
				specialists: 'Специалисты',
				about: 'О нас',
				blog: 'Блог',
				promotions: 'Акции',
				login: 'Войти',
				registration: 'Регистрация',
				shopHidden: 'только для зарегистрированных пользователей',
				shopClose: 'недоступно в вашем регионе',
				dashboard: 'Личный кабинет'
			}
		}
	})
}