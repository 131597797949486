const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
   sldier: []
  },
  getters: {
   getAll: state => state.sldier
  },
  mutations: {
    setSlider: (state, payload) => state.sldier = payload
  },
  actions: {
    async setSlider({commit, rootGetters}){
      commit('setSlider',await getApiSlider(rootGetters['languages/getLang']));
    }
  }
}

async function getApiSlider(lang){
  const response = await fetch(`${domain}/${lang}/get-review-slider/`);
  if(response.ok) {
    const data = await response.json();
    return data; 
  }

  return null;
}