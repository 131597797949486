export default {
	data: () => ({
		langRo: {
			form: {
				title: { text1: 'Povestește-ne despre ', color: ' scopurile ', text2: ' tale ' },
				desc: 'Vă vom ajuta să alegeți programul potrivit pentru Dumneavoastră.',
				consent: { text1: 'Sunt de acord cu ', link: 'Termenii și condițiile', text2: ' de prelucrare a informației cu caracter personal' },
				country: 'Ţară',
				name: 'Numele',
				phone: 'Telefon',
				btn: 'Lasă o cerere'
			}
		}
	})
}