export default {
	data: () => ({
		langRu: {
			title: 'Авторизация',
			name: 'Логин',
			password: 'Пароль',
			confirmPas: 'Повторите пароль',
			newpass: 'Новый пароль',
			login: 'Войти',
			registration: 'Нет аккаунта ?',
			recoveryPass: 'Восстановление',
			btnRecover: 'Восстановить',
			save: 'Сохранить',
			generate: 'Cгенерировать',
      savePass: "Cохранить логин и пароль"
		}
	})
}