export default {
	data: () => ({
		langEng: {
			btnPath: {
				home: 'Home',
				category: 'Courses',
			},
			courseMaterials: 'Course materials',
			courseTitle: {
				text: 'Course',
				color: ' content'
			},
			courseDesc: 'Online lectures, practical assignments, workshops and master classes are waiting for you',
			chapters: 'theoretical',
			modules: 'practical videos'
		}
	})
}