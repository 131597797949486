export default {
	data: () => ({
		langRo: {
			title: 'Despre noi',
			left: 'Sursa de cunoștințe pentru creșterea ta profesională',
			right: 'Fiecare are puterea și talentul pentru a atinge obiective grozave. Vă ajutăm să găsiți o cale de dezvoltare și să vă realizați prin profesie - așa cum doriți',
			author: {
				title: 'Despre autor',
				btn: 'Vezi toată istoria'
			},
			breadCrumbs: {
				home: 'Acasă',
				about: 'Despre noi'
			}
		}
	})
}








