export default {
	data: () => ({
		langRo: {
			titlePage: 'Cursuri',
			home: 'Acasa',
			results: 'rezultate',
			reload: 'Mai încarcă',
			from: 'de la',
			advancedFiltering: 'Filtrare avansată'
		}
	})
}








