export default {
	data: () => ({
		langEng: {
			titlePage: 'Courses',
			home: 'Home',
			results: 'results',
			reload: 'Load more',
			from: 'from',
			advancedFiltering: 'Advanced filtering'
		}
	})
}








