export default {
	set(state, { cart }){
		state.products = cart;
		//state.token = token;
		//state.loaded = true;
	},
	add(state, { id, userId, payload }){
		state.products.push({ id, cnt: 1, 'userId': userId, ...payload });
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	remove(state, { ind }){
		state.products.splice(ind, 1);
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	setCnt(state, { ind, cnt }){
		state.products[ind].cnt = cnt;
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	startProccess(state, id){
		state.proccessId.push(id);
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	endProccess(state, rid){
		state.proccessId = state.proccessId.filter(id => id !== rid);
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	reset(state){
		state.products = [];
    localStorage.setItem('products', JSON.stringify(state.products));
	},
	filterProducts(state, ids) {
		if (!ids) return
		state.products = state.products.filter(product => ids.includes(product.id))
		localStorage.setItem('products', JSON.stringify(state.products))
	},
  setProductsFromLocalStorage(state, payload) {
    state.products = payload;
  },
}