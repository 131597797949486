<template>
<div>
  <HeaderWrp/>
  
  <div class="my_container">
    <Breadcrumbs>
      <router-link to="/">{{ $t('article.home') }}</router-link>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
        <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
      </svg>
      <router-link class="curr" to="/category">{{ $t('category.titlePage') }}</router-link>
    </Breadcrumbs>
  </div>
  <div class="mobile__filters" :class="{ 'active' : popupFilterMobile }" v-scroll-lock="popupFilterMobile">
    <div class="top">
      <span>{{ $t('category.advancedFiltering') }}</span>
      <div class="close" @click="popupFilterMobile = !popupFilterMobile"></div>
    </div>
    <div class="col">
      <strong>Brand</strong>
      <div class="checkboxes">
        <label v-for="brand, idx in brands" :key="idx">
          <input type="checkbox" :value="brand.id" v-model="popupFilterData.brands">
          <span>{{brand.title}}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="my_container">
    <div class="category__courses__cnt">
      <div class="filters__row">
        <div class="filters__popup" :class="{ 'active' : popupFilter }">
          <div class="col">
            <strong>Brand</strong>
            <div class="checkboxes">
              <label v-for="brand, idx in brands" :key="idx">
                <input type="checkbox" :value="brand.id" v-model="popupFilterData.brands">
                <span>{{brand.title}}</span>
              </label>
            </div>
          </div>  
        </div>
        <div class="left">
          <div class="basic__filters">              
            <div 
              v-for="(cat, ind) in categoriesStore"
              class="btn" 
              :class="{'active': cat.status}"
              :key="cat.id" 
              @click="selectCat(cat.id)"
            >
              {{ cat.name }}
            </div> 
          </div>
          <div class="adv__btn" :class="{ 'active' : popupFilter }" @click="openFilter">
            <span>{{$t('category.advancedFiltering') }}</span>
          <div class="icon"></div>
          </div>
        </div>
        <div class="results__num">
          {{ filterBrands.length }} {{ $t('category.results') }}
        </div>
      </div>
      <div class="category__courses__row">
        

        <CardTags v-for="(card, index) in products"
         :key="index"
         :image="card.image"
         :title="card.title"
         :discount="card.discount"
         :linkVideo="card.linkVideo"
         :tags="card.tags"
         :price="card.price"
         :time="card.time"
         :is-new="card.is_new"
         @select="$router.push({name: 'course', params: {lang: $i18n.locale, id: card.id}, query: {...$route.query} })"
        />

      </div>
      <div v-show="filterBrands.length != products.length" @click="pageCountCard += pageCountCard" class="load__more">
        <span>{{ $t('category.reload')}}</span>      
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.99593 5H9.99593V10H8.99593V6.493C7.6106 7.07272 6.46865 8.1141 5.76404 9.44027C5.05942 10.7664 4.83559 12.2956 5.13056 13.7681C5.42553 15.2406 6.22111 16.5656 7.38217 17.518C8.54323 18.4705 9.99819 18.9916 11.4999 18.993C13.0945 18.9946 14.6341 18.4099 15.8257 17.3502C17.0173 16.2906 17.7778 14.8299 17.9625 13.246C18.1473 11.6621 17.7433 10.0656 16.8275 8.76015C15.9118 7.45471 14.5481 6.53145 12.9959 6.166V5.142C14.7152 5.49183 16.2574 6.43335 17.3542 7.80274C18.4511 9.17213 19.0331 10.8827 18.9991 12.6369C18.965 14.3911 18.3171 16.0778 17.168 17.4036C16.0188 18.7294 14.4413 19.6104 12.7097 19.8933C10.9782 20.1762 9.20224 19.843 7.69092 18.9518C6.17961 18.0606 5.02856 16.6678 4.43805 15.0157C3.84754 13.3635 3.85493 11.5567 4.45895 9.9094C5.06296 8.26215 6.22537 6.87879 7.74393 6H4.99593V5Z" fill="#930684"/>
        </svg>
      </div>
    </div>
  </div>
  <FooterApp/>
</div>
</template>

<script>
import LangRu from '@/lang/Category/ru' 
import LangRo from '@/lang/Category/ro' 
import LangEng from '@/lang/Category/eng'
import LangEs from '@/lang/Category/es'

import Breadcrumbs from "@/components/Breadcrumbs";
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp";
import CardTags from "@/components/cards/CardTags"
import VueSlickCarousel from 'vue-slick-carousel'
import { mapGetters, mapActions } from "vuex"


export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    HeaderWrp,
    Breadcrumbs,
    CardTags,
    VueSlickCarousel,
    FooterApp
  },
  async created(){
    await this.initCategories();
    await this.setBrands();
    this.useHashFilter();
  },
  data(){
    return {
      popupFilter: false,
      popupFilterMobile: false,
      pageCountCard: 8,
      popupFilterData: {
        brands: []
      },
      categories:[]
    }
  },
  metaInfo() {
    return this.langStorage == 'ru' ? {
      title: 'Курсы по косметологии и эстетической медицине',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Курсы по косметологии и эстетической медицине - Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Профессиональные курсы по косметологии и эстетической медицине в академии красоты Lica Doctor. По окончанию курса выдается международный диплом'
        }
      ]
    } :
    this.langStorage == 'ro' ? {
      title: 'Cursuri de cosmetologie și medicină estetică',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Cursuri de cosmetologie și medicină estetică - Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Cursuri profesionale de cosmetologie și medicină estetică la academia de frumusețe Lica Doctor. La sfârșitul cursului, se eliberează o diplomă internațională'
        }
      ]
    } :
    this.langStorage == 'es' ? {
      title: 'Cursos de cosmetología y medicina estética',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Cursos de cosmetología y medicina estética - Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Cursos profesionales de cosmetología y medicina estética en la academia de belleza Lica Doctor. Al finalizar el curso se otorga un diploma internacional.'
        }
      ]
    } :
    {
      title: 'Courses in cosmetology and aesthetic medicine',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Cosmetologist Classes - Aesthetic medicine Courses - Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Professional courses in cosmetology and aesthetic medicine at the Lica Doctor beauty academy. At the end of the course, an international diploma is issued'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      initializeCourses: 'shopCourses/initializeCourses',
      initCategories: 'category/setCat',
      setBrands: 'shopCards/setBrands'
    }),
    selectCat(id) {
      this.$store.commit('category/selectCat', id);
      switch (id) {
        case 3:
          location.hash = 'cosmetology'
          break;
        case 4:
          location.hash = 'medicine'
          break;
        case 8:
          location.hash = 'massage'
          break;
      }
    },
    openFilter(){
      if(window.innerWidth >= 600 ){
        this.popupFilter = !this.popupFilter;
      }
      else {
        this.popupFilterMobile = !this.popupFilterMobile;
      }
    },
    useHashFilter() {
      const hash = this.$route.hash;
      if(hash == '#cosmetology') {
        this.selectCat(3);
      }
      else if(hash == '#medicine') {
        this.selectCat(4);
      }
      else if(hash == '#massage') {
        this.selectCat(8);
      }
    }
  },
  computed: {
    ...mapGetters({
      shopCourses: 'shopCourses/getAll',
      langStorage: 'languages/getLang',
      categoriesStore: 'category/getAll',
      getSelectedCat: 'category/getSelectId',
      brands: 'shopCards/getBrands',
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    filterCategories(){
      if(this.getSelectedCat){
        return this.shopCourses.filter(el => el.category == this.getSelectedCat);
      }
      else {
        return this.shopCourses;
      }
    },
    filterBrands(){
      if(this.popupFilterData.brands.length) {
       return this.filterCategories.filter(el => { 
        for(const value of el.tags) {
          if(this.popupFilterData.brands.includes(value.id)){
            return true;
          }
        }
        return false;
       });
      }
      else {
        return this.filterCategories;
      }
    },
    products() {
     return this.filterBrands.slice(0, this.pageCountCard);
    },
  },
  watch:{
    async langStorage(){
      await this.initCategories();
      await this.initializeCourses();
      await this.setBrands();
    }
  }
}
</script>

<style lang="scss">
  .category__courses__cnt .filters__popup {
    width: 100% !important;
    max-width: 275px;
    right: 50% !important;
  }
.category__courses__cnt .filters__popup .col .checkboxes label span { white-space: nowrap;}
  .category__courses__row {
    .card .title {
      height: 35px;
      overflow-y: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

    @media (max-width: 1024px) and (min-width: 600px){
    .results__num {
      display: none;
    }
    .category__courses__row .card {
      width: calc(35% - 30px) !important;
      margin: 0 5px 40px !important;
    }
    .category__courses__cnt  {
      margin: 0 auto 25px !important;
    }
    .category__courses__row {
      width: calc(99% + 24px) !important;
    }
  }


</style>