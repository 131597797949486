import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en'
import ru from '@/locales/ru'
import ro from '@/locales/ro'
import fr from '@/locales/fr'
import es from '@/locales/es'

const messages = {
  en,
  ru,
  ro,
  fr,
  es,
}

Vue.use(VueI18n)
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
let lng = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'ro';

if(locale == 'ru' || locale == 'ro' || locale == 'en' || locale == 'fr' || locale == 'es'){
  lng = locale;
}

export default new VueI18n({
  locale: lng,
  fallbackLocale: ['ru', 'ro'],
  messages: messages
})