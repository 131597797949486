<template>
  <div class="about__author" :class="{'no-bg': !background}">
    <div class="cnt">
      <div class="left">
        <h3 class="inner-p" v-html="title"></h3>
        <div class="name inner-p" v-html="name"></div>
        <span class="desc inner-p" v-html="desc"></span>
        <router-link to="author" class="view">
           {{ $t('aboutAuthor.author.btn') }}
          <img src="/img/success-forward.svg" alt="">
        </router-link>
      </div>
      <div class="slider__wrp">
<!--        <div class="fig">-->
<!--          &lt;!&ndash; <img src="img/author-fig.svg" alt=""> &ndash;&gt;-->
<!--        </div>-->
        <div class="shadow"></div>
        <div class="wrapper__arr">
          <div class="arr prev" @click="$refs.carouselAuthor.prev()">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1587 32.5803C19.8317 32.2618 19.8317 31.7364 20.1587 31.4179L42.7153 9.44064C43.0304 9.13364 43.5328 9.13364 43.8479 9.44064C44.1748 9.75915 44.1748 10.2845 43.8479 10.603L21.2912 32.5803C20.9761 32.8873 20.4738 32.8873 20.1587 32.5803Z" fill="#000001"/>
              <path d="M43.8479 54.5594C44.1748 54.2409 44.1748 53.7155 43.8479 53.397L21.2846 31.4133C20.9695 31.1063 20.4672 31.1063 20.1521 31.4133C19.8252 31.7318 19.8252 32.2572 20.1521 32.5757L42.7154 54.5594C43.0305 54.8664 43.5328 54.8664 43.8479 54.5594Z" fill="#000001"/>
            </svg>
          </div>
          <div class="arr next" @click="$refs.carouselAuthor.next()">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M43.8413 32.5803C44.1683 32.2618 44.1683 31.7364 43.8413 31.4179L21.2847 9.44064C20.9696 9.13364 20.4672 9.13364 20.1521 9.44064C19.8252 9.75915 19.8252 10.2845 20.1521 10.603L42.7088 32.5803C43.0239 32.8873 43.5262 32.8873 43.8413 32.5803Z" fill="#000001"/>
              <path d="M20.1521 54.5594C19.8252 54.2409 19.8252 53.7155 20.1521 53.397L42.7154 31.4133C43.0305 31.1063 43.5328 31.1063 43.8479 31.4133C44.1748 31.7318 44.1748 32.2572 43.8479 32.5757L21.2846 54.5594C20.9695 54.8664 20.4672 54.8664 20.1521 54.5594Z" fill="#000001"/>
            </svg>
          </div>
        </div>
        <div class="about__author__slider">
          <VueSlickCarousel  ref="carouselAuthor" :arrows="false">
            <div v-for="item in images" :key="item.id" class="img__cont" >  <!--:style="{backgroundImage: `url(${item.image})`}"-->
              <img :src="item.image" alt="">
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LangRu from '@/lang/components/aboutAuthor/ru' 
import LangRo from '@/lang/components/aboutAuthor/ro' 
import LangEng from '@/lang/components/aboutAuthor/eng'
import LangEs from '@/lang/components/aboutAuthor/es'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex';
export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  props:{
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    images:{
      type: Array,
      default: () => []
    },
    background: {
      type: Boolean,
      default: true
    }

  },
  components: {
    VueSlickCarousel
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    }
  }
}
</script>


<style lang="scss">
  .about__author .cnt .slider__wrp .shadow {
    width: calc(100% - 320px);
    height: 100%;
  }


  .about__author {
    .desc {
      max-height: 360px;
      overflow: hidden;
    }
  }

  .about__author__slider {
    .img__cont {

      height: 536px;
      
      @media(max-width: 1680px){
        height: 600px;
      }
      @media(max-width: 1440px){
        max-height: 480px;
      }
      @media(max-width: 1200px){
        max-height: 380px;
      }
      @media(max-width: 430px){
        max-height: 345px;
      }
      @media(max-width: 375px){
        max-height: 300px;
      }
      @media(max-width: 320px){
        max-height: 250px;
      }
    }
  }
  .about__author .view {
    white-space: nowrap;
  }

  @media (max-width: 1800px) {
    .about__author .cnt .slider__wrp .shadow {
      width: calc(100% - 220px);
      height: 100%;
    }
  }
  @media (max-width: 430px) {
    .about__author .cnt .slider__wrp .shadow {
      width: calc(100% - 50px);
      height: 92%;
    }
    .about__author .cnt .left {
      margin-top: 60px;
    }
    .wrapper__arr {
      position: relative;
      top: 392px;
    }
  }
</style>