<template>
  <div class="page__breadcrumbs">
    <div class="options" @click="$router.go(-1)">
      <img src="/img/success-forward.svg" alt="arrow-img" class="arrow-img"/>
      <span>{{$t('breadCrumbs.back')}}</span>
    </div>
    <div class="links">
      <slot></slot> 
    </div>
    <slot name="title"></slot>
    <slot name="content"></slot>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .page__breadcrumbs {
    position: relative;
    justify-content: center;
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 30px;
      cursor: pointer;
      span {
        font-weight: 500;
        font-size: 14px;
        color: #6B6B78;
        margin-left: 5px;
      }
      &:hover {
        .arrow-img {
          transform: rotate(0deg);
        }
      }
      @media(max-width: 430px) {
        display: none;
      }
    }
    .arrow-img {
      transform: rotate(180deg);
      transition: 0.5s;
      width: 25%;
     
    }
  }
</style>