export default {
	data: () => ({
		langRo: {
			title: 'Login',
			name: 'Login',
			password: 'Parolă',
			confirmPas: 'Confirmă parola',
			newpass: 'Parolă nouă',
			login: 'Intră în cabinet',
			registration: 'Înregistrare',
			recoveryPass: 'Recuperare',
			btnRecover: 'Recuperare',
			save: 'Salvează',
			generate: 'Generează',
      savePass: "Salvați datele de conectare și parola"
		}
	})
}








