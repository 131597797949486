<script>
import DOMPurify from 'dompurify';
import BaseModal from '@/components/BaseModal.vue';
export default {
    components: {
        BaseModal
    },
    
    data () {
        return {
            modalActive: true,
        }
    },

    methods: {
        openCoursesPage () {
            this.modalActive = false
            this.$store.dispatch('courses/markUserHasBeenNotifiedAboutCoursesUpdates')
            this.$router.push('/dashboard/courses')
        },

        hasHourPassed() {
            const lastChecked = localStorage.getItem('courses_updates_checked')
            if (!lastChecked) {
                return false
            }

            // If you want to change time interval, change this variable
            const hourInMilliseconds = 1 * 60 * 60 * 1000

            const currentTime = new Date().getTime()
            const timeDifference = currentTime - lastChecked
            return timeDifference >= hourInMilliseconds
        },


        async checkCoursesUpdates () {
            if (!localStorage || !this.needToFetchUpdates()) return
            await this.$store.dispatch("courses/fetchCoursesUpdates")
            localStorage.setItem('courses_updates_checked', new Date().getTime())
            this.modalActive = true
        },
        
        closeModal () {
            localStorage.setItem('courses_updates_checked', new Date().getTime())
            this.modalActive = false
        },

        needToFetchUpdates() {
            return !!(!localStorage.getItem('courses_updates_checked') || this.hasHourPassed())
        },
    },

    computed: {
        getCoursesRepresentation () {
            const coursesRepresentation = Array.from(new Set(this.$store.getters['courses/getCoursesUpdates']?.map(update => update.course?.name))).join(', ')
            return DOMPurify.sanitize(coursesRepresentation)
        },
        getCondition () {
            return this.$store.getters['courses/getCoursesUpdates'].length > 0
        }
    },

    async mounted () {
        await this.checkCoursesUpdates()
    },

    watch: {
        '$route': async function() {
            await this.checkCoursesUpdates()
        }
    }
}
</script>
<template>
    <BaseModal v-if="getCondition" :modal-active="modalActive" @close-modal="closeModal">
        <template #title>
            <h2>{{ $t('coursesUpdatesModal.title') }}</h2>
        </template>

        <template>
            <div class="new-course-modal__wrapper">
                <div class="new-course-modal__content">
                    <p v-html="$t('coursesUpdatesModal.content', { courses: getCoursesRepresentation })"></p>
                </div>
            </div>
        </template>

        <template #footer>
            <button class="modal-btn" type="button" @click="openCoursesPage">{{ $t('coursesUpdatesModal.button') }}</button>
        </template>
    </BaseModal>
</template>

<style lang="scss">
.modal-btn {
    padding: 24px 24px;
    background: #0066FF;
    border-radius: 96px;
    font-weight: 500;
    font-size: 20px;
    color: #FFF;
    border: none;
    cursor: pointer;
}
</style>