<template>
  <div id="app">
    <MobileMenu/>
    <SpecialistShopMobileMenu />
    <router-view/>
    <PoliticaModal/>
    <BtnScrollTop/>
    <CoursesUpdatesModal v-if="userIsLoggedIn"/>
  </div>
</template>
<script>
import MobileMenu from "@/components/MobileMenu";
import BtnScrollTop from "@/components/BtnScrollTop.vue";
import PoliticaModal from "@/components/PoliticaModal.vue"
import { mapGetters } from 'vuex';
import SpecialistShopMobileMenu from './components/SpecialistShopMobileMenu.vue';
import CoursesUpdatesModal from "@/components/CoursesUpdatesModal.vue";

export default {
  name: 'Home',
  components: {
    MobileMenu,
    PoliticaModal,
    SpecialistShopMobileMenu,
    BtnScrollTop,
    CoursesUpdatesModal
},
  metaInfo(){
      if(this.lang == 'ru'){
        return{
           script: [
            { type: 'text/javascript', src: '//code.jivo.ru/widget/HYZtySQJBB',  async: true, class: "jivo_script"}
          ],
        }
      }
      else if(this.lang == 'ro'){
        return {
          script: [
            { type: 'text/javascript', src: '//code.jivo.ru/widget/8A59aJYktv',  async: true, class: "jivo_script"}
        ],
        }
      }
      else if(this.lang == 'en'){
        return {
          script: [
            { type: 'text/javascript', src: '//code.jivo.ru/widget/dEzjn0NHFQ',  async: true, class: "jivo_script"}
        ],  
        }
      }
      else{
        return{
          script: [
            { type: 'text/javascript', src: '//code.jivo.ru/widget/Y3c2DfMi38',  async: true, class: "jivo_script"}
          ],
        }
      }
  },
  mounted(){
    this.$store.commit('utmQuery/utm', this.$route.query);
    if(this.darkMode === true) {
      document.querySelector("body").classList.add("night");
    }
  },
  watch:{
    '$route': function(){
      let body = document.body
      if(body.classList.contains('scroll-down')){
      body.classList.remove('scroll-down')
    }
    },
    '$i18n.locale': function(){
      window.location.reload();
      // let src = this.$metaInfo.script[0].src
      // let head = document.getElementsByTagName('head')[0];
      // let script = document.createElement('script');
      // script.src = src
      // script.async = true
      // script.type = 'text/javascript'
      // head.appendChild(script)
    },
    darkMode(){
      document.querySelector("body").classList.toggle("night");
    }
  },
  computed: {
    ...mapGetters({
      darkMode: 'darkMode',
      lang: 'languages/getLang',
      userIsLoggedIn: 'user/isLogin'
    }),
  },
}
</script>

<style lang="scss">
@import "@/sass/main.sass";

  a, div {
    outline: none;
  }
  img {
    pointer-events: none;
  }
  .my_container {
    margin-top: 50px;
    padding: 0 5.375em;
  }
  .inner-p p{
    margin: 0 !important;
  }

  @media (max-width: 1024px) and (min-width: 430px) {
    .hero__slider__wrp header .links,.hero__slider__wrp header .right .login__box {
      display: none;
    }
    .logo {
      display: flex;
      justify-content: center;
    }
    .hero__slider__wrp header .menu {
      display: block;
    }
    .advantages__row .card .txt strong {
      font-size: 20px;
      line-height: unset;
      margin-bottom: 5px;
    }
    .advantages__row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 100px;
      .card {
        width: calc(50% - (24px * 3) / 4);
        margin-bottom: 50px;
        .txt span {
          font-size: 15px;
          font-weight: 600;
          line-height: 25px
        }
      }
    }
    .help__wrp {
      margin: 100px auto;
    }
    .about__author .cnt {
      .left {
        padding-left: 0;
      }
      h3 {
        font-size: 40px;
      }
    }
    .about__author .cnt .left span {
      font-size: 15px;
      line-height: 23px;
    }
    .about__author .cnt .slider__wrp .shadow,.about__author .cnt .slider__wrp .fig,.about__author .cnt .slider__wrp .arr {
      display: none;
    }
    .about__author .cnt .slider__wrp .about__author__slider .slick-list,.about__author .cnt .slider__wrp .about__author__slider .img__cont {
      border-radius: unset;
    }
    .about__author .cnt .slider__wrp .about__author__slider {
      width: 100%;
      margin: 0;
    }
    .about__author .cnt .slider__wrp .about__author__slider .img__cont img {
      width: 300px;
      height: 300px;
      border-radius: 12px;
    }
    .about__author .cnt .slider__wrp .about__author__slider .img__cont {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .faq__wrp .col .faq__card {
      margin-bottom:0;
      padding-bottom: 0;
      padding: 10px;
    }
    .faq__wrp .col .btm div.answer {
      width: 100%;
    }
    .faq__wrp .col .btm {
      width: 100%;
      flex-direction: column;
    }
    .faq__wrp .col .req__btn {
      margin-top: 20px;
    }
  }
  @media (max-width: 430px) {
    .my_container {
      padding: 0;
    }
    .faq__wrp .txt .fig {
      background-size: contain;
    }
  }
</style>

