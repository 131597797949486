export default {
	data: () => ({
		langRu: {
			titlePage: 'Курсы',
			home: 'Домой',
			results: 'результат',
			reload: 'Загрузи больше',
			from: 'от',
			advancedFiltering: 'Расширенный поиск'
		}
	})
}