const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    items: { results: [] },
    specialistItems: { results: [] },
    categories: [],
    brands: [],
    shopOptions: {
      productFilter: {
        pageCountCard: 6,
        categories: [],
        brands: [],
           
        dropdown: {
          brands: false,
          price: false,
          categories: false   
        },
        mobileVersion: false
      },
    }
  },
  getters: {
    getAll(state){
      return state.items;
    },
    getSpecialistItems(state){
      return state.specialistItems;
    },
    getOne(state){
      return (id) => {
        return state.items.results.find(item => id == item.id);
      }
    },
    getOneSpecialistPr(state){
      return (id) => {
        return state.specialistItems.results.find(item => id == item.id);
      }
    },
    getCategories(state){
      return state.categories;
    },
    getBrands(state){
      return state.brands;
    },
    getShopOptions(state){
      return state.shopOptions;
    }
  },
  mutations: {
    setItems(state, payload){
      state.items = payload;
    },
    setSpecialistItems(state, payload){
      state.specialistItems = payload;
    },
    setCategories(state, payload){
      state.categories = payload;
    },
    setBrands(state, payload){
      state.brands = payload;
    },
    changeShopOptions(state, payload){
      state.shopOptions.productFilter = payload;
    }
  },
  actions: {
    async setItems({commit, rootGetters}, code){
      let lang = rootGetters['languages/getLang'];
      let token = rootGetters['user/user'].token;
      if(token) {
        let products = await getProducts(lang, token, code);
        if(products != null) {
          products.results.sort(function(a,b){
            return b.id - a.id
          })
          commit('setItems', products);
        }
        else {
          this.$store.dispatch("user/user", { name: 'token', value: '' });
          this.$store.commit("checkout/reset");
          logOut(token);
        }
      }
    },
    async setSpecialistProducts({commit, rootGetters}, {code, filter = false}){
      let lang = rootGetters['languages/getLang'];
      commit('setSpecialistItems', await getSpecialistProducts(lang, code, filter));
    },
    async setCategories({commit, rootGetters}){
      let lang = rootGetters['languages/getLang'];
      commit('setCategories', await getCategories(lang));
    },
    async setBrands({commit, rootGetters}){
      let lang = rootGetters['languages/getLang'];
      commit('setBrands', await getBrands(lang));
    },
    changeShopOptions({commit}, payload){
      commit('changeShopOptions', payload);
    }
  }
}

async function getCategories(lang, id){
  try {
    const response = await fetch(`${domain}/${lang}/shop/category/${id ? id : ''}`);
    return await response.json();
  }
  catch(er){
    console.error(er);
  }
}
async function getProducts(lang,token, code){
  try {
    let arr = []
    const response = await fetch(`${domain}/${lang}/shop/product/?active=true&is_active_for_${code}=true&page_size=10000`,{
      headers: {
        Authorization: `Token ${token}`
      }
    });
    if(!response.ok){
      throw response;
    }
    const data = await response.json();

    arr.push(data.results);
    async function getPage(url){
      let req = await fetch(url);
      let data = await req.json();
      arr.push(data.results)

      if(data.next){
        await getPage(data.next);
      }
      else return;
    }
    if(data.next){
      await getPage(data.next);
    }
    return { results: arr.flat()};
  }
  catch(er){
    console.error(er);
    return null;
  }
}
async function getBrands(lang, id){
  try {
    const response = await fetch(`${domain}/${lang}/shop/brands/${id ? id : ''}`);
    const data = await response.json();
    return data;
  }
  catch(er){
    console.error(er);
  }
}
async function getSpecialistProducts(lang, code, isLica = false){
  try {
    let arr = [];
    let url = `${domain}/${lang}/shop/product/?active=true&is_active_for_client_${code}=true`;
    if(!isLica) {
      url += '&catherine_store=False';
    }
    const request = await fetch(url + '&page_size=10000');
    if(!request.ok){
      throw request;
    }
    const data = await request.json();
    // TODO 31ng9xa
    data.results.sort(function(a,b){return b.id-a.id})
    arr.push(data.results);

    async function getPage(url){
      let req = await fetch(url);
      let data = await req.json();
      arr.push(data.results)
      if(data.next){
        await getPage(data.next);
      }
      else return;
    }

    if(data.next){
      await getPage(data.next);
    }
    let wrapp = arr.flat().filter(el => { return el.client_price != null || el.client_price_ro != null || el.client_price_ru != null});
    wrapp = wrapp.map(el => {
      return {
        ...el,
        discount: el.client_discount,
        discount_ro: el.client_discount_ro,
        discount_ru : el.client_discount_ru,

        in_stock_for_md: el.client_in_stock_for_md,
        in_stock_for_ro: el.client_in_stock_for_ro,
        in_stock_for_ru: el.client_in_stock_for_ru,

        price: el.client_price,
        price_ro: el.client_price_ro,
        price_ru: el.client_price_ru,

        price_old: el.client_price_old,
        price_old_ro: el.client_price_old_ro,
        price_old_ru: el.client_price_old_ru

      };
    });
    return { results: wrapp };
  }
  catch(er){
    console.error(er);
  }
}
function logOut(token){
  try {
    fetch(`${domain}/auth/token/logout/`, { 
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`
      }
    });
  }
  catch(er){
    console.error(er);
  }
}

