export default {
	data: () => ({
		langRu: {
			form: {
				title: { text1: 'Расскажите нам о своих ', color: 'целях', text2: '' },
				desc: 'Поможем подобрать подходящую для вас программу',
				consent: { text1: 'Я соглас(на/сен) с ', link: 'условиями', text2: 'обработки персональных данных' },
				country: 'Страна',
				name: 'Имя',
				phone: 'Телефон',
				btn: 'Оставьте заявку'
			}
		}
	})
}