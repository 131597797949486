export default {
  about: {
    title: "О нас",
    left: "Источник знаний для вашего профессионального роста",
    right:
      "У каждого есть сила и талант для достижения великих целей. Мы поможем вам найти способ развиваться и достигать в профессии - по вашему желанию",
    author: {
      title: "Об авторе",
      btn: "Посмотреть всю историю",
    },
    breadCrumbs: {
      home: "Домой",
      about: "О нас",
    },
  },
  basket: {
    title: "Твоя корзина",
    date: {
      postcode: "Почтовый Индекс",
      title: "Заполните данные",
      name: "Имя",
      surname: "Фамилия",
      phone: "Телефон",
      city: "Город",
      district: "Округ",
      houseNumber: "Номер дома",
      email: "Электронная почта",
      addressInvoice: "Address invoice",
      country: "Страна",
      street: "Улица",
      flat: "Квартира",
      person: "Физическое лицо",
      company: "Юридическое лицо",
      companyName: "Название компании",
      fiscalCode: "Фискальный идентификационный код",
      tradeNumber: "Номер в торговом реестре компании",
      companyAddress: "Адрес компании",
      block: "Подьезд",
    },
    delivery: {
      title: "Доставка",
      courier: "Доставка",
      shop: "Из магазина",
      courierDesc: "Доставка в отделении или курьером",
      courierDescRo:
        "Если стоимость заказа превышает 500 леев, доставка бесплатная",
      shopDesc: "Для тех кто желает нас посетить",
      address: "Адрес",
      info:
        " Бесплатная доставка: Кишинев - для заказов от 1000 лей; регионы - при заказе от 1200 лей. В противном случае транспортную оплату берет на себя заказчик(стоимость различается в зависимости от населенного пункта и веса посылки).",
      novaPosta: "Бесплатная доставка: Кишинев - для заказов от 1000 лей; регионы - при заказе от 1200 лей.",
      selectType: {
        courier: "Курьер",
        inOffice: "В отделении Nova Poshta",
      },
    },
    street: {
      houseNumber: "Дом",
      flat: "Кв.",
    },
    payment: {
      title: "Способы оплаты",
      сash: "Наличные",
      card: "Банковская карта",
    },
    confirmare: {
      title: "Подтверждение",
      comments: "Комментарии",
      promo: "Промокод",
      orderAmount: "Сумма заказа",
      delivery: "Доставка",
      total: "Всего",
      finish: "Завершить заказ",
      conditions: "Согласен с условиями",
      policy: "Политика конфиденциальности",
    },
    useCashback: "Использовать бонусы",
    noProducts: {
      title: "В корзине ничего нет, но это легко исправить",
      btn: "Перейти в магазин",
    },
  },
  category: {
    titlePage: "Курсы",
    home: "Домой",
    results: "результат",
    reload: "Загрузи больше",
    from: "от",
    advancedFiltering: "Расширенный поиск",
  },
  aboutAuthor: {
    author: {
      btn: "Посмотреть всю историю",
    },
  },
  cardTags: {
    from: "от",
  },
  contactForm: {
    form: {
      title: { text1: "Расскажите нам о своих", color: " целях ", text2: "" },
      desc: "Поможем подобрать подходящую для вас программу.",
      consent: {
        text1: "Я соглас(на/сен) с",
        link: "условиями ",
        text2: "обработки персональных данных",
      },
      country: "Страна",
      name: "Имя",
      phone: "Телефон",
      btn: "Оставьте заявку",
      message:
        "Заявка успешно отправлена, в скором времени с вами свяжется наш менеджер",
    },
  },
  footer: {
    courses: "Курсы",
    shop: "Магазин",
    specialists: "Специалисты",
    about: "О нас",
    blog: "Блог",
    login: "Войти",
    registration: "Регистрация",
    terms: "Условия и положения",
    provisions: "Политика конфиденциальности",
    message: "Оставить сообщение",
    desc:
      "Первая Академия красоты, специализирующаяся на косметологии и эстетической медицине. Сертифицированные специалисты в данной области предоставят вам лучшие материалы, которые помогут вам достичь вашей цели.",
    policy: "Правила пользования",
    security: "Политика безопасности",
  },
  header: {
    courses: "Курсы",
    shop: "Магазин",
    specialists: "Специалисты",
    about: "О нас",
    blog: "Блог",
    promotions: "Акции",
    login: "Войти",
    registration: "Регистрация",
    shopHidden: "только для зарегистрированных пользователей",
    shopClose: "недоступно в вашем регионе",
    dashboard: "Личный кабинет",
  },
  personalSlider: {
    title: { text: "Мы верим в ваш ", color: "успех" },
    btn: "Посмотреть всю историю",
  },
  course: {
    btnPath: {
      home: "Домой",
      category: "Курсы",
    },
    skillTitle: {
      text: "Преимущества ",
      color: "курса",
    },
    courseTitle: {
      text: "Содержание",
      color: " курса",
    },
    courseDesc:
      "Вас ждут онлайн лекции, практические задания, воркшопы и мастерклассы",
    chapters: "теоретическое",
    modules: "практических видео",
    priceTitle: "Стоимость курса",
    buy: "Купить курс",
    subscription: "Онлайн магазин",
    login: "Войти Сейчас",
    SeeTheProgram: "Содержание курса",
    modalBuyCourse: {
      title: "Купить курс",
      name: "Имя",
      phone: "Телефон",
      modeBuy: "Вариант оплаты",
      modalBtnBuy: "перейти на оплату",
      buy: "Банковская карта",
      conditions: "Cогласен с условиями и сроками",
      terms: "Правила пользования",
      payment: "Оплата курса",
    },
    preview:
      "ВИДЕО КУРС ЗАПИСАН,  ВЫ МОЖЕТЕ ПРИСТУПИТЬ К ОБУЧЕНИЮ В ЛЮБОЕ ВРЕМЯ ПОСЛЕ ПОКУПКИ.",
  },
  courseInner: {
    btnPath: {
      home: "Домой",
      category: "Курсы",
    },
    courseMaterials: "Материалы курса",
    courseTitle: {
      text: "Содержание",
      color: " курса",
    },
    courseDesc:
      "Вас ждут онлайн лекции, практические задания, воркшопы и мастерклассы",
    chapters: "теоретическое",
    modules: "практических видео",
  },
  dashboard: {
    abonament: {
      title: "Курсы",
      purchasedCourses: "Купленные курсы",
      viewStatus: {
        start: "Непросмотренный",
        mid: "Незавершенный",
        final: "Завершено",
      },
      btnPlay: "Продолжить",
    },
    shop: {
      title: "История",
      titleDesc: "Все операции",
      message: "У вас пока нет продаж, тут будет выводится список ваших продаж",
      products: "Товары",
      cashback: "Cashback",
    },
    applications: {
      title: "Заявки",
      list: "Список",
      calendar: "Календарь",
      confirmation: "Авто подтверждения",
      table: {
        title: "Подтвержденные заявки",
        name: "Имя",
        phone: "Номер телефона",
        data: "ДАТА РЕГИСТРАЦИИ",
        endData: "ПОСТАНОВКА НА УЧЕТ",
      },
      btn: {
        title: "Подтвердить",
        done: "Отменить",
        addUser: "Добавить",
      },
      emptyMessage: "У вас нет новых заявок",
      msgConfirmate: "У вас нет подтверждённых заявок",
      warnings: "Выбранные дата и время уже заняты",
    },
  },
  faq: {
    title: {
      text: "У вас есть ",
      color: "вопросы?",
    },
    desc: {
      text: "Здесь вы можете найти ответы на самые часто задаваемые ",
      color: "вопросы",
      text2: "Мы будем рядом с вами на протяжении всего обучения",
    },
    info: "Для получения дополнительной информации, свяжитесь с нами",
    btnText: "Оставить запрос",
  },
  home: {
    header: {
      courses: "Курсы",
      shop: "Магазин",
      specialists: "Специалисты",
      about: "О нас",
      blog: "Блог",
      promotions: "Акции",
      login: "Войти",
      registration: "Регистрация",
      shopHidden: "только для зарегистрированных пользователей",
      shopClose: "недоступно в вашем регионе",
    },
    slider: {
      btn: "Выбрать курс",
    },
    about: {
      video: { title: "Видео", desc: "Теоретические и практические курсы" },
      support: { title: "Поддержка", desc: "Советы лучших специалистов" },
      web: {
        title: "WEB",
        desc: "Создай свой персональный кабинет с возможностью онлайн записи",
      },
      results: { title: "Результат", desc: "Сертификат о прохождении курсов" },
    },
    support: {
      question: "Нужна помощь?",
      desc: "Будем рады помочь и ответить на ваш любой вопрос",
      btn: "Свяжитесь с нами",
    },
    courses: {
      title: "Наши курсы",
      left: "Косметология",
      right: "Эстетическая медицина",
      btn: "Посмотреть все курсы",
      massage: "Массаж",
    },
    author: {
      title: "Об авторе",
      btn: "Посмотреть всю историю",
    },
    offers: {
      title: {
        txt: "Специальные",
        color: "предложения",
      },
    },
  },
  login: {
    title: "Авторизация",
    name: "Логин",
    password: "Пароль",
    confirmPas: "Повторите пароль",
    newpass: "Новый пароль",
    login: "Войти",
    registration: "Нет аккаунта?",
    recoveryPass: "Восстановление",
    btnRecover: "Восстановить",
    save: "Сохранить",
    generate: "Cгенерировать",
    badDate: "Неверные данные",
    password2: "Пароль должен состоять из 6-ми символов",
    forgotPass: "Забыли пароль ?",
    message: "Проверьте пожалуйста почтовый ящик",
  },
  registration: {
    title: "Регистрация",
    name: "Имя",
    surname: "Фамилия",
    phone: "Телефон",
    email: "email",
    password: "Пароль",
    password2: "Повторите пароль",
    proffession: {
      title: "Специальность",
      cosmetolog: "Косметолог (медсестра)",
      medic: "Врач",
      other: "Для клиентов без сертификата косметолога",
    },
    errors: {
      password1: "Пароль должен состоять из 6-ми символов",
      password2: "Пароли не совпадают",
    },
    strongPassword: "Parola nu este sigură",
    messageCertificate: "Имя и фамилия будут указаны в сертификате",
    messageBusyUser: "email este ocupat",
    certificate: "Загрузить Сертификат",
    login: "Войти",
    log_in: "ЗАРЕГИСТРИРОВАТЬСЯ",
    politics: "Я принимаю правила и условия",
    politics2: "Я принимаю политику",
    popup: "Ваша регистрация проходит проверку",
    country: "Страна",
    modal: {
      message: `Вы успешно прошли регистрацию! Поздравляем! <br/>
          Ваш аккаунт будет активирован в течении 24 часов. <br/>
          В знак признательности мы дарим вам одно видео наших курсов! <br/>
          Далее, чтобы просмотреть видео необходимо зайти в личный кабинет
          <a href='https://lica.doctor/ru/dashboard'>https://lica.doctor/dashboard<a/> <br/>
          Мы с нетерпением ждём Вас на нашем сайте! <br/>
          По любым вопросам обращайтесь к нашим менеджерам: info@lica.doctor`,
      message2: `Вы успешно прошли регистрацию! Поздравляем! <br/>
          Ваш аккаунт будет активирован в течении 24 часов. <br/>
          Мы с нетерпением ждём Вас на нашем сайте! <br/>
          По любым вопросам обращайтесь к нашим менеджерам:info@lica.doctor.`,
    },
  },
  shop: {
    links: {
      home: "Домой",
      shop: "Магазин",
    },
    title: "Магазин",
    filtreTitle: "Фильтр продуктов",
    brand: "Бренд",
    price: "Цена",
    categorie: "Категория",
    sorted: "Ассортимент",
    result: "результатов",
    btnAdd: "Купить",
    loadPage: "Загрузи больше",
    specialist: "Специалисты",
  },
  specialists: {
    linkHome: "Домой",
    title: "Специалисты",
    btn: "Записаться",
    btn2: "Магазин",
    selectTitle: "Выберите дату,чтобы записаться",
    price: "Консультация от",
    reload: "Загрузи больше",
    result: "результатов",
    search: "Найди",
    address: "Адрес",
    certificateTitle: {
      text: "Сертификаты и ",
      color: "дипломы",
    },
  },
  certificates: {
    title: "Сертификаты академии Lica Doctor",
    descTitle:
      "Сертификаты, полученные после прохождения курсов на платформе Lica.Doctor Beauty Academy",
    title2: "Мои сертификаты",
    descTitle2: "Мои сертификаты и дипломы, не относящиеся к академии",
    btnAdd: "Добавить сертификат",
    messageCertificate: "У вас пока нет сертификатов",
    messageCertificateLink: "Перейти на страницу курсов",
    warning: "Предупреждение",
    confirmation: "Удалить Сертификат ?",
    delete: "Удалить",
  },
  changePass: {
    mainTitle: "Изменить пароль",
    oldPass: "Старый пароль",
    newPass: "Новый пароль",
    repeatPass: "Повтор пароля",
    save: "Сохранить",
    errMes1: "Пароль не совпадает",
  },
  courses: {
    noAvailable: "У вас еще нет курсов, вы можете",
    noAvailableLink: "купить первый.",
    free: "Бесплатно",
    advantage: "Воплощают твой идей в реальность",
    price: "0 МДЛ",
    perMonth: "/месяц",
    current: "Текущий план",
    advantage1: "Доступ для просмотра видео 10 часов / месяц",
    advantage2: "Ограниченный доступ к курсам",
    advantage3: "Расписание уроков и курсов",
    type: "Про",
    advantage4: "Лучшее для частных лиц",
    price2: "300 МДЛ",
    buy: "Купить Про",
    advantage5: "Просмотр видео 36 часов / месяц",
    advantage6: "Неограниченный доступ к курсам",
    work: "Неограниченный доступ к курсам",
    resource: "Ресурсы",
    type2: "Про+",
    advantage8: "Для настоящих профессионалов",
    price3: "1 200 МДЛ",
    buy2: "Купить Про+",
    advantage10: "Неограниченный доступ к просмотру видео",
    advice: "Практические советы кураторов",
  },
  myPage: {
    message: "Заполните поле",
    title: "Моя страница",
    certificates: "Мои сертификаты",
    certificatesDesc: "Список отображаемых сертификатов",
    services: "Мои услуги",
    btnAdd: "Добавить",
    btnSave: "Сохранить",
    personalLink: "Личная ссылка",
    placeholder: {
      name: "Имя / Фамилия",
      speciality: "Специальность",
      price: "Цена",
      address: "Адрес",
    },
  },
  orders: {
    title: "История",
    titleDesc: "Все операции",
    message: "У вас пока нет оплат, тут будет выводится список ваших оплат",
  },
  protocols: {
    message:
      "У вас пока не протоколов, протоколы можно получить при покупке курса",
  },
  requests: {
    busy: "Занят",
    daysOfWeek: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
  },
  article: {
    home: "Домой",
    blog: "Блог",
    article: "Статья",
    back: "назад",
    readMore: "Читать дальше",
  },
  checkout: {
    message: "Ваш заказ успешно оформлен!",
    messageDesc: "В скором времени вы получите email с деталями вашего заказа",
  },
  product: {
    home: "Домой",
    shop: "Магазин",
    add: "Добавить",
    added: "Добавлен",
    sliderTitle: ["Похожие", "товары"],
    returnPolicy:
      "Возврат: Косметика в запечатанной упаковке и / или фольге может быть возвращена только в том случае, если упаковка и / или фольга не повреждены.",
    inStock: "Нет в наличии",
  },
  promotion: {
    home: "Домой",
    breadcrumb1: "Акции",
    breadcrumb2: "Акция",
    back: "Назад",
  },
  promotions: {
    readMore: "Читать дальше",
    home: "Домой",
    breadcrumb1: "Акции",
  },
  usefulInfo: {
    home: "Домой",
    blog: "Полезная информация",
    article: "Статья",
    back: "назад",
  },
  appoitment: {
    title: "Записаться",
    namePhone: "Имя и номера телефона",
    comment: "Комментарий",
    name: "Имя",
    phone: "Телефон",
    btnModal: "Отправить",
    message: "Ваша заявка была отправлена!",
  },
  calendar: {
    title: "Составление регистрации",
    namePhone: "Имя и номера телефона",
    name: "Имя",
    phone: "Телефон",
    timeDate: "Дата и время",
    comment: "Комментарий",
    btn: "Сохранить",
  },
  confirmation: {
    text: "Успешное сохранение",
  },
  politics: {
    message:
      "Мы используем cookie-файлы для нашего сайта для того, чтобы обеспечить наилучший способ подбора Ваших предпочтений. Нажав на кнопку ”Принимаю” Вы соглашаетесь с использованием cookie-файлов. Перейдите в Настройки cookie-файлов, чтобы узнать больше.",
    btn: "Принимаю",
    link: "Политика безопасности",
  },
  productSelect: "Поиск товара",
  certificateUpload: "Сертификат,подтверждающий вашу профессию!",
  warnings: "Предупреждения",
  breadCrumbs: {
    back: "Назад",
  },
  coursesUpdatesModal: {
    title: "У вас обновления в курсе",
    content: "В курсе <b>{courses}</b> добавлено новое видео, протокол",
    button: "Переход на курс"
  }
};
