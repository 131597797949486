const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    specialists: [],
    localSpecialist: {}
  },
  getters: {
    getAll(state){
      return state.specialists;
    },
    getOne(state){
      return (id) => {
        return state.specialists.find(item => id == item.id);
      }
    },
    localSpecialist: state => state.localSpecialist
  },
  mutations: {
    setSpecialists(state, payload){
      state.specialists = payload;
    },
    SET_LOCAL_SPECIALIST: (state, specialists) => state.localSpecialist = specialists[0]
  },
   actions: {
    async setSpecialists({commit, rootGetters}){
      let lang = rootGetters['languages/getLang'];     
      commit('setSpecialists', await getUsers(lang));
    },
    async setLocalSpecialist({commit, rootGetters}, id){
      let lang = rootGetters['languages/getLang'];     
      commit('SET_LOCAL_SPECIALIST', await getUsers(lang, id));
    }
  }
}

async function getUsers(lang, id){
  try {
    const response = await fetch(`${domain}/${lang}/accounts/users/${id ? id : ''}`);
    if(response.ok) {
      let data = await response.json();
      if (!Array.isArray(data)) data = [data];
      let wrapp = data.map(e => {
        if(e.profile){
          return {
            ...e.profile,
            id: e.id,
            photo: e.profile.image == null ? '/img/avatar-dashboard.png' : e.profile.image,
            name: e.profile.name,
            position: e.profile.specialization,
            price: e.profile.consultation_price,
            money_type: e.profile.currency.toUpperCase(),
            about: e.profile.description,
            status: e.profile.landing_page_is_active,
            certificates: e.profile.certificates_and_diplomas,
            works: e.profile.user_page_slide,
            address: e.profile.address,
          }
        }
        return false;
      });
      wrapp.sort(function (a,b){return (a.image === null) - (b.image === null) || - (a.image > b) || + (a.image < b) || b.consultation_price - a.consultation_price })
      return wrapp.filter(e => e.status);
    }
    else {
      console.error(response);
      return [];
    }
  }
  catch(er){
    console.error(er);
  }
}