const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    courses: []
  },
  getters: {
    getAll(state){
      return state.courses;
    }
  },
  mutations: {
   setCourses(state, payload){
    state.courses = payload
      .sort((a, b) => b.is_new - a.is_new);
   }
  },
  actions: {
    async initializeCourses({commit, rootGetters}) {
      let lang = rootGetters['languages/getLang'];
      commit("setCourses", await getCourses(lang));
    }
  }
}


async function getCourses(lang) {
  try {
    const response = await fetch(`${domain}/${lang}/courses/course/?page_size=10000`);
    if(response.ok) {
      const data = await response.json();
      return wrapperCourses(data.results);
    }
    return null;
  }
  catch(err){
    console.error(err);
  }
}


function wrapperCourses(arr) {
  const filtered = [];
  arr.forEach(e => {
    filtered.push({
      ...e,
      id: e.id,
      image: e.image_thumbnail,
      title: e.name,
      discount: e.discount,
      linkVideo: e.preview_video_link,
      tags: e.brand,
      price: e.price,
      time: e.time,
      category: e.category
    });
  });

  return filtered;
}

