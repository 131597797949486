export default {
	data: () => ({
		langRo: {
			title: 'Cursuri',
			purchasedCourses: 'Cursuri procurate',
			viewStatus: {
				start: 'Neînceput',
				mid: 'Nefinalizat',
				final: 'Finalizat'
			},
			btnPlay: 'Continuă'
		}
	})
}








