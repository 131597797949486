export default {
	data: () => ({
		langRo: {
			header: {
				courses: 'Cursuri',
				shop: 'Magazin',
				specialists: 'Specialiști',
				about: 'Despre noi',
				blog: 'Blog',
        promotions: 'Stoc',
				login: 'Autentificare',
				registration: 'Înregistrare',
				shopHidden: 'numai pentru utilizatorii înregistrați',
				shopClose: 'nu este disponibil în regiunea dvs.'
			},
			slider: {
		
				btn: 'Alege un curs acum'
			},
			about: {
				video: { title: 'Video', desc: 'Lecții video și webinare' },
				support: { title: 'Support', desc: 'Sfaturile celor mai buni specialiști' },
				web: { title: 'WEB', desc: 'Beneficiezi de pagina web personală cu posibilitatea de înscriere online' },
				results: { title: 'Rezultat', desc: 'Certificate de calificare' },
			},
			support: {
				question: 'Ai nevoie de ajutor?',
				desc: 'Vom fi bucuroși să vă răspundem la oricare întrebări și să vă îndrumăm',
				btn: 'Contactează acum'
			},
			courses: {
				title: 'Cursurile noastre',
				left: 'Cosmetologie',
				right: 'Medicină estetică',
				btn: 'Vezi toate cursurile'
			},
			author: {
				title: 'Despre autor',
				btn: 'Vezi toată istoria'
			},
			offers: {
				title: {
					txt: 'Oferte',
					color: 'speciale'
				}
			}
		}
	})
}








