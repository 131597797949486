export default {
  namespaced: true,
  state: {
    utm: [],
    strUtm: ""
  },
  getters: {
    utm: s => s.utm,
    strUtm: s => s.strUtm
  },
  mutations: {
    utm: (s, obj) => {
      const query = Object.entries(obj);
      if (query.length){
        const arr = [];
        query.forEach(([key, value]) => {
          arr.push({
            [key]: value
          });
        });
        s.utm = arr;
        s.strUtm = arr.map(e => {
          let [key, value] = Object.entries(e)[0];         
          return `${key}=${value}`;
        }).join('&');
      }
    }
  },
  actions: {}
}