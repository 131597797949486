export default {
	async add({ state, getters, commit, dispatch }, { id, userId, payload }){
		if(getters.canAdd(id)){
			commit('startProccess', id);
			commit('add', { id, userId, payload });		
			commit('endProccess', id);
		}
	},
	async remove({ state, getters, commit }, { id }){
		if(getters.canUpdate(id)){
			commit('startProccess', id);	
			commit('remove', { ind: getters.index(id) });
			commit('endProccess', id);
		}
	},
	async setCnt({ state, getters, commit, dispatch }, { id, cnt }){
		if(getters.canUpdate(id)){
			commit('startProccess', id);
			let validCnt = Math.max(1, cnt);
			commit('setCnt', { ind: getters.index(id), cnt: validCnt });
			commit('endProccess', id);
		}
	},
	checkProductsAreAvailable: async ({ getters, rootGetters, commit }) => {
		const token = rootGetters['user/user'].token
		if (!token) return
		const productsIds = getters.products.map(product => product.id).join(',')
		const url = process.env.VUE_APP_BASE_API + '/check-stock/?product_ids=' + productsIds
		await fetch(url, { headers: { "Authorization": "Token " + token } })
			.then(async response => { 
				if (!response.ok) return
				const result = await response.json()
				if (!result) return
				commit('filterProducts', result.active_products)
			})
	}
}