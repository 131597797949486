export default {
	data: () => ({
		langRu: {
			header: {
				courses: 'Курсы',
				shop: 'Магазин',
				specialists: 'Специалисты',
				about: 'О нас',
				blog: 'Блог',
        promotions: 'Акции',
				login: 'Войти',
				registration: 'Регистрация',
				shopHidden: 'только для зарегистрированных пользователей',
				shopClose: 'недоступно в вашем регионе'
			},
			slider: {
				
				btn: 'Выбрать курс'
			},
			about: {
				video: { title: 'Видео', desc: 'Теоретические и практические курсы' },
				support: { title: 'Поддержка', desc: 'Советы лучших специалистов' },
				web: { title: 'WEB', desc: 'Создай свой персональный кабинет с возможностью онлайн записи' },
				results: { title: 'Результат', desc: 'Сертификат о прохождении курсов' },
			},
			support: {
				question: 'Нужна помощь? ',
				desc: 'Будем рады помочь и ответить на ваш любой вопрос',
				btn: 'Свяжитесь с нами'
			},
			courses: {
				title: 'Наши курсы',
				left: 'Косметология',
				right: 'Эстетическая медицина',
				btn: 'Посмотреть все курсы'
			},
			author: {
				title: 'Об авторе',
				btn: 'Посмотреть всю историю'
			},
			offers: {
				title: {
					txt: 'Специальные',
					color: 'предложения'
				}
			}
		}
	})
}