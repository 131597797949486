<template>
</template>

<script>
export default {
created(){
    window.location.href="viber://pa?chatURI=licadoctorbeautyacademy"
},
}
</script>

<style>

</style>