export default {
	data: () => ({
		langEng: {
			header: {
				courses: 'Сourses',
				shop: 'Shop',
				specialists: 'Specialists',
				about: 'About',
				blog: 'Blog',
        promotions: 'Promotions',
				login: 'Login',
				registration: 'Registration',
				shopHidden: 'for registered users only',
				shopClose: 'not available in your region',
				dashboard: 'Personal account'
			}
		}
	})
}