<template>
<div v-if="AboutTheAuthor.length" class="author">
  <HeaderWrp/>
  <Breadcrumbs>
    <router-link :to="{name: 'Home', params: {lang: langStorage}}">{{$t('article.home')}}</router-link>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
      <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
      <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
    </svg>    
    <router-link to="author" class="curr">{{ AboutTheAuthor[0].title }}</router-link>
    <template v-slot:title>
      <h1>{{ AboutTheAuthor[0].title }}</h1>
    </template>
  </Breadcrumbs>

  <div class="specialist__cnt page_author">
    <div class="img__cont">
      <img :src=" AboutTheAuthor[0].gallery_about_author[0].image_thumbnail" alt="">
    </div>
    <div class="about__col">
      <div class="top">
        <div class="details">
          <div class="name">{{ AboutTheAuthor[0].sub_title }}</div>
          <div class="function">{{ AboutTheAuthor[0].specialization }}</div>
        </div>
      </div>
      <p class="page__breadcrumbs_about inner-p" v-html="AboutTheAuthor[0].description_short"></p>
    </div>
  </div>
  <div class="diplomas__cnt page_author">
    <!-- <h2>Certificate și <b>diplome</b></h2> -->
    <div class="diplomas__row">
      <div class="card" v-for="photo in AboutTheAuthor[0].gallery_about_author" :key="photo.id">
        <img :src="photo.image_thumbnail" alt=""> <!--/img/diploma1.jpg-->
      </div>
    </div>
  </div>

  <!-- <div class="space-240"></div> -->
  <FooterApp/>
</div>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp";
import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    Breadcrumbs,
    HeaderWrp,
    FooterApp 
  },
  beforeMount(){
    this.getAboutTheAuthor();
  },
  watch: {
    langStorage(){
      this.getAboutTheAuthor();
    }
  },
  data:()=>({
    AboutTheAuthor: []
  }),
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    })
  },
  methods: {
    async getAboutTheAuthor(){
      const response = await fetch(`${domain}/${this.langStorage}/about-author-slider/`);
      this.AboutTheAuthor = await response.json();
    }
  }
}
</script>

<style lang="scss">
.author {
  .page__breadcrumbs_about {
    max-width: 706px;
  }
  .diplomas__row {
    justify-content: space-around !important;
  }

  .page__breadcrumbs_about.inner-p p{
    line-height: 22px !important;
  }
  .specialist__cnt.page_author,.diplomas__cnt.page_author {
    @media(min-width: 430px){
      margin-bottom: 80px;
    }
  }
  @media (max-width: 430px) {
    .specialist__cnt .img__cont {
      top: -18px !important;
    }
  }

}
</style>