import { getCurrency, getProductPrice, getProductClientPrice, DEFAULT_COUNTRY } from '@/utils/helpers'

const userPhoneNumberFormatter = (phoneNumber) => {
    return phoneNumber.replace(/[^+\d]/g, "");
}

export default {
    namespaced: true,
    actions: {
        sendGoogleEvent(context, dataLayerObject) {
            // Make sure to call this action inside or after mounted
            if (!dataLayer) return
            try {
                dataLayer.push({ ecommerce: null, user: null })
                dataLayer.push(dataLayerObject)
            } catch (e) {
                console.error(e)
            }
        },

        sendViewItemEvent ({ dispatch }, payload) {
            
            if (!payload?.item) return
            if (!payload?.currency) payload.currency = "RUB"
            if (!payload?.price) payload.price = payload.item.price

            dispatch('sendGoogleEvent', {
                event: "view_item",
                ecommerce: {
                    currency: payload.currency,
                    items: [
                        {
                            item_name: payload.item.title,
                            price: +payload.price,
                            quantity: 1
                        }
                    ]
                }
            })
        },

        sendAddToCartEvent ({ dispatch }, payload) {
            if (!payload?.item) return
            if (!payload?.currency) payload.currency = "RUB"
            if (!payload?.price) payload.price = payload.item.price
            if (!payload?.quantity) payload.quantity = 1

            dispatch('sendGoogleEvent', {
                event: "add_to_cart",
                ecommerce: {
                    currency: payload.currency,
                    value: +payload.price * payload.quantity,
                    items: [
                        {
                            item_name: payload.item.title,
                            price: +payload.price,
                            quantity: payload.quantity
                        }
                    ]
                }
            })
        },

        sendBeginCheckoutEvent ({ dispatch }, payload) {
            
            if (!payload?.items) return
            if (!payload?.currency) payload.currency = "RUB"

            dispatch('sendGoogleEvent', {
                event: "begin_checkout",
                ecommerce: {
                    currency: payload.currency,
                    value: payload.items.reduce((acc, item) => acc + (+item.price * item.quantity), 0),
                    items: payload.items.map(item => {
                        return {
                            item_name: item.title,
                            price: +item.price,
                            quantity: item.quantity
                        }
                    })
                }
            })
        },

        sendPurchaseEvent ({ dispatch }, payload) {
            if (
                !payload?.items ||
                !payload.user ||
                !payload.user.firstName ||
                !payload.user.lastName ||
                !payload.user.phone ||
                !payload.user.email
            ) return
            if (!payload?.currency) payload.currency = "RUB"

            dispatch('sendGoogleEvent', {
                event: "purchase",
                ecommerce: {
                    currency: payload.currency,
                    value: payload.items.reduce((acc, item) => acc + (+item.price * item.quantity), 0),
                    items: payload.items.map(item => {
                        return {
                            item_name: item.title,
                            price: +item.price,
                            quantity: item.quantity
                        }
                    })
                },
                user: {
                    user_first_name: payload.user.firstName,
                    user_last_name: payload.user.lastName,
                    user_phone: userPhoneNumberFormatter(payload.user.phone),
                    user_email: payload.user.email
                }
            })
        }
    }
}