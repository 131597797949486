<template >
<a class="scroll-to-top-btn" v-scroll-to="{ el: 'header' }" v-if="scrolled >= 30">
  <svg width="12" height="8" viewBox="0 0 12 8" fill="#930684" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1812 7.65967C10.5933 8.11091 11.2611 8.11091 11.6874 7.67471C11.9005 7.44909 12 7.1633 12 6.86248C12 6.57669 11.9005 6.29091 11.6874 6.08033L6.75666 0.830908C6.55773 0.62033 6.28774 0.5 6.00355 0.5C5.71936 0.5 5.44938 0.62033 5.25044 0.830908L0.319715 6.08033C-0.106573 6.51653 -0.106573 7.22347 0.319715 7.67471C0.731792 8.11091 1.41385 8.11091 1.82593 7.65967L6.00355 3.22248L10.1812 7.65967Z">
    </path>
  </svg>
</a>
</template>
<script>
export default {
  data() {
    return {
      scrolled: 0
    }
  },
  mounted() {
    window.onscroll = () => {
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      this.scrolled = (winScroll / height) * 100;
    };
  },
}
</script>
<style lang="scss">
  .scroll-to-top-btn {
    z-index: 25;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #930684;
    box-shadow: 12px 12px 60px rgb(75 139 236 / 8%);
    border-radius: 80px;
    position: fixed;
    right: 20px;
    bottom: 50px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 765px) {
      right: 30px;
      bottom: 100px;
    }
  }
</style>
