export default {
	data: () => ({
		langRu: {
			title: 'Курсы',
			purchasedCourses: 'Купленные курсы',
			viewStatus: {
				start: 'Непросмотренный',
				mid: 'Незавершенный',
				final: 'Завершено'
			},
			btnPlay: 'Play'
		}
	})
}