<template>
<div class="dashboard__col">
  <h1>{{ getTitle }}</h1>
  <div class="certificates__col">
    <div class="academy">
      <strong>
       {{$t('certificates.title')}}
      </strong>
      <div class="subtitle">
       {{$t('certificates.descTitle')}}
      </div>
      <div class="row">
        <div class="card" v-for="(item, ind) in certificateCourse" :key="item.id" >
          <div class="img__cont" @click="openAcademyrCertificate(ind)">
            <img v-if="!checkPdfLink(item.image)" :src="item.image" alt="">
            <!-- <img v-else src="/img/diploma3.jpg" alt=""> -->
            <div class="pdf_object" v-else>
              <iframe 
                :src="item.image + '#toolbar=0&navpanes=0'"
                width="100%" 
                height="234px"
              />
            </div>
          </div>
          <div class="title">
           {{ item[`title_${langStorage}`] }}
            <a class="download-link" @click="downloadFile(item.image)">
              <img src="/img/download2.svg" alt="" class="download-icn">
            </a>
          </div>
          <div class="course">
            <span>Curs:</span>
            <router-link :to="{name: 'CourseInner', params: {lang : langStorage, id : item.id_course}}">{{ item[`course_name_${langStorage}`] }}</router-link>
          </div>
        </div>
        <div class="card no-available" v-if="!certificateCourse.length">
          <h2>
            {{$t('certificates.messageCertificate')}}
            <br>
            <span @click="$router.push({name:'category', params: {lang: langStorage}});"> {{$t('certificates.messageCertificateLink')}}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="own">
      <strong>
         {{$t('certificates.title2')}}
      </strong>
      <div class="subtitle">
        {{$t('certificates.descTitle2')}}
      </div>
      <div class="row">
        <label class="add__certificate">
          <!-- accept="image/*,application/pdf" -->
          <input type="file" accept="image/*,application/pdf" @change="addCertificate">
          <span>{{$t('certificates.btnAdd')}}</span>
          <div class="plus">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8.625V15.5H8.625V8.625H15.5V7.375H8.625V0.5H7.375V7.375H0.5V8.625H7.375Z" fill="white"/>
            </svg>
          </div>
        </label>
        <div class="card" v-for="(item, ind) in certificateUser" :key="item.id">
          <div class="img__cont">
            <img v-if="!checkPdfLink(item.image)" :src="item.image" alt="">
            <!-- <img v-else src="/img/diploma3.jpg" alt=""> -->
            <div class="pdf_object" v-else>
              <iframe 
                :src="item.image + '#toolbar=0&navpanes=0'"
                width="100%" 
                height="234px"
              />
            </div>

            <svg class="zoom" @click="openUserCertificate(ind)">
              <use xlink:href="/icons.svg#zoom"></use>
            </svg>
  
            <svg class="delete" width="30px" height="30px" @click="openModal(item.id)">
              <use xlink:href="/icons.svg#delete"></use>
            </svg>
          </div>
          <div class="title user_certificate">
            {{titleLink(item.image)}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="aq__modal__wrp" :class="{'active' : modalWarning}" @click="closeModal">
      <div class="aq__modal">
        <div class="top">
          <div class="details">
            <strong>{{$t('certificates.warning')}}</strong>
          </div>
          <div class="close__btn" @click="modalWarning = false">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
            </svg>
          </div>
        </div>
        <form>
          <strong>{{$t('certificates.confirmation')}}</strong>        
          <button type="button" @click="deleteCertificate()">{{$t('certificates.delete')}}</button>
        </form>
      </div>
  </div>
    <CoolLightBox 
    :items="LightBoxCertificateUser" 
    :index="indexLightBoxUser"
    @close="indexLightBoxUser = null">
  </CoolLightBox>
  <CoolLightBox 
    :items="LightBoxCertificateCourse" 
    :index="indexLightBoxCourse"
    @close="indexLightBoxCourse = null">
  </CoolLightBox>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const domain = process.env.VUE_APP_BASE_API;


export default {
  components: {
    CoolLightBox
  },
  beforeMount() {
    this.getCertificates();   
  },
  data(){
    return {
      certificateCourse: [],
      certificateUser: [],
      userResponce: {},
      modalWarning: false,
      idCertificateDelete: 0,
      indexLightBoxCourse: null,
      indexLightBoxUser: null
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user',
    }),
    getTitle(){  
      return this.langStorage == 'ro' ? 'Online cabinet' : this.langStorage == 'ru' ? 'Онлайн кабинет' : this.langStorage == 'es' ? 'Gabinete en línea' : 'Online cabinet';
    },
    LightBoxCertificateCourse() {
      let arr = [];
      this.certificateCourse.forEach(el => {
        if(window.innerWidth >= 430){
          arr.push(el.image);
        }
        else if(!this.checkPdfLink(el.image)){
          arr.push(el.image);
        }
      });
      return arr;
    },
    LightBoxCertificateUser() {
      let arr = [];
      this.certificateUser.forEach(el => {
        if(window.innerWidth >= 430){
          arr.push(el.image);
        }
        else if(!this.checkPdfLink(el.image)){
          arr.push(el.image);
        }
      });
      return arr;
    }
  },
  methods: {
    async getCertificates(){
      try {
        const req = await fetch(`${domain}/accounts/users/${this.user.userID}/`);
        const res = await req.json();
        this.certificateCourse = [];
        this.certificateUser = [];
        this.userResponce = res;
        res.profile.certificates_and_diplomas.forEach(el => {
          el.for_completing_the_course ? this.certificateCourse.push(el) : this.certificateUser.push(el);
        });
      }
      catch(er) {
        console.error(er);
      }
    },
    async addCertificate(e){
      if(e.target.files.length) {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("user_profile_id", this.userResponce.profile.id);
        formData.append("image", file);
        formData.append("show", true);
        formData.append("for_completing_the_course", false);
        await fetch(`${domain}/accounts/certificates_and_diplomas/`, {
          method: 'POST',
          body: formData
        });
        await this.getCertificates(); 
      }
    },
    openModal(id){
      this.idCertificateDelete = id;
      this.modalWarning = true;
    },
    async deleteCertificate(){
      try {
        await fetch(`${domain}/accounts/certificates_and_diplomas/${this.idCertificateDelete}/`, {
          method: 'DELETE'
        });
        this.modalWarning = false;
        this.getCertificates();
      }
      catch(er) {
        console.error(er);
      }
    },
    closeModal(e){
     if(e.target.classList == 'aq__modal__wrp active'){
      this.modalWarning = false;
     }
    },
    checkPdfLink(str){
      return str.substring(str.length - 3).toUpperCase() == "PDF";
    },
    titleLink(str){
      let arr =  decodeURI(str).split('/');
      arr = arr[arr.length - 1].split('_');
      return arr[0];
    },
    openUserCertificate(ind) {
      if(window.innerWidth <= 430 && this.checkPdfLink(this.certificateUser[ind].image)){
        window.open(this.certificateUser[ind].image);
      }
      else {
        this.indexLightBoxUser = ind;
      }
    },
    openAcademyrCertificate(ind) {
      if(window.innerWidth <= 430 && this.checkPdfLink(this.certificateCourse[ind].image)){
        window.open(this.certificateCourse[ind].image);
      }
      else {
        this.indexLightBoxCourse = ind;
      }
    },
    downloadFile(url) {
      fetch(url, {
        method: "GET",
      })
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
      .then(dataUrl => {
        let a = document.createElement("a");
        a.href = dataUrl;
        a.download = "certificates";
        a.click();
      })
      .catch(console.error)
    
    },
    

  }
}
</script>
<style lang="scss" scoped>
body.night {
  .card.no-available {
    h2 {
      color: white;
    }
  }
}
.dashboard__col {
  .card {
    .title {
      display: flex;
      align-items: center;
      .download-link {
        cursor: pointer;
        height: 20px;
        .download-icn {
          height: 20px;
          margin-left: 5px;
        }
        img {        
          border-radius: 100%;
          transition: 0.5s;
        }
        &:hover {
          img {
            background-color: #930684c7;
          }
        }
      }
    }
  }
}
  .no-available {
    width: 100%;
    h2 {
     font-size: 18px;
     span {
      cursor: pointer;
      font-size: 12px;
      text-decoration: underline;
     }
    }
  }

  .aq__modal__wrp {
    button {
      background-color: #a7002e;
    }
  }
  .academy .img__cont {
    cursor: pointer;
    .pdf_object {
      pointer-events: none;
    }
  }

  .own {
    .delete {
      transition: 0.3s;
      opacity: 0;
    }
    .img__cont {
      position: relative;
      cursor: pointer;
      img,.pdf_object {
        transition: 0.3s;
      }
      .delete {
        position: absolute;
        right: 10px;
        top: 10px;
        fill: red;
      }
      .pdf_object {
        pointer-events: none;
        overflow: hidden;
        cursor: pointer;
      }
      &:hover {
        .delete, .zoom {
          opacity: 1;
        }
        img,.pdf_object {
          filter: brightness(0.5);
        }
        
      }
    }
  }

  .certificates__col {
    .user_certificate {
      overflow-x: hidden;
      width: 100%;
    }
  }

  .zoom {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    fill: red;
    transition: 0.3s;
    opacity: 0;
  }

</style>


