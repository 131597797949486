export default {
	data: () => ({
		langRu: {
			title: {
				text: 'У вас есть ',
				color: 'вопросы?'
			},
			desc: {
				text: 'Здесь вы можете найти ответы на самые часто задаваемые ',
				color: 'вопросы',
				text2: 'Мы будем рядом с вами на протяжении всего обучения'
			},
			info: 'Для получения дополнительной информации, свяжитесь с нами',
			btnText: 'Оставить запрос'
		}
	})
}