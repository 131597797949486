export default {
	data: () => ({
		langEng: {
			title: 'Login',
			name: 'Login',
			password: 'password',
			newpass: 'New password',
			confirmPas: 'Confirm password',
			login: 'Sign in',
			registration: 'Sign up',
			recoveryPass: 'Recovery',
			btnRecover: 'Recover',
			save: 'Save',
			generate: 'Generate',
      savePass: "Save login and password"
		}
	})
}








