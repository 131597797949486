import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo  from 'vue-scrollto'
import VScrollLock from 'v-scroll-lock'
import vSelect from 'vue-select'
import VueMeta from 'vue-meta'
import "@/sass/vueModal.css"
import 'vue-select/dist/vue-select.css';
import i18n from './i18n'

Vue.component('v-select', vSelect)

Vue.config.productionTip = false
Vue.use(VueScrollTo)
Vue.use(VScrollLock)
Vue.use(VueMeta)

const products = process.browser && localStorage.getItem('products');
store.commit("checkout/setProductsFromLocalStorage", JSON.parse(products) ?? [])
store.dispatch('shopCourses/initializeCourses')
store.dispatch('personalSlider/setSlider')
store.dispatch('courses/getCosmetologyCourses')
store.dispatch('courses/getMedicinaCourses')



let mode = window.localStorage.getItem('darkMode');
if(mode === null || mode === undefined) {
  store.dispatch('setDarkMode', false);
  window.localStorage.setItem('darkMode', false);
}
else {
  store.dispatch('setDarkMode', mode);
}

store.dispatch('user/initializeCountry').then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
});

