export default {
	data: () => ({
		langEng: {
			title: 'About',
			left: 'Source of knowledge for your professional growth',
			right: 'Everyone has the power and talent to achieve great goals. We help you find a way to develop and achieve through the profession - as you wish',
			author: {
				title: 'About the author',
				btn: 'View full story'
			},
			breadCrumbs: {
				home: 'Home',
				about: 'About'
			}
		}
	})
}


