export default {
	data: () => ({
		langRo: {
			btnPath: {
				home: 'Acasa',
				category: 'Cursuri',
			},
			courseMaterials: 'Materialele cursului',
			courseTitle: {
				text: 'Conținutul',
				color: ' cursului'
			},
			courseDesc: 'Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri',
			chapters: 'teoretic',
			modules: 'videoclipuri practice'
		}
	})
}