export default {
	data: () => ({
		langEng: {
			footer: {
				courses: 'Сourses',
				shop: 'Shop',
				specialists: 'Specialists',
				about: 'About',
				blog: 'Blog',
				login: 'Login',
				registration: 'Registration',
				terms: 'Terms and Conditions',
				provisions: 'Privacy policy',
				message: 'Leave a message',
				desc: 'The first beauty Academy specializing in cosmetology and aesthetic medicine. Certified professionals in the field will provide you with the best materials to help you achieve your goal.',
				policy: 'Terms of use'
			}
		}
	})
}