export default {
	data: () => ({
		langRu: {
			footer: {
				courses: 'Курсы',
				shop: 'Магазин',
				specialists: 'Специалисты',
				about: 'О нас',
				blog: 'Блог',
				login: 'Войти',
				registration: 'Регистрация',
				terms: 'Условия и положения',
				provisions: 'Политика конфиденциальности',
				message: 'Оставить сообщение',
				desc: 'Первая Академия красоты, специализирующаяся на косметологии и эстетической медицине. Сертифицированные специалисты в данной области предоставят вам лучшие материалы, которые помогут вам достичь вашей цели.',
				policy: 'Правила пользования'
			}
		}
	})
}