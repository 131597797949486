export default {
	data: () => ({
		langEng: {
			titlePage: 'Cursos',
			home: 'Inicio',
			results: 'Resultados',
			reload: 'Cargar más',
			from: 'Desde',
			advancedFiltering: 'Filtros avanzados'
		}
	})
}




