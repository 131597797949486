<template>
  <div class="aq__modal__wrp" @click.self="close()">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <strong>
            <slot name="header">Modal Name</slot>
          </strong>
        </div>
        <div class="close__btn" @click="close()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <form>
        <slot name="body">
          <strong></strong>
          <div class="inp__row">
            
          </div>
        </slot>
        <slot name="footer">
          <button type="button" @click="$emit('ok')">Button</button>
        </slot>
        
      </form>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {

    }
  },
  methods: {
    close(){
      this.$emit('close');
    },

  }
}
</script>

<style lang="scss">
.aq__modal__wrp {
  .time__row {
    .time,.ducraction {
      width: 100%;
    }
    .date {    
      padding-left: 14px !important;
    }

  }
  .vue__time-picker.time-picker {
    width: calc(50% - 12px);
    @media(max-width: 430px) { width: 100%; }
    .time {
      background: url('/img/time-icon.svg') no-repeat right #EFEEFE;
      background-position: top 16px right 20px;
      padding-left: 24px;
      border-radius: 6px;
      outline: none;
      border: none;
      height: 48px;
      color: #000001;
      font-size: 16px;
    }
  }
  .clock {
    width: calc(50% - 12px);
    background: url('/img/time-icon.svg') no-repeat right #EFEEFE;
    background-position: top 16px right 20px;
    padding-left: 24px;
    border-radius: 6px;
    outline: none;
    border: none;
    height: 48px;
    color: #000001;
    font-size: 16px;
    @media(max-width: 430px) { width: 100%; }
  }

  .vue__time-picker {
    .dropdown {
      ul li:not([disabled]).active{
        background: #930f81;
      }
    }
  }

  .date_range_picker {
    width: calc(50% - 12px);
    border-radius: 6px;
    @media(max-width: 430px){
      width: 100%;
      margin-bottom: 16px;
    }
    .form-control {
      display: flex;
      align-items: center;
      background: url('/img/calendar.svg') no-repeat right #EFEEFE;
      background-position-x: 95%;
      background-size: 25px;
      height: 48px;
      border-radius: 6px;
      outline: none;
      border: none;
    }
  }



  .duraction {
    background-color: #EFEEFE;
    border-radius: 6px;
    padding-left: 20px;
    outline: none;
    border: none;
    width: 100% !important;
    max-width: 180px;
  }
  .requred {
    background-color: #ff00002e !important;
    .form-control {
      background-color: #ff00002e;
    }
  }

}

</style>
