export default {
	data: () => ({
		langEng: {
			title: 'Courses',
			purchasedCourses: 'Purchased courses',
			viewStatus: {
				start: 'Unseen',
				mid: 'Incomplete',
				final: 'Completed'
			},
			btnPlay: 'Play'
		}
	})
}








