const DEFAULT_SHOP_ID_BY_COUNTRY_MAP = {
    "Romania": 98,
    "Moldova, Republic of": 104,
}

const AVAILABLE_COUNTRIES_MAP = {
    "RO": "Romania",
    "MD": "Moldova, Republic of",
    "RU": "Russian Federation",
}

const AVAILABLE_COUNTRIES = {
    MOLDOVA: 'Moldova, Republic of',
    ROMANIA: 'Romania',
    RUSSIAN_FEDERATION: 'Russian Federation',
}

const DEFAULT_COUNTRY = AVAILABLE_COUNTRIES.RUSSIAN_FEDERATION

const getCurrency = (country) => {
    if (!country) throw new Error("No country provided")
    
    switch (country) {
        case AVAILABLE_COUNTRIES.ROMANIA:
            return 'RON'
        case AVAILABLE_COUNTRIES.MOLDOVA:
            return "MDL"
        default:
            return "RUB"
    }
}

const getProductPrice = (country) => {
    if (!country) throw new Error("No country provided")

    switch (country) {
        case AVAILABLE_COUNTRIES.ROMANIA:
            return 'price_ro'
        case AVAILABLE_COUNTRIES.MOLDOVA:
            return 'price'
        default:
            return 'price_ru'
    }
}

const getProductClientPrice = (country) => {
    if (!country) throw new Error("No country provided")

    switch (country) {
        case AVAILABLE_COUNTRIES.ROMANIA:
            return 'client_price_ro'
        case AVAILABLE_COUNTRIES.MOLDOVA:
            return 'client_price'
        default:
            return 'client_price_ru'
    }
}

export {
    DEFAULT_SHOP_ID_BY_COUNTRY_MAP,
    AVAILABLE_COUNTRIES_MAP,
    AVAILABLE_COUNTRIES, 
    DEFAULT_COUNTRY, 
    getCurrency, 
    getProductPrice,
    getProductClientPrice 
}