<template>
  <div class="card">
    <a
      class="img__cont"
      @click="$emit('select')"
    >
      <div class="discount" v-if="discount">{{ discount }}%</div>
      <div class="new" v-if="isNew">New</div>
      <img :src="image" alt="" />
      <div class="play__btn">
        <svg
          width="25"
          height="30"
          viewBox="0 0 25 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 16.732C25.3333 15.9622 25.3333 14.0378 24 13.268L3 1.14359C1.66666 0.373795 0 1.33605 0 2.87565L0 27.1244C0 28.664 1.66667 29.6262 3 28.8564L24 16.732Z"
            fill="white"
          ></path>
        </svg>
      </div>
    </a>

    <a
      class="title"
      tabindex="0"
      @click="$emit('select')"
      >
      {{ title }}
    </a>

    <div class="tags">
      <span 
        v-for="tag in tags"
        :key="tag.id"
        class="tag" 
      >
       {{ tag.title }}
      </span>
    </div>

    <div class="old_price">
      <template v-if="discount"> {{ price }} &#8364; </template>
    </div>

    <div class="price__row">
      <div class="price">
        {{ $t("category.from") }}
        {{
          Math.trunc(Number((price / 100) * discount) + Number(price))
        }}
        &#8364;
      </div>
      <div class="time">{{ time }}</div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    image: {
      type: String,
      default: "/img/curs1.jpg",
    },
    title: {
      type: String,
      default: "Title Product",
    },
    discount: {
      type: Number,
      default: 0,
    },
    linkVideo: {
      type: String,
    },
    tags: {
      type: Array,
      default() {
        return [
          { title: "Cosmetologie", link: "/" },
          { title: "Mint", link: "/" },
          { title: "Pressensa", link: "/" },
        ];
      },
    },
    price: {
      type: String,
      default: "1 568",
    },
    time: {
      type: String,
      default: "",
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    }
  },

};
</script>

<style lang="scss">
.category__courses__cnt .img__cont {
  cursor: pointer !important;
  .new {
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: #eb236b;
    border-radius: 12px 64px 64px 64px;
    height: 72px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    z-index: 1;
  }
}
.card {
  .title {
    height: auto !important;
  }

  .tags {
    width: 100%;
    overflow-x: auto;

    scrollbar-color: #f5f4fb transparent; /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: thin; /* толщина */

    &::-webkit-scrollbar {
      /* полоса прокрутки (скроллбар) */
      height: 5px; /* высота для горизонтального скролла */
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      /* ползунок скроллбара */
      background-color: #f5f4fb;
      border-radius: 9em;
      box-shadow: inset 1px 1px 10px #f3faf7;
    }
  }
  .old_price {
    margin-top: auto;
    text-decoration-line: line-through;
    color: #515076;
    font-weight: 600;
    font-size: 14px;
    min-height: 16px;
  }
}
</style>