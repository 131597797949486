<template>
  <div class="dropdown lang" @mouseover="hoverLang = true" @mouseleave="hoverLang = false">
    <button class="dropdown-toggle" type="button">
      <span class="caption">{{ langStorage.toUpperCase() }}</span>
      <!-- <span class="arrow" :style="{'color': color}"></span> -->
      <IconArrow class="arrow"/>
    </button>
    <div style="height: 5px"  @mouseover="hoverLang = true"></div>
    <div ref="dropdownMenu" class="dropdown-menu" :style="{'height': hoverLang ? 'auto' : '0', 'overflow': hoverLang ? 'unset' : 'hidden'}">
      <div class="dropdown-item" @click="$i18n.locale='ro' ,selectLanguages('ro')">RO</div>
      <div class="dropdown-item" @click="$i18n.locale='ru' ,selectLanguages('ru')">RU</div>
      <div class="dropdown-item" @click="$i18n.locale='en' ,selectLanguages('en')">EN</div>
      <div class="dropdown-item" @click="$i18n.locale='fr' ,selectLanguages('fr')">FR</div>
      <div class="dropdown-item" @click="$i18n.locale='es' ,selectLanguages('es')">ES</div>
    </div>
  </div>
</template>
<script>
import IconArrow from "@/components/icons/arrowDown.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    IconArrow
  },
  props: {
    color: {
      type: String,
      default: "#ffffff"
    }
  },
  data: () => ({
    hoverLang: false
  }),
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    }),
  },
  methods: {
    ...mapActions({
      setLang: 'languages/setLang'
    }),
    selectLanguages(lng) {
      this.setLang(lng); 
      if(this.$route.params.lang != lng) {
        this.$router.push({name: this.$route.name, params: {lang: lng}})
      }
      this.hoverLang = false;
    },
  }
}
</script>

<style lang="scss" scoped>

.night {
  .dropdown.lang {
    .dropdown-toggle {
      color: white;
      border-color: white;
    }
  }
}

    .dropdown {
      overflow: hidden;
    }
    .dropdown.lang {
      height: 47px;
    }
    .dropdown.lang {
      .dropdown-toggle {
        text-align: center;
        border: 1px solid black;
        background: transparent;
        border-radius: 100px;
        color: black;
        width: 50px;
        white-space: nowrap;
        cursor: pointer;
        outline: none;
        .caption {
          font-size: 12px;
          font-weight: 900;
        }

      }
    }
    .dropdown.lang .dropdown-toggle .caption {
      display: block;
      text-align: center;
      vertical-align: baseline;
    }
    .dropdown.lang .dropdown-toggle .arrow {
      display: inline-block;
      vertical-align: top;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      margin-top: 2px;
      width: 13px;
      height: 13px;

      // transform: rotate(90deg);
      // &::before {
      //   content: '>';
      //   // color: white;
      //   font-family: 'awesome';     
      // }
    }
    .dropdown-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 12px;
      z-index: 2;
      
      width: 50px;
      color: black;
      
      .dropdown-item {
        padding: 10px;
        width: 100%;
        text-align: center;
        transition: 0.3s;
        border-radius: 10px;
        &:hover {
          background-color: #930684;
          color: white;
        }
      }
    }
    .dropdown.lang:hover {
      height: auto;
      .dropdown-menu {
        position: absolute;
      }
      .arrow {
        transform: rotate(180deg);
      }
    }
  
</style>
