export default {
	data: () => ({
		langEng: {
			title: {
				text: 'Do you have any ',
				color: 'questions?'
			},
			desc: {
				text: 'Here you can find answers to frequently asked ',
				color: 'questions',
				text2: 'We will be near you throughout your studying process'
			},
			info: 'For further information contact us',
			btnText: 'Leave a request'
		}
	})
}