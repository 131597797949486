export default {
	data: () => ({
		langRo: {
			footer: {
				courses: 'Cursuri',
				shop: 'Magazin',
				specialists: 'Specialiști',
				about: 'Despre noi',
				blog: 'Blog',
				login: 'Crează un cont',
				registration: 'Înregistrare',
				terms: 'Termeni și condiții',
				provisions: 'Politica de confidețialitate',
				message: 'Lasă un mesaj',
				desc: 'Prima Academie de frumusețe, specializată în cosmetologie și medicină estetică. Specialiști certificați în sfera vă vor oferi cele mai bune materiale pentru a vă ajuta să vă atingeți scopul.',
				policy: 'Reguli de utilizare'
			}
		}
	})
}